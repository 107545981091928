import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { submissions as submissionsApi } from 'services/api';

export const actionTypes = {
    FETCH_WORKING_SUBMISSION: 'submissions/FETCH_WORKING_SUBMISSION',
    FETCH_WORKING_SUBMISSION_SUCCESS:
        'submissions/FETCH_WORKING_SUBMISSION_SUCCESS',
    FETCH_WORKING_SUBMISSION_FAILED:
        'submissions/FETCH_WORKING_SUBMISSION_FAILED',

    FETCH_WORKING_SUBMISSION_RESET:
        'submissions/FETCH_WORKING_SUBMISSION_RESET',

    FETCH_SUBMISSION_STATES: 'submissions/FETCH_SUBMISSION_STATES',
    FETCH_SUBMISSION_STATES_SUCCESS:
        'submissions/FETCH_SUBMISSION_STATES_SUCCESS',
    FETCH_SUBMISSION_STATES_FAILED:
        'submissions/FETCH_SUBMISSION_STATES_FAILED',

    UPDATE_WORKING_SUBMISSION_STATE:
        'submissions/UPDATE_WORKING_SUBMISSION_STATE',
    UPDATE_WORKING_SUBMISSION_STATE_SUCCESS:
        'submissions/UPDATE_WORKING_SUBMISSION_STATE_SUCCESS',
    UPDATE_WORKING_SUBMISSION_STATE_FAILED:
        'submissions/UPDATE_WORKING_SUBMISSION_STATE_FAILED',
    SIGNOFF_SUBMISSION: 'submissions/SIGNOFF_SUBMISSION',
};

export const actions = {
    fetchWorkingSubmission: createAction(actionTypes.FETCH_WORKING_SUBMISSION),
    fetchWorkingSubmissionSuccess: createAction(
        actionTypes.FETCH_WORKING_SUBMISSION_SUCCESS,
    ),
    fetchWorkingSubmissionFailed: createAction(
        actionTypes.FETCH_WORKING_SUBMISSION_FAILED,
    ),
    resetWorkingSubmissionState: createAction(
        actionTypes.FETCH_WORKING_SUBMISSION_RESET,
    ),

    fetchSubmissionStates: createAction(actionTypes.FETCH_SUBMISSION_STATES),
    fetchSubmissionStatesSuccess: createAction(
        actionTypes.FETCH_SUBMISSION_STATES_SUCCESS,
    ),
    fetchSubmissionStatesFailed: createAction(
        actionTypes.FETCH_SUBMISSION_STATES_FAILED,
    ),

    updateWorkingSubmission: createAction(
        actionTypes.UPDATE_WORKING_SUBMISSION_STATE,
    ),
    updateWorkingSubmissionSuccess: createAction(
        actionTypes.UPDATE_WORKING_SUBMISSION_STATE_SUCCESS,
    ),
    updateWorkingSubmissionFailed: createAction(
        actionTypes.UPDATE_WORKING_SUBMISSION_STATE_FAILED,
    ),
    signoffSubmission: createAction(actionTypes.SIGNOFF_SUBMISSION),
};

export const states = {
    fetch: {
        UNINITIALISED: 1,
        LOADING: 2,
        LOADED: 3,
        LOADING_FAILED: 4,
    },
    update: {
        UNINITIALISED: 1,
        UPDATING: 2,
        UPDATED: 3,
        UPDATING_FAILED: 4,
    },
};

const INITIAL_STATE = {
    workingSubmission: {},
    submissionStates: [],
    state: {
        fetchWorkingSubmission: states.fetch.UNINITIALISED,
        fetchSubmissionStates: states.fetch.UNINITIALISED,
        updateWorkingSubmission: states.update.UNINITIALISED,
    },
};

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_WORKING_SUBMISSION: {
            return {
                ...state,
                state: {
                    ...state.state,
                    fetchWorkingSubmission: states.fetch.LOADING,
                },
            };
        }
        case actionTypes.FETCH_WORKING_SUBMISSION_SUCCESS: {
            return {
                ...state,
                workingSubmission: action.payload,
                state: {
                    ...state.state,
                    fetchWorkingSubmission: states.fetch.LOADED,
                },
            };
        }
        case actionTypes.FETCH_WORKING_SUBMISSION_RESET: {
            return {
                ...state,
                workingSubmission: {},
                state: {
                    ...state.state,
                    fetchWorkingSubmission: 1,
                },
            };
        }
        case actionTypes.FETCH_WORKING_SUBMISSION_FAILED: {
            return {
                ...state,
                state: {
                    ...state.state,
                    fetchWorkingSubmission: states.fetch.LOADING_FAILED,
                },
            };
        }

        case actionTypes.FETCH_SUBMISSION_STATES: {
            return {
                ...state,
                state: {
                    ...state.state,
                    fetchSubmissionStates: states.fetch.LOADING,
                },
            };
        }

        case actionTypes.FETCH_SUBMISSION_STATES_SUCCESS: {
            return {
                ...state,
                submissionStates: [...action.payload.states],
                state: {
                    ...state.state,
                    fetchSubmissionStates: states.fetch.LOADED,
                },
            };
        }

        case actionTypes.FETCH_SUBMISSION_STATES_FAILED: {
            return {
                ...state,
                state: {
                    ...state.state,
                    fetchSubmissionStates: states.fetch.LOADING_FAILED,
                },
            };
        }

        case actionTypes.UPDATE_WORKING_SUBMISSION_STATE: {
            return {
                ...state,
                state: {
                    ...state.state,
                    updateWorkingSubmission: states.update.UPDATING,
                },
            };
        }

        case actionTypes.UPDATE_WORKING_SUBMISSION_STATE_SUCCESS: {
            const { id, code, name } = state.submissionStates.find(
                status => status.id === action.payload,
            );
            return {
                ...state,
                workingSubmission: {
                    ...state.workingSubmission,
                    status: { id, code, name },
                },
                state: {
                    ...state.state,
                    updateWorkingSubmission: states.update.UPDATED,
                },
            };
        }

        case actionTypes.UPDATE_WORKING_SUBMISSION_STATE_FAILED: {
            return {
                ...state,
                state: {
                    ...state.state,
                    updateWorkingSubmission: states.update.UPDATING_FAILED,
                },
            };
        }

        case actionTypes.SIGNOFF_SUBMISSION: {
            return {
                ...state,
                state: {
                    ...state.state,
                    updateWorkingSubmission: states.update.UPDATING,
                },
            };
        }

        default:
            return state;
    }
};

const findId = code => state => {
    const foundState = state.submissions.submissionStates.find(
        state => state.code === code,
    );
    return foundState ? foundState.id : 0;
};

const getStateId = code => {
    return createSelector(findId(code), id => id);
};

export const selectors = {
    getWorkingSubmission: state => state.submissions.workingSubmission,
    getSubmissionStates: state => state.submissions.submissionStates,
    getStateId,
    getFetchWorkingSubStatus: state =>
        state.submissions.state.fetchWorkingSubmission,
    getUpdateWorkingSubStatus: state =>
        state.submissions.state.updateWorkingSubmission,
    getFetchSubStatesStatus: state =>
        state.submissions.state.fetchSubmissionStates,
    getSignoffSubmission: state => state.submissions.state.signoffSubmission,
};

export const sagas = {
    onFetchWorkingSubmission: function* onFetchWorkingSubmission({ payload }) {
        try {
            const { submissionId, isOvt } = payload || {};
            const api = isOvt
                ? submissionsApi.getOvtSubmission
                : submissionsApi.getSubmission;
            const response = yield call(api, {
                submissionId,
            });
            yield put(actions.fetchWorkingSubmissionSuccess(response));
        } catch (error) {
            yield put(actions.fetchWorkingSubmissionFailed(error));
        }
    },

    onFetchSubmissionStates: function* onFetchSubmissionStates() {
        try {
            const response = yield call(submissionsApi.getStates);
            yield put(actions.fetchSubmissionStatesSuccess(response));
        } catch (error) {
            yield put(actions.fetchSubmissionStatesFailed(error));
        }
    },

    onUpdateWorkingSubmissionState: function* onFetchWorkingSubmission({
        payload,
    }) {
        try {
            const { submissionId, statusId, action } = payload || {};
            const response = yield call(submissionsApi.updateSubmissionState, {
                submissionId,
                statusId,
                action,
            });
            if (response) {
                yield put(actions.updateWorkingSubmissionSuccess(statusId));
            } else {
                yield put(actions.updateWorkingSubmissionFailed());
            }
        } catch (error) {
            yield put(actions.updateWorkingSubmissionFailed(error));
        }
    },
    onSignoffSubmission: function* onSignoffSubmission({ payload }) {
        try {
            const { submissionId, signedOffStatusId, signOffType } =
                payload || {};
            yield call(submissionsApi.signoff, { submissionId, signOffType });
            yield put(
                actions.updateWorkingSubmissionSuccess(signedOffStatusId),
            );
        } catch (error) {
            yield put(actions.updateWorkingSubmissionFailed(error));
        }
    },

    listen: function* listen() {
        yield takeLatest(
            actionTypes.FETCH_WORKING_SUBMISSION,
            sagas.onFetchWorkingSubmission,
        );
        yield takeLatest(
            actionTypes.FETCH_SUBMISSION_STATES,
            sagas.onFetchSubmissionStates,
        );
        yield takeLatest(
            actionTypes.UPDATE_WORKING_SUBMISSION_STATE,
            sagas.onUpdateWorkingSubmissionState,
        );
        yield takeLatest(
            actionTypes.SIGNOFF_SUBMISSION,
            sagas.onSignoffSubmission,
        );
    },
};
