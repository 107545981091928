import { generatePath, Link } from 'react-router-dom';
import { AL_NIL_RETURN } from 'src/constants/activityLogTypeIds';
import { PATHS } from 'src/constants/constants';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { LogEntry } from '../../types';

import styles from './activityLogLink.module.scss';

interface ActivityLogLinkProps {
    logEntry: LogEntry;
}

const ActivityLogLink = ({ logEntry }: ActivityLogLinkProps) => {
    if (logEntry.type.id === AL_NIL_RETURN) return <>{logEntry.filename}</>;

    return (
        <Link
            className={styles.link}
            data-test-id={formatTestId('link to', logEntry?.filename)}
            to={generatePath(PATHS.REPORTS, {
                collectionId: logEntry.collectionId.toString(),
                submissionId: logEntry.submissionUuid,
            })}
        >
            {logEntry.filename}
        </Link>
    );
};

export default ActivityLogLink;
