import { get, post } from '../utils';

export const getProviders = async ({ collectionId, terminal = true }) =>
    get(`/submissions/bulk-reprocessing/providers/${collectionId}`, {
        query: { terminal },
    });

export const sendProvidersForProcessing = async ({ payload, collectionId }) =>
    post(`/submissions/bulk-reprocessing/providers/${collectionId}`, {
        body: payload,
    });
