import React, { useCallback } from 'react';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Loading from 'src/components/Loading/Loading';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import {
    getUiState,
    uiStates,
    useProviderStatuses,
    useSubmissionStates,
} from '../queries';

import styles from './stepSummary.module.scss';

const transformToSteps = states =>
    states.reduce((acc, { step }) => {
        return { ...acc, [step.number]: { ...step, count: 0 } };
    }, {});

const stepNumberForNotStarted = 1;

const groupIntoStepSummary = (providerStatuses, steps) => {
    const summary = providerStatuses.reduce((output, { latestSubmission }) => {
        const {
            status: { step },
        } = latestSubmission || {
            status: { step: steps[stepNumberForNotStarted] },
        };

        return {
            ...output,
            [step.number]: { ...step, count: output[step.number].count + 1 },
        };
    }, steps);

    return Object.values(summary);
};

const splitInTwo = steps => {
    const half = Math.ceil(steps.length / 2);

    return steps.reduce(
        ([a, b], step, index) => [
            [...a, ...(index < half ? [step] : [])],
            [...b, ...(index >= half ? [step] : [])],
        ],
        [[], []],
    );
};

const useStepSummary = ({ collectionReference }) => {
    const providerStatuses = useProviderStatuses(collectionReference);
    const submissionStates = useSubmissionStates();

    const status = getUiState([providerStatuses, submissionStates]);

    const steps =
        status === uiStates.LOADED
            ? groupIntoStepSummary(
                  providerStatuses.data,
                  transformToSteps(submissionStates.data),
              )
            : [];

    const loadData = useCallback(() => {
        providerStatuses.refetch();
        submissionStates.refetch();
    }, [providerStatuses, submissionStates]);

    return {
        status,
        steps: splitInTwo(steps),
        loadData,
    };
};

const displayStepMapping = {
    'Not started': 'Not Started',
    Ingestion: 'Upload',
    'Schema check': 'Schema Check',
    'Right to process check': 'Right To Process Check',
    Enrichment: 'Enrichment',
    'Quality assurance': 'Quality Assurance',
    Approval: 'Approval',
    'Awaiting sign off': 'Awaiting Sign Off',
    'Signed off': 'Signed Off',
};

export const StepSummary = ({ collectionReference }) => {
    const { status, steps, loadData } = useStepSummary({ collectionReference });

    const handleTryAgain = useCallback(() => {
        loadData();
    }, [loadData]);

    const renderSummary = () => {
        switch (status) {
            case uiStates.LOADING:
                return <Loading />;

            case uiStates.LOADED:
                return (
                    <Table data-test-id={formatTestId('table', 'step summary')}>
                        <TableHead>
                            <TableRow className={styles.header} xs={12}>
                                {steps.map((column, i) =>
                                    column.map((step, j) => (
                                        <TableCell
                                            className={styles.cell}
                                            key={`step-${i}-${j}`}
                                            count={step.count}
                                        >
                                            {step.number} {'. '}
                                            {/* {'.'} {step.name} */}
                                            {displayStepMapping[step.name]}
                                        </TableCell>
                                    )),
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className={styles.row}>
                                {steps.map((column, i) =>
                                    column.map((step, j) => (
                                        <TableCell key={`step-${i}-${j}`}>
                                            {step.count}
                                        </TableCell>
                                    )),
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                );
            case uiStates.LOADING_FAILED:
                return (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography
                            variant={'body2'}
                            paragraph
                            align={'center'}
                        >
                            The step summary failed to load.
                        </Typography>
                        <Button
                            variant={'outlined'}
                            size={'small'}
                            onClick={handleTryAgain}
                        >
                            Try again
                        </Button>
                    </Box>
                );

            default:
                return null;
        }
    };

    return <div className="Summary__accordion-wrapper">{renderSummary()}</div>;
};
