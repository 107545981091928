import { Params, RouteObject } from 'react-router-dom';
import AuthRoute from 'components/AuthRoute/AuthRoute';

const managementRoutes: RouteObject[] = [
    {
        element: <AuthRoute allowAllHesa />,
        children: [
            {
                path: 'management',
                async lazy() {
                    const { Management } = await import('pages/Management');
                    return { Component: Management };
                },
                handle: {
                    crumb: 'Management',
                    withoutLink: true,
                },
                children: [
                    {
                        path: 'collections',
                        handle: {
                            crumb: 'Collections',
                        },
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { Collections } = await import(
                                        'pages/Management'
                                    );
                                    return { Component: Collections };
                                },
                            },
                            {
                                path: 'bulk-reprocessing/:collectionId/:reference',
                                async lazy() {
                                    const { BulkReprocessing } = await import(
                                        'pages/Management/Collections/BulkReprocessing/BulkReprocessing'
                                    );
                                    return { Component: BulkReprocessing };
                                },

                                handle: {
                                    crumb: (params: Params<'reference'>) => {
                                        return `Bulk Reprocessing (${params.reference})`;
                                    },
                                },
                            },
                            {
                                path: 'historic-amendment/:collectionReference',
                                async lazy() {
                                    const { HistoricAmendment } = await import(
                                        'pages/Management/Collections/HistoricAmendment/HistoricAmendment'
                                    );
                                    return { Component: HistoricAmendment };
                                },

                                handle: {
                                    crumb: (
                                        params: Params<'collectionReference'>,
                                    ) => {
                                        return `Historic Amendment (${params.collectionReference})`;
                                    },
                                },
                            },
                        ],
                    },
                    {
                        path: 'providers',
                        async lazy() {
                            const { Providers } = await import(
                                'pages/Management/index'
                            );
                            return { Component: Providers };
                        },
                        handle: {
                            crumb: 'Providers',
                        },
                    },
                    {
                        path: 'specifications',
                        async lazy() {
                            const { Specifications } = await import(
                                'pages/Management'
                            );
                            return { Component: Specifications };
                        },
                        handle: {
                            crumb: 'Specifications',
                        },
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { Specs } = await import(
                                        'pages/Management/Specifications'
                                    );
                                    return { Component: Specs };
                                },
                            },
                            {
                                path: 'specs',
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { Specs } = await import(
                                                'pages/Management/Specifications'
                                            );
                                            return { Component: Specs };
                                        },
                                    },
                                    {
                                        path: 'new',
                                        async lazy() {
                                            const { NewSpecification } =
                                                await import(
                                                    'pages/Management/Specifications'
                                                );
                                            return {
                                                Component: NewSpecification,
                                            };
                                        },
                                        handle: {
                                            crumb: 'Add New Specification',
                                        },
                                    },
                                    {
                                        path: ':spec',
                                        async lazy() {
                                            const { Spec } = await import(
                                                'pages/Management/Specifications'
                                            );
                                            return { Component: Spec };
                                        },
                                        handle: {
                                            crumb: 'Details',
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'schemas',
                                async lazy() {
                                    const { Schemas } = await import(
                                        'pages/Management/Specifications'
                                    );
                                    return { Component: Schemas };
                                },
                                handle: {
                                    crumb: 'Schemas',
                                },
                            },
                            {
                                path: 'rulesets',
                                async lazy() {
                                    const { Rulesets } = await import(
                                        'pages/Management/Specifications'
                                    );
                                    return { Component: Rulesets };
                                },
                                handle: {
                                    crumb: 'Rulesets',
                                },
                            },
                        ],
                    },

                    {
                        path: 'delivery-config',
                        handle: {
                            crumb: 'Delivery Configurations',
                        },
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { DeliveryConfig } = await import(
                                        'pages/Management/DeliveryConfig/DeliveryConfig'
                                    );
                                    return { Component: DeliveryConfig };
                                },
                            },
                            {
                                path: 'create',
                                async lazy() {
                                    const { CreateDeliveryConfiguration } =
                                        await import(
                                            'pages/Management/DeliveryConfig/Containers/CreateDeliveryConfiguration'
                                        );
                                    return {
                                        Component: CreateDeliveryConfiguration,
                                    };
                                },
                                handle: {
                                    crumb: 'Create New Configuration',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default managementRoutes;
