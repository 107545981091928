import { ChangeEvent, MouseEvent } from 'react';
import { Table, TableBody, TablePagination } from '@mui/material';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { LogEntry } from '../types';

import ActivityLogRow from './ActivityLogRow/ActivityLogRow';
import ActivityLogTableHeader from './ActivityLogTableHeader/ActivityLogTableHeader';

interface ActivityLogTableProps {
    kind: 'collection' | 'provider';
    activityLogEntries: {
        records: LogEntry[];
        pagingMetadata: {
            totalResultsCount: number;
            limit: number;
            offset: number;
        };
    };
    handleChangePage: (
        event: MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => void;
    handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ActivityLogTable = ({
    kind,
    activityLogEntries,
    handleChangePage,
    handleChangeRowsPerPage,
}: ActivityLogTableProps) => {
    const { records, pagingMetadata } = activityLogEntries;

    return (
        <div>
            <>
                <Table
                    data-test-id={formatTestId('table', `${kind} activity log`)}
                >
                    <ActivityLogTableHeader kind={kind} />
                    <TableBody>
                        {records.map((logEntry, index) => (
                            <ActivityLogRow
                                kind={kind}
                                key={logEntry.id}
                                logEntry={logEntry}
                                index={index}
                            />
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={pagingMetadata.totalResultsCount || 0}
                    rowsPerPage={pagingMetadata.limit || 10}
                    page={pagingMetadata.offset / pagingMetadata.limit || 0}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
        </div>
    );
};

export default ActivityLogTable;
