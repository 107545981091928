import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';
import { Button, Paper } from '@mui/material';
import { DateTime } from 'luxon';
import { actions, selectors, states } from 'modules/files';
import AccessDenied from 'src/components/AccessDenied/AccessDenied';
import { RestrictAccess } from 'src/components/index';
import { requestOvtFileDownload } from 'src/pages/Collections/OnlineValidation/utils';
import { requestFileDownload } from 'src/pages/Collections/Submission/utils';

import { useStepper } from '../Stepper/StepperContext';

import UploadError from './UploadError';
import UploadForm from './UploadForm';
import UploadProgress from './UploadProgress';

import styles from './upload.module.scss';

const UploadedMessage = ({ instId, data, isOvt }) => (
    <>
        <div className={styles.uploadActions}>
            <p>
                File <strong>{data.fileName}</strong> was uploaded on{' '}
                {DateTime.fromISO(data.uploaded).toLocaleString(
                    DateTime.DATETIME_MED,
                )}
            </p>
            <p>
                <button
                    className={styles.linkButton}
                    onClick={() => {
                        !isOvt
                            ? requestFileDownload(
                                  data.uuid,
                                  instId,
                                  data.fileName,
                                  data.uploaded,
                                  false,
                              )
                            : requestOvtFileDownload(
                                  data.uuid,
                                  instId,
                                  data.fileName,
                                  data.uploaded,
                                  false,
                              );
                    }}
                >
                    Download Original File
                </button>
            </p>
        </div>
    </>
);

const UploadingMessage = ({
    submissionUuid,
    collectionId,
    instId,
    fileName,
    isOvt,
}) => {
    const refetchInterval = useRef();
    const dispatch = useDispatch();
    const progress = useSelector(selectors.getUploadProgress);
    const cancelState = useSelector(selectors.getCancelStatus);
    const { getSubmission } = useOutletContext();

    const handleCancel = useCallback(() => {
        dispatch(
            actions.cancelUpload({
                submissionUuid,
                collectionId,
                instId,
                fileName,
                isOvt,
            }),
        );
    }, [collectionId, dispatch, fileName, instId, isOvt, submissionUuid]);

    const cancelDisabled = useMemo(
        () =>
            cancelState === states.cancelState.CANCEL_PENDING ||
            cancelState === states.cancelState.CANCEL_SUCCESS,
        [cancelState],
    );

    useEffect(() => {
        if (
            progress === 100 ||
            cancelState === states.cancelState.CANCEL_SUCCESS
        ) {
            if (refetchInterval.current === undefined) {
                /*  
                    Refetches the submission on a 1 second interval when uploading is complete. 
                    This is to ensure that the submission status is updated to 'UPLOADED' in the UI.
                    Fixes an intermittent websocket disconnect issue where the submission status is not updated.
                    When the Upload component is unmounted, the interval is cleared.
                */
                refetchInterval.current = setInterval(
                    () => getSubmission(),
                    1000,
                );
            }
        }
    }, [cancelState, getSubmission, progress]);

    useEffect(() => {
        return () => {
            clearInterval(refetchInterval.current);
        };
    }, []);

    return (
        <>
            <div className={styles.uploadActions}>
                <span>Uploading</span>
                <UploadProgress progress={progress} />
                <div className={styles.or} aria-live="polite">
                    Please wait while we upload your file.
                </div>
                <RestrictAccess
                    allowPermissions={
                        !isOvt
                            ? ['collections.cancel-upload']
                            : ['online-validation.cancel-upload-ovt']
                    }
                >
                    <Button onClick={handleCancel} disabled={cancelDisabled}>
                        Cancel
                    </Button>
                </RestrictAccess>
            </div>
        </>
    );
};

const Upload = ({ isOvt = false }) => {
    const xhr = useRef(new XMLHttpRequest());

    const dispatch = useDispatch();
    const [state, setState] = useState();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const { navigateToStep, steps, currentState } = useStepper();
    const { submissionId } = useParams();
    const [isValidating, setIsValidating] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const { instId, data, collectionId, setPageTitle } = useOutletContext();

    useEffect(() => {
        setPageTitle('Upload');
    }, [setPageTitle]);

    useEffect(() => {
        return () => {
            dispatch(actions.resetUpload());
        };
    }, [dispatch]);

    const isUploaded = useMemo(() => {
        return steps?.some(step => step.label === 'Upload' && step.isComplete);
    }, [steps]);

    const hasError = useMemo(() => {
        return steps?.some(step => step.label === 'Upload' && step.isError);
    }, [steps]);

    const isUploading = useMemo(() => {
        return (
            currentState?.code === 'UPLOADING' ||
            state?.status?.code === 'UPLOADING'
        );
    }, [currentState?.code, state?.status?.code]);

    const uploadFile = useCallback(() => {
        if (isUploaded) {
            navigateToStep('Processing');
            return;
        }

        setState({
            fileName: fileName,
            status: { code: 'UPLOADING' },
        });

        dispatch(
            actions.uploadFile({
                submissionId,
                file,
                collectionId,
                instId,
                xhr: xhr.current,
                isOvt,
            }),
        );
    }, [
        isUploaded,
        fileName,
        dispatch,
        submissionId,
        file,
        collectionId,
        instId,
        isOvt,
        navigateToStep,
    ]);

    const uploadButtonDisabled = useMemo(
        () =>
            (!isUploaded && !fileName) ||
            isValidating ||
            hasValidationError ||
            state?.status?.code === 'UPLOADING',
        [state, isUploaded, fileName, hasValidationError, isValidating],
    );

    const UploadContent = useMemo(() => {
        if (hasError) {
            return <UploadError />;
        }

        if (isUploaded) {
            return (
                <UploadedMessage instId={instId} data={data} isOvt={isOvt} />
            );
        }

        if (isUploading) {
            return (
                <UploadingMessage
                    submissionUuid={data?.uuid || submissionId}
                    instId={instId}
                    collectionId={collectionId}
                    fileName={data?.fileName || fileName}
                    isOvt={isOvt}
                />
            );
        }

        return (
            <UploadForm
                setFile={setFile}
                setFileName={setFileName}
                fileName={fileName}
                isValidating={isValidating}
                setIsValidating={setIsValidating}
                hasValidationError={hasValidationError}
                setHasValidationError={setHasValidationError}
            />
        );
    }, [
        hasError,
        isUploaded,
        isUploading,
        fileName,
        isValidating,
        hasValidationError,
        instId,
        data,
        isOvt,
        submissionId,
        collectionId,
    ]);

    return (
        <RestrictAccess
            allowPermissions={
                !isOvt
                    ? ['collections.ingestion']
                    : ['online-validation.ingestion-ovt']
            }
            render={() => <AccessDenied mt={8} />}
        >
            <Paper className={styles.upload}>{UploadContent}</Paper>
            <div className={styles.buttons}>
                <Button
                    className="hdp-override large"
                    data-test="Upload"
                    disabled={uploadButtonDisabled}
                    disableRipple
                    variant="contained"
                    onClick={uploadFile}
                    data-test-id={
                        isUploaded || isUploading ? 'continue' : 'upload'
                    }
                >
                    {isUploaded || isUploading ? 'Continue' : 'Upload'}
                </Button>
                <Button
                    className="hdp-override large hdp-override--grey"
                    data-test-id="back"
                    disableRipple
                    variant="contained"
                    onClick={() => navigateToStep('Submissions')}
                >
                    Back
                </Button>
            </div>
        </RestrictAccess>
    );
};

export default Upload;
