import { useUserContext } from 'components';

import { Selector } from '../Selector';

export const StreamSelector = () => {
    const { activeStream, streams, changeStream } = useUserContext();

    if (!streams || !activeStream) {
        return null;
    }

    const options = streams.map(s => ({
        value: s.id,
        label: s.name,
        disabled: !s.available,
    }));

    return (
        <Selector
            heading={'Stream:'}
            options={options}
            value={activeStream.id}
            onChange={changeStream}
        />
    );
};

StreamSelector.displayName = 'StreamSelector';
