import { createContext } from 'react';

export type DropdownState = {
    [key: string]: boolean;
};

export type AppContextValues = {
    sidebar: {
        isOpen: boolean;
        setIsOpen: (isOpen: boolean) => void;
    };
    dropdown: {
        isDropdownOpen: DropdownState;
        setDropdownOpen: (
            updater: (prev: DropdownState) => DropdownState,
        ) => void;
    };
    // TODO: add other properties as we convert more files to ts
};

export const AppContext = createContext<AppContextValues | undefined>(
    undefined,
);
