import { useQuery } from '@tanstack/react-query';
import {
    providers as providersApi,
    submissions as submissionsApi,
} from 'src/services/api';

export const useCollections = ({ instId, state }) =>
    useQuery({
        queryKey: ['collections', instId, state],
        queryFn: () =>
            providersApi.getCollections({
                instId,
                state,
            }),
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

export const useCollectionSubmissions = ({ collectionId, instId, isOvt }) => {
    const endpoint = isOvt
        ? submissionsApi.getOvtSubmissions
        : submissionsApi.getSubmissions;

    return useQuery({
        queryKey: [
            'collection-submissions',
            collectionId,
            instId,
            isOvt ? 'ovt' : '',
        ],
        queryFn: () =>
            endpoint({
                collectionId,
                instId,
            }),
        select: data =>
            data.toSorted((a, b) => b.SubmissionId - a.SubmissionId),
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
};
