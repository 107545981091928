import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, states } from 'modules/providers';

const useProvider = ({ instId }) => {
    const dispatch = useDispatch();
    const storeState = useSelector(selectors.getFetchStatus);
    const provider = useSelector(
        instId ? selectors.getProviderByInst(instId) : selectors.getProviders(),
    );

    useEffect(() => {
        const providersNotInitialised = storeState === states.UNINITIALISED;
        if (providersNotInitialised) {
            dispatch(actions.getProviders());
        }
    }, [dispatch, storeState, provider]);

    return provider ?? instId;
};

export default useProvider;
