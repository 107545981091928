import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Link, NativeSelect } from '@mui/material';
import { RestrictAccess } from 'components';
import { actions, selectors, states } from 'modules/collections';
import { selectors as specificationsSelectors } from 'modules/specifications';
import PropTypes from 'prop-types';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { CancelButton, EditButton, SaveButton } from '../Buttons';

const specificationModes = {
    UNASSIGNED: 1,
    DISPLAY: 2,
    EDITING: 3,
    SAVING: 4,
    ERROR: 5,
};

export const Specification = ({ reference }) => {
    const { UNASSIGNED, DISPLAY, EDITING, SAVING, ERROR } = specificationModes;

    const dispatch = useDispatch();

    const { id, name, status } = useSelector(
        selectors.getSpecification(reference),
    );
    const isEditable = useSelector(
        selectors.isSpecificationEditable(reference),
    );
    const versions = useSelector(specificationsSelectors.getVersions);
    const [mode, setMode] = useState(id ? DISPLAY : UNASSIGNED);

    const [value, setValue] = useState(id || versions[0].id);

    useEffect(() => {
        switch (status) {
            case states.specification.UPDATING:
                return setMode(SAVING);

            case states.specification.UPDATED:
                return setMode(DISPLAY);

            case states.specification.UPDATING_FAILED:
                return setMode(ERROR);

            default:
                return;
        }
    }, [DISPLAY, ERROR, SAVING, status]);

    useEffect(() => {
        if ([DISPLAY].includes(mode)) {
            setValue(id);
        }
    }, [DISPLAY, UNASSIGNED, id, mode]);

    const handleChange = event => {
        setValue(event.target.value);
    };

    const handleEdit = () => setMode(EDITING);
    const handleCancel = () => setMode(id ? DISPLAY : UNASSIGNED);
    const handleSave = useCallback(
        () =>
            dispatch(
                actions.updateSpecification({
                    reference,
                    specificationId: value,
                }),
            ),
        [dispatch, reference, value],
    );

    const renderDropdown = ({ disabled = false, error = false } = {}) => (
        <NativeSelect
            value={value}
            onChange={handleChange}
            disabled={disabled}
            error={error}
            inputProps={{
                'aria-label': 'Specification versions',
                'data-test-id': formatTestId('select specification', reference),
            }}
        >
            {versions.map(({ id, name }) => (
                <option key={id} value={id}>
                    {name}
                </option>
            ))}
        </NativeSelect>
    );

    const getFallbackComponent = () => {
        return (
            <Wrapper justifyContent={'flex-start'}>
                No specification assigned
            </Wrapper>
        );
    };

    const renderAssignLink = () => (
        <RestrictAccess
            allowPermissions={['management.assign-specification']}
            fallBackComponent={getFallbackComponent()}
        >
            <Link
                className="hdp-override"
                component="button"
                variant="body2"
                onClick={handleEdit}
                data-test-id={formatTestId('assign specification', reference)}
            >
                Assign specification version
            </Link>
        </RestrictAccess>
    );

    const renderEditButton = () => (
        <RestrictAccess allowPermissions={['management.assign-specification']}>
            <EditButton
                title="Change specification version"
                onClick={handleEdit}
                data-test-id={formatTestId('changeSpecification', reference)}
            />
        </RestrictAccess>
    );

    const renderSaveButton = ({ loading = false } = {}) => (
        <SaveButton
            title="Save specification version"
            onClick={handleSave}
            loading={loading}
            disabled={loading}
            data-test-id={formatTestId('saveSpecification', reference)}
        />
    );

    const renderCancelButton = ({ disabled = false } = {}) => (
        <CancelButton
            title="Cancel"
            onClick={handleCancel}
            disabled={disabled}
            data-test-id={formatTestId('cancelSpecificationChange', reference)}
        />
    );

    const Wrapper = ({ children, justifyContent = 'space-between' }) => (
        <Box
            display={'flex'}
            justifyContent={justifyContent}
            alignItems={'center'}
        >
            {children}
        </Box>
    );

    switch (mode) {
        case EDITING:
            return (
                <Wrapper>
                    {renderDropdown()}
                    <Box>
                        {renderSaveButton()}
                        {renderCancelButton()}
                    </Box>
                </Wrapper>
            );

        case SAVING:
            return (
                <Wrapper>
                    {renderDropdown({ disabled: true })}
                    <Box>
                        {renderSaveButton({ loading: true })}
                        {renderCancelButton({ disabled: true })}
                    </Box>
                </Wrapper>
            );

        case ERROR:
            return (
                <Wrapper>
                    {renderDropdown({ error: true })}
                    <Box>
                        {renderSaveButton()}
                        {renderCancelButton()}
                    </Box>
                </Wrapper>
            );

        case UNASSIGNED:
            return (
                <Wrapper justifyContent={'flex-start'}>
                    {renderAssignLink()}
                </Wrapper>
            );

        default:
            return (
                <Wrapper justifyContent={'flex-start'}>
                    {name}
                    {isEditable && renderEditButton()}
                </Wrapper>
            );
    }
};

Specification.propTypes = {
    reference: PropTypes.string.isRequired,
};
