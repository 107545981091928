import decode from 'jwt-decode';
import * as auth from 'modules/auth';
import * as providers from 'modules/providers';
import * as regulators from 'modules/regulators';
import * as streams from 'modules/streams';
import * as tokens from 'modules/tokens';
import * as user from 'modules/user';
import { createAction } from 'redux-actions';
import {
    all,
    call,
    put,
    race,
    select,
    spawn,
    take,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { history } from 'services/history';
import { PATHS } from 'src/constants/constants';

export const actionTypes = {
    LOAD: 'app/LOAD',
    RELOAD: 'app/RELOAD',
    LOADED: 'app/LOADED',
    LOAD_FAILED: 'app/LOAD_FAILED',
};

export const actions = {
    load: createAction(actionTypes.LOAD),
    reload: createAction(actionTypes.RELOAD),
    loaded: createAction(actionTypes.LOADED),
    loadFailed: createAction(actionTypes.LOAD_FAILED),
};

export const states = {
    UNINITIALISED: 1,
    LOADING: 2,
    LOADED: 3,
    LOAD_FAILED: 4,
};

export const errors = {
    NO_ORGANISATIONS: 'NO_ORGANISATIONS',
    NO_PROVIDERS: 'NO_PROVIDERS',
    NO_DATA: 'NO_DATA',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

const INITIAL_STATE = {
    state: states.UNINITIALISED,
    error: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.LOAD:
            return { ...state, state: states.LOADING, error: null };

        case actionTypes.RELOAD:
            return { ...state, state: states.LOADING, error: null };

        case actionTypes.LOADED:
            return { ...state, state: states.LOADED };

        case actionTypes.LOAD_FAILED:
            return {
                ...state,
                state: states.LOAD_FAILED,
                error: action.payload,
            };

        default:
            return state;
    }
};

const pipe =
    (...fns) =>
    x =>
        fns.reduce((f, g) => g(f), x);
const map = fn => a => a.map(fn);
const filter = fn => a => a.filter(fn);

const usingData =
    ({ providers, regulators }) =>
    ({ id, type }) => {
        switch (type) {
            case 'provider': {
                return providers.find(
                    provider => provider.institutionId === id,
                );
            }

            case 'hesa': {
                return { name: 'HESA' };
            }

            case 'statutory_customer': {
                return regulators.find(regulator => regulator.code === id);
            }

            default:
                return undefined;
        }
    };

const addOrganisationData =
    get =>
    ({ id, type, streams }) => ({
        id,
        type,
        streams,
        organisation: get({ id, type }),
    });

const addStreamNames = streams => organisation => ({
    ...organisation,
    streams: organisation.streams.map(stream => ({
        ...stream,
        name: streams[stream.id]?.name,
    })),
});

const isSelectable = organisation => {
    if (organisation.organisation === undefined) {
        return false;
    }

    if (
        organisation.type === 'provider' &&
        organisation.organisation.onboarded !== true
    ) {
        return false;
    }

    return true;
};

const getOrganisations = createSelector(
    providers.selectors.getProviders,
    regulators.selectors.getRegulators,
    streams.selectors.getStreamsMap,
    user.selectors.getOrganisations,
    (providers, regulators, streams, organisations) => {
        return pipe(
            map(addOrganisationData(usingData({ providers, regulators }))),
            map(addStreamNames(streams)),
            filter(isSelectable),
        )(organisations);
    },
);

const getActiveOrganisation = createSelector(
    getOrganisations,
    user.selectors.getActiveOrganisationId,
    user.selectors.getActiveOrganisationType,
    (organisations, organisationId, organisationType) => {
        return organisations.find(
            organisation =>
                organisation.id === organisationId &&
                organisation.type === organisationType,
        );
    },
);

export const selectors = {
    getState: state => state.app.state,
    getError: state => state.app.error,

    getOrganisations,
    getActiveOrganisation,
};

function* fetchAppData() {
    yield put(providers.actions.fetch());
    yield put(regulators.actions.fetch());
    yield put(streams.actions.fetch());

    const result = yield race({
        all: all({
            providers: take(providers.actionTypes.FETCH_PROVIDERS_SUCCESS),
            regulators: take(regulators.actionTypes.FETCH_REGULATORS_SUCCESS),
            streams: take(streams.actionTypes.FETCH_STREAMS_SUCCESS),
        }),
        providers: take(providers.actionTypes.FETCH_PROVIDERS_FAILED),
        regulators: take(regulators.actionTypes.FETCH_REGULATORS_FAILED),
        streams: take(streams.actionTypes.FETCH_STREAMS_FAILED),
    });

    if (!result.all) {
        return yield put(actions.loadFailed(errors.NO_DATA));
    }
}

function* watchUnauthorisedErrors() {
    yield takeEvery('*', function* onEvery(action) {
        if (action.error && action.payload.name === 'UnauthorisedError') {
            yield put(user.actions.logout());
        }
    });
}

function* watchUserFound() {
    const { payload } = yield take(auth.actionTypes.USER_FOUND);
    yield put(tokens.actions.hesaTokenReceived(payload.access_token));
}

function* watchValidToken() {
    yield takeLatest(
        tokens.actionTypes.VALID_TOKEN,
        function* onValidToken({ payload }) {
            const { organisations } = decode(payload);
            yield put(user.actions.setRoles(organisations));
        },
    );
}

function* watchIdsInvalidToken() {
    const { payload } = yield take(tokens.actionTypes.INVALID_TOKEN);

    if (payload === tokens.tokenErrors.NO_ORGANISATIONS) {
        return yield put(actions.loadFailed(errors.NO_ORGANISATIONS));
    }

    return yield put(actions.loadFailed(errors.UNKNOWN_ERROR));
}

function* watchChangeOrganisation() {
    yield takeLatest(
        user.actionTypes.CHANGE_ORGANISATION,
        function* onChangeOrganisation() {
            return yield put(actions.reload());
        },
    );
}

function* watchChangeStream() {
    yield takeLatest(
        user.actionTypes.CHANGE_STREAM,
        function* onChangeStream() {
            return yield put(actions.reload());
        },
    );
}

function* watchSetPermissions() {
    yield takeLatest(
        user.actionTypes.SET_PERMISSIONS,
        function* onSetPermissions({ payload }) {
            // If we are setting permissions again, then we need to send the user
            // back to the dashboard. As they may not have access to the page they're on
            // after the permission change.
            if (!payload.initialRequest) {
                history.replace(PATHS.DASHBOARD);
            }
            return yield put(actions.loaded());
        },
    );
}

function* watchSetRoles() {
    yield takeLatest(user.actionTypes.SET_ROLES, function* onSetRoles() {
        yield call(fetchAppData);

        const organisations = yield select(selectors.getOrganisations);
        const organisation =
            organisations &&
            organisations.find(
                o =>
                    o.organisation.onboarded ||
                    ['hesa', 'statutory_customer'].includes(o.type),
            );

        if (!organisation) {
            return yield put(actions.loadFailed(errors.NO_PROVIDERS));
        }

        return yield put(user.actions.changeOrganisation(organisation));
    });
}

function* watchIdsLogout() {
    yield take(user.actionTypes.LOGOUT);
    yield put(auth.actions.signOut());
}

function* watchSignOutSuccess() {
    yield take(auth.actionTypes.SIGN_OUT_CALLBACK_SUCCESS);
    yield put(tokens.actions.clearToken());
}

function* loadUser() {
    yield put(auth.actions.loadUser());
}

export const sagas = {
    setup: function* setup() {
        yield take(actionTypes.LOAD);

        yield spawn(watchUnauthorisedErrors);
        yield spawn(watchUserFound);
        yield spawn(watchValidToken);
        yield spawn(watchIdsInvalidToken);
        yield spawn(watchChangeOrganisation);
        yield spawn(watchChangeStream);
        yield spawn(watchSetPermissions);
        yield spawn(watchSetRoles);
        yield spawn(watchIdsLogout);
        yield spawn(watchSignOutSuccess);

        yield call(loadUser);
    },

    listen: function* () {
        yield call(sagas.setup);
    },
};
