import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { rulesets as rulesetsApi } from 'services/api';

export const actionTypes = {
    FETCH_RULESETS: 'rulesets/FETCH',
    FETCH_RULESETS_SUCCESS: 'rulesets/FETCH_SUCCESS',
    FETCH_RULESETS_FAILED: 'rulesets/FETCH_FAILED',
};

export const actions = {
    fetch: createAction(actionTypes.FETCH_RULESETS),
    fetchSuccess: createAction(actionTypes.FETCH_RULESETS_SUCCESS),
    fetchFailed: createAction(actionTypes.FETCH_RULESETS_FAILED),
};

export const states = {
    fetch: {
        UNINITIALISED: 1,
        LOADING: 2,
        LOADED: 3,
        LOADING_FAILED: 4,
    },
};

const INITIAL_STATE = {
    state: {
        fetch: states.fetch.UNINITIALISED,
    },
    rulesets: {},
};

const keyBy = (a, key) =>
    a.reduce((output, i) => ({ ...output, [i[key]]: i }), {});

const addIndex = (item, index) => ({ ...item, index });

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_RULESETS:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING,
                },
            };

        case actionTypes.FETCH_RULESETS_SUCCESS:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADED,
                },
                rulesets: keyBy(action.payload.rulesets.map(addIndex), 'id'),
            };

        case actionTypes.FETCH_RULESETS_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING_FAILED,
                },
            };

        default:
            return state;
    }
};

const byIndexDesc = (a, b) => a.index - b.index;

const rulesets = state => Object.values(state.rulesets.rulesets);

export const selectors = {
    getFetchStatus: state => state.rulesets.state.fetch,
    getRulesets: createSelector(rulesets, rulesets =>
        rulesets.sort(byIndexDesc),
    ),
};

export const sagas = {
    onFetchRulesets: function* onFetchRulesets({ payload = {} }) {
        try {
            const { schemaId, state } = payload;
            const response = yield call(rulesetsApi.getAll, {
                schemaId,
                state,
            });
            yield put(actions.fetchSuccess(response));
        } catch (error) {
            yield put(actions.fetchFailed(error));
        }
    },

    listen: function* listen() {
        yield takeLatest(actionTypes.FETCH_RULESETS, sagas.onFetchRulesets);
    },
};
