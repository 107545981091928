import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { useEffect } from 'react';
import {
    useLocation,
    useMatches,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { submissions as submissionsApi } from 'services/api';
import { FILE_STATUS, fileStatusById } from 'src/constants/FileStatus';

import { inferStepLabelFromRoute } from '../../utils';

export const StepperContext = createContext({});
export const useStepper = () => useContext(StepperContext);

const StepperProvider = ({
    submissionId,
    submissionData,
    steps,
    paths,
    ovt = false,
    isStatutoryCustomer,
    children,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeStep, setActiveStep] = useState({
        label: '',
    });
    const [stateHistory, currentState] = useMemo(() => {
        const { stateHistory, status: currentState } = submissionData;
        return [stateHistory, currentState];
    }, [submissionData]);
    const matches = useMatches();
    const params = useParams();
    const userRequestedStepLabel = inferStepLabelFromRoute(matches, steps);

    const [stepData, setStepData] = useState(
        steps?.map(step => ({ label: step })),
    );

    // Additional Reports & Credibility Reports routes use :reportCode
    // Quality Reports routes use :rule
    const isUserViewingReport = !!params?.reportCode || !!params.rule;

    const isEveryStepComplete = stepData.every(
        step => step?.isComplete === true,
    );

    const isEveryStepValidated = stepData.every(
        step => 'isComplete' in step && 'isError' in step && 'isActive' in step,
    );

    const canNavigateToStep = useCallback(
        stepLabel => {
            const step = stepData.find(step => step.label === stepLabel);
            return step?.isComplete || step?.isError || step?.isActive;
        },
        [stepData],
    );

    const navigateToStep = useCallback(
        (step, replace) => {
            if (!step) return;

            const path = paths[step];
            if (path !== location.pathname) {
                navigate(path, { replace });
            }
        },
        [paths, location.pathname, navigate],
    );

    const targetStep = useMemo(() => {
        if (!isEveryStepValidated || isUserViewingReport) return;

        // If the user has requested a specific step (e.g by refreshing the page), navigate to that step
        if (canNavigateToStep(userRequestedStepLabel)) {
            return stepData.find(step => step.label === userRequestedStepLabel);
        } else {
            // Redirect to sign-off step if all steps are complete
            if (isEveryStepComplete && !isStatutoryCustomer) {
                return stepData.at(-1);
            }

            const incompleteStepIndex = stepData.findLastIndex(
                step => step?.isError || step?.isActive,
            );

            const qualityAssuranceStepIndex = stepData.findIndex(
                step => step.label === 'Quality Assurance',
            );

            if (
                isStatutoryCustomer &&
                incompleteStepIndex > qualityAssuranceStepIndex
            ) {
                return stepData[qualityAssuranceStepIndex];
            }

            return stepData[incompleteStepIndex];
        }
    }, [
        isEveryStepComplete,
        isEveryStepValidated,
        canNavigateToStep,
        isStatutoryCustomer,
        isUserViewingReport,
        stepData,
        userRequestedStepLabel,
    ]);

    useEffect(() => {
        if (!targetStep) return;

        if (targetStep.label !== activeStep.label) {
            const shouldReplaceHistoryEntry = !canNavigateToStep(
                userRequestedStepLabel,
            );
            setActiveStep(targetStep);
            navigateToStep(targetStep.label, shouldReplaceHistoryEntry);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, navigateToStep, targetStep, canNavigateToStep]);

    const updateStep = useCallback(
        (index, newStep) => {
            const newSteps = [...stepData];
            newSteps[index] = newStep;
            setStepData(newSteps);
        },
        [stepData],
    );

    const shouldFetchCommitStatus = useMemo(() => {
        if (!stateHistory?.length) return false;

        const currentStateBeyondQA =
            fileStatusById(currentState?.id)?.order >
            FILE_STATUS.QUALITY_PROCESSING_COMPLETE.order;

        return (
            stateHistory?.some(
                state =>
                    state.StatusId ===
                    FILE_STATUS.QUALITY_PROCESSING_COMPLETE.id,
            ) ||
            (submissionData?.copiedFrom && currentStateBeyondQA)
        );
    }, [stateHistory, currentState, submissionData]);

    const commitStatusQuery = useQuery({
        queryKey: ['commitStatus', submissionId],
        queryFn: () =>
            submissionsApi.getSubmissionCommitAllowedStatus({
                submissionId,
            }),
        enabled:
            Boolean(!ovt) &&
            Boolean(submissionId) &&
            Boolean(shouldFetchCommitStatus),
    });

    const ovtQuery = useQuery({
        queryKey: ['ovtFailures', submissionId],
        queryFn: () =>
            submissionsApi.getOvtFailureCounts({
                submissionId,
                instId: submissionData?.provider?.instId,
            }),
        enabled:
            Boolean(ovt) &&
            Boolean(submissionId) &&
            Boolean(shouldFetchCommitStatus),
    });

    const contextValue = useMemo(
        () => ({
            currentState,
            stateHistory,
            commitStatusQuery,
            ovtQuery,
            paths,
            updateStep,
            steps: stepData,
            ovt,
            submissionData,
            navigateToStep,
            isSubmissionSignedOff:
                currentState?.id === FILE_STATUS.SIGNED_OFF.id,
            isBurpSubmission: submissionData?.copiedFrom,
            activeStep,
        }),
        [
            currentState,
            stateHistory,
            commitStatusQuery,
            ovtQuery,
            paths,
            updateStep,
            stepData,
            ovt,
            submissionData,
            navigateToStep,
            activeStep,
        ],
    );

    return (
        <StepperContext.Provider value={contextValue}>
            {children}
        </StepperContext.Provider>
    );
};

export default StepperProvider;
