import { useMemo } from 'react';
import { useUserContext } from 'components';
import TabPanel from 'components/Tabs/TabPanel';
import { DateTime } from 'luxon';
import Loading from 'src/components/Loading/Loading';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';

import {
    getUiState,
    uiStates,
    useOvtProviderStatuses,
    useProviderStatuses,
    useSubmissionStates,
} from '../queries';
import { Filters } from '../SubmissionSummary/Filters';

import { OvtSubmissionSummaryTable } from '.';

const transformToSteps = states => {
    const steps = states.reduce((acc, { step }) => {
        return { ...acc, [step.number]: { ...step, count: 0 } };
    }, {});

    return Object.values(steps);
};

const addTotalSteps = steps => providerStatus => {
    return {
        ...providerStatus,
        totalSteps: steps.length,
    };
};

const transformToAwaitingParties = states => {
    return states.reduce((output, state) => {
        return {
            ...output,
            [state.id]: state.awaitingParty,
        };
    }, {});
};

const addAwaitingParty = awaitingParties => providerStatus => {
    return {
        ...providerStatus,
        awaitingParty: providerStatus.latestSubmission
            ? awaitingParties[providerStatus.latestSubmission?.status.id]
            : { id: 1, name: 'Provider' },
    };
};

const applyFilters = filters => providerStatus =>
    Object.entries(filters).reduce((acc, [name, value]) => {
        if (acc === false) {
            return acc;
        }

        if (value === undefined) {
            return true;
        }

        switch (name) {
            case 'institutionId':
                return providerStatus.provider.institutionId === value;

            case 'state':
                return (
                    (value === '__NOT_STARTED' &&
                        providerStatus.latestSubmission === null) ||
                    providerStatus.latestSubmission?.status.code === value
                );

            case 'awaitingAction':
                return providerStatus.awaitingParty?.id === value;

            case 'regulatorCode':
                return providerStatus.provider.regulatorCode === value;

            default:
                return true;
        }
    }, true);

const sortByLastSubmission = (a, b) => {
    const aDate = DateTime.fromISO(a.latestSubmission.uploaded);
    const bDate = DateTime.fromISO(b.latestSubmission.uploaded);

    if (aDate < bDate) return 1;
    else if (aDate === bDate) return 0;
    else return -1;
};

const sortByStep = prop => order => (a, b) => {
    if (a[prop] && b[prop]) {
        if (a[prop].status.step.number < b[prop].status.step.number)
            return order === 'desc' ? 1 : -1;

        if (a[prop].status.step.number > b[prop].status.step.number)
            return order === 'desc' ? -1 : 1;
    }

    if (b[prop] && !a[prop]) return order === 'desc' ? 1 : -1;
    if (!b[prop] && a[prop]) return order === 'desc' ? -1 : 1;

    if (a.provider.name > b.provider.name) return 1;
    if (a.provider.name < b.provider.name) return -1;

    return 0;
};

const sortByCurrentStep = sortByStep('latestSubmission');
const sortByFurthestStep = sortByStep('furthestSubmission');

const sortOvtResults = (array, order, orderBy) => {
    let sortedArray;

    switch (orderBy) {
        case 'last-submission':
            sortedArray = array
                .filter(sub => !!sub?.latestSubmission?.uploaded)
                .sort(sortByLastSubmission);
            if (order !== 'desc') sortedArray.reverse();

            return sortedArray.concat(
                array.filter(sub => !sub?.latestSubmission?.uploaded),
            );

        case 'current-step':
            return array.toSorted(sortByCurrentStep(order));

        case 'furthest-step':
            return array.toSorted(sortByFurthestStep(order));

        default:
            return array;
    }
};

const pipe =
    (...fns) =>
    x =>
        fns.reduce((v, f) => f(v), x);

const useOvtProviderSubmissions = ({
    collectionReference,
    isHesa,
    filters,
    sortBy,
    sortOrder,
}) => {
    const ovtProviderStatuses = useOvtProviderStatuses(
        collectionReference,
        isHesa,
    );
    const providerStatuses = useProviderStatuses(collectionReference);

    const submissionStates = useSubmissionStates();
    const status = useMemo(
        () => getUiState([providerStatuses, submissionStates]),
        [providerStatuses, submissionStates],
    );

    const ovtStatus = useMemo(
        () => getUiState([ovtProviderStatuses, submissionStates]),
        [ovtProviderStatuses, submissionStates],
    );

    const ovtSubmissions = useMemo(() => {
        return ovtStatus === uiStates.LOADED
            ? ovtProviderStatuses.data.map(
                  pipe(
                      addTotalSteps(transformToSteps(submissionStates.data)),
                      addAwaitingParty(
                          transformToAwaitingParties(submissionStates.data),
                      ),
                  ),
              )
            : [];
    }, [ovtProviderStatuses, submissionStates, ovtStatus]);

    const filteredOvtSubmissions = useMemo(() => {
        const filtered = ovtSubmissions.filter(applyFilters(filters));
        const sorted = sortOvtResults(filtered, sortOrder, sortBy);
        return sorted;
    }, [ovtSubmissions, filters, sortOrder, sortBy]);

    const states = useMemo(() => {
        return status === uiStates.LOADED ? submissionStates.data : [];
    }, [submissionStates, status]);

    return {
        ovtStatus,
        filteredOvtSubmissions,
        ovtSubmissions,
        submissionStates: states,
    };
};

export const OvtSubmissionSummary = ({ collectionReference }) => {
    const { isHesa } = useUserContext();

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
        selectedProvider: {},
        filters: {},
        sortBy: 'last-submission',
        sortOrder: 'desc',
    };

    const tableId = TABLE_PARAMS_IDS.SUBMISSIONS_SUMMARY_OVT;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset, filters, sortBy, sortOrder } = tableParams;

    const { setLimit, setOffset, setFilters, setSortBy, setSortOrder } =
        tableParamsMethods;

    const {
        ovtStatus,
        filteredOvtSubmissions,
        ovtSubmissions,
        submissionStates,
    } = useOvtProviderSubmissions({
        collectionReference,
        isHesa,
        filters,
        sortBy,
        sortOrder,
    });

    const handleFiltersChange = newFilter => {
        setFilters({ ...filters, ...newFilter });
    };

    const handleSortChange = id => {
        if (sortBy === id) {
            // If already sorting by the same column, flip the order
            const flippedSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
            setSortOrder(flippedSortOrder);
        } else {
            // If sorting by a different column, set the order to the default
            setSortOrder(DEFAULT_TABLE_PARAMETERS.sortOrder);
            setSortBy(id);
        }
    };

    const renderContent = () => {
        switch (ovtStatus) {
            case uiStates.LOADING:
                return <Loading />;

            case uiStates.LOADED:
                return (
                    <>
                        <TabPanel value={0} index={0}>
                            <Filters
                                filters={filters}
                                submissions={ovtSubmissions}
                                submissionStates={submissionStates}
                                onChange={handleFiltersChange}
                                isOvt={true}
                            />
                            <OvtSubmissionSummaryTable
                                isOvt
                                submissions={filteredOvtSubmissions}
                                order={sortOrder}
                                orderBy={sortBy}
                                onSort={handleSortChange}
                                limit={limit}
                                setLimit={setLimit}
                                offset={offset}
                                setOffset={setOffset}
                            />
                        </TabPanel>
                    </>
                );

            default:
                return null;
        }
    };

    return <>{renderContent()}</>;
};
