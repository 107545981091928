import React from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

import styles from './providerFilter.module.scss';

export const ProviderFilter = React.memo(({ value, providers, onChange }) => {
    const sortAlphabetically = providers =>
        providers.slice().sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        });

    const handleChange = (_e, option) => {
        onChange(option || {});
    };

    const selected =
        providers.find(provider => provider.institutionId === value) || null;

    return (
        <Autocomplete
            className="hdp-override"
            sx={{ width: 400 }}
            options={sortAlphabetically(providers)}
            onChange={handleChange}
            value={selected}
            getOptionLabel={option => `${option.institutionId} ${option.name}`}
            renderOption={(props, option) => (
                <span {...props}>
                    <span className={styles.providerFilterId}>
                        {option.institutionId}
                    </span>
                    <span>{option.name}</span>
                </span>
            )}
            renderInput={params => (
                <TextField {...params} placeholder="Filter by provider" />
            )}
        />
    );
});

ProviderFilter.displayName = 'ProviderFilter';
