import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, CircularProgress, List, ListItem } from '@mui/material';
import { RestrictAccess } from 'components';
import BannerBox from 'components/BannerBox/BannerBox';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import {
    providers as providersApi,
    submissions as submissionsApi,
} from 'services/api';
import { useUserContext } from 'src/components';
import {
    HDPAccordion,
    HDPAccordionDetails,
    HDPAccordionGroup,
    HDPAccordionSummary,
} from 'src/components/HDPAccordion';
import { ACCORDION_IDS, OVTPATHS } from 'src/constants/constants';
import { FILE_STATUS } from 'src/constants/FileStatus';
import { v4 as uuid } from 'uuid';

import { SubmissionLink } from '../Submission/Submissions';
import { formatDate } from '../Submission/utils';

import styles from '../Submission/submissions.module.scss';

export const Submissions = () => {
    const history = useNavigate();
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [selectedCollectionObject] = useState(null);
    const [collectionsLoading, setCollectionsLoading] = useState(true);

    const { activeOrganisation } = useUserContext();

    const openUploadStep = (event, collection) => {
        event.stopPropagation();
        const newSubmissionRoute = generatePath(OVTPATHS.UPLOAD, {
            collectionId: collection.id,
            submissionId: uuid().toUpperCase(),
        });
        history(newSubmissionRoute);
    };

    const selectCollection = async (event, expanded, collectionId) => {
        setSelectedCollection(expanded ? collectionId : null);
    };

    useEffect(() => {
        const fetchData = async () => {
            const collections = await providersApi.getCollections({
                instId: activeOrganisation.id,
                state: ['open', 'historic amendment', 'validation'],
            });

            const collectionsWithSubs = await Promise.all(
                collections.map(async collection => {
                    const submissions = await submissionsApi.getOvtSubmissions({
                        collectionId: collection.id,
                        instId: activeOrganisation.id,
                    });
                    return {
                        ...collection,
                        latestSubmission: submissions?.at(-1),
                    };
                }),
            );

            setCollections(collectionsWithSubs);
            setCollectionsLoading(false);
        };
        fetchData();
    }, [activeOrganisation, selectedCollection, selectedCollectionObject]);

    return (
        <React.Fragment>
            <h1 className="heading--h1 margin--bottom-1" id="heading">
                Online Validation Tool
            </h1>
            <BannerBox
                heading="Online validation tool"
                upperText="This is the online validation tool (OVT). This tool is designed for providers to validate their files. Once your file is ready to be analysed you will need to upload it to the relevant live collection. Please note that only the latest uploaded file will be available to view."
                lowerText={
                    <span>
                        If you need support please contact{' '}
                        <a href="mailto:liaison@hesa.ac.uk">HESA Liaison.</a>
                    </span>
                }
            />
            <div className={styles.accordionheader}>
                <span className={styles.accordionHeaderName}>Name</span>
                <span className={styles.accordionHeaderHeading}>
                    Date range
                </span>

                <span className={styles.accordionHeaderHeading}>&nbsp;</span>
                <span className={styles.accordionHeaderHeading}>&nbsp;</span>
            </div>
            <div>
                {collectionsLoading ? (
                    <CircularProgress aria-label="Circular loading animation" />
                ) : (
                    <HDPAccordionGroup>
                        {collections.map(collection => {
                            const from = formatDate(
                                collection.referencePeriodStart,
                            );
                            const to = formatDate(
                                collection.referencePeriodEnd,
                            );

                            return (
                                <HDPAccordion
                                    key={collection.id}
                                    onChange={(event, expanded) =>
                                        selectCollection(
                                            event,
                                            expanded,
                                            collection.id,
                                        )
                                    }
                                    searchParamId={
                                        ACCORDION_IDS.MANAGE_SUBMISSIONS
                                            .COLLECTION + collection.reference
                                    }
                                >
                                    <HDPAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        size="md"
                                        fontWeight="normal"
                                        header={
                                            <div
                                                className={
                                                    styles.accordionSummary
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles.accordionName
                                                    }
                                                >
                                                    {collection.name}
                                                </span>
                                                <span
                                                    className={
                                                        styles.accordionDate
                                                    }
                                                >{`${from} - ${to}`}</span>
                                            </div>
                                        }
                                    >
                                        <div className={styles.actions}>
                                            <RestrictAccess
                                                allowPermissions={[
                                                    'online-validation.online-validation',
                                                ]}
                                            >
                                                <Button
                                                    className={`hdp-override ${styles.uploadFile}`}
                                                    data-test="Add submission"
                                                    disableRipple
                                                    variant="contained"
                                                    onClick={e =>
                                                        openUploadStep(
                                                            e,
                                                            collection,
                                                        )
                                                    }
                                                >
                                                    Upload File
                                                </Button>
                                            </RestrictAccess>
                                        </div>
                                    </HDPAccordionSummary>
                                    <HDPAccordionDetails>
                                        {collection?.latestSubmission ? (
                                            <div>
                                                <div className={styles.header}>
                                                    <span
                                                        className={`${styles.heading} ${styles.submission}`}
                                                    >
                                                        Submission
                                                    </span>
                                                    <span
                                                        className={
                                                            styles.heading
                                                        }
                                                    >
                                                        Uploaded
                                                    </span>
                                                    <span
                                                        className={
                                                            styles.heading
                                                        }
                                                    >
                                                        Status
                                                    </span>
                                                </div>
                                                <List
                                                    className={
                                                        styles.submissionsList
                                                    }
                                                >
                                                    <ListItem
                                                        key={
                                                            collection
                                                                .latestSubmission
                                                                ?.Uuid
                                                        }
                                                        className={
                                                            styles.submissionItem
                                                        }
                                                    >
                                                        <SubmissionLink
                                                            submission={
                                                                collection.latestSubmission
                                                            }
                                                            isOvt
                                                        />

                                                        <span
                                                            className={
                                                                styles.detail
                                                            }
                                                        >
                                                            {DateTime.fromISO(
                                                                collection
                                                                    .latestSubmission
                                                                    .Uploaded,
                                                            ).toLocaleString(
                                                                DateTime.DATETIME_MED,
                                                            )}
                                                        </span>

                                                        <span
                                                            className={`${styles.detail} ${styles.detailStatus}`}
                                                        >
                                                            <span
                                                                className={
                                                                    styles.icon
                                                                }
                                                            >
                                                                {FILE_STATUS[
                                                                    collection
                                                                        .latestSubmission
                                                                        .Status
                                                                ]
                                                                    ? FILE_STATUS[
                                                                          collection
                                                                              .latestSubmission
                                                                              .Status
                                                                      ]
                                                                          .statusIcon
                                                                    : ''}
                                                            </span>
                                                            <span>
                                                                {FILE_STATUS[
                                                                    collection
                                                                        .latestSubmission
                                                                        .Status
                                                                ]
                                                                    ? FILE_STATUS[
                                                                          collection
                                                                              .latestSubmission
                                                                              .Status
                                                                      ].label
                                                                    : ''}
                                                            </span>
                                                        </span>
                                                    </ListItem>
                                                </List>
                                            </div>
                                        ) : (
                                            <p>
                                                No Submissions found for this
                                                collection.
                                            </p>
                                        )}
                                    </HDPAccordionDetails>
                                </HDPAccordion>
                            );
                        })}
                    </HDPAccordionGroup>
                )}
            </div>
        </React.Fragment>
    );
};

Submissions.displayName = 'Submissions';

FileList.propTypes = {
    classes: PropTypes.object,
};
