import { Chip, TableCell, TableRow } from '@mui/material';
import { formatIsoDateToUKFormat } from 'utils/format-date/format-date';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import { LogEntry } from '../../types';
import ActivityLogLink from '../ActivityLogLink/ActivityLogLink';

import styles from './activityLogRow.module.scss';

interface ActivityLogRowProps {
    kind: 'collection' | 'provider';
    logEntry: LogEntry;
    index: number;
}

const ActivityLogRow = ({ kind, logEntry, index }: ActivityLogRowProps) => {
    if (kind === 'collection') {
        return (
            <TableRow
                hover
                data-test-id={formatTestId(
                    'collection activity log',
                    `row ${index}`,
                )}
                className={styles.row}
            >
                <TableCell>{logEntry.instId}</TableCell>
                <TableCell>{logEntry.providerName}</TableCell>
                <TableCell>{logEntry.submissionUuid.slice(-9)}</TableCell>
                <TableCell>{logEntry.id}</TableCell>
                <TableCell>{logEntry.filename}</TableCell>
                <TableCell>
                    {formatIsoDateToUKFormat(logEntry.createdUtc)}
                </TableCell>
                <TableCell>
                    {logEntry.isAnonymised ? (
                        <Chip label={logEntry.username} />
                    ) : (
                        logEntry.username
                    )}
                </TableCell>
                <TableCell>{logEntry.type.name}</TableCell>
            </TableRow>
        );
    }

    if (kind === 'provider') {
        return (
            <TableRow
                hover
                data-test-id={formatTestId(
                    'provider activity log',
                    `row ${index}`,
                )}
                className={styles.row}
            >
                <TableCell>{logEntry.submissionUuid.slice(-9)}</TableCell>
                <TableCell>{logEntry.id}</TableCell>
                <TableCell>
                    <ActivityLogLink logEntry={logEntry} />
                </TableCell>
                <TableCell>
                    {formatIsoDateToUKFormat(logEntry.createdUtc)}
                </TableCell>
                <TableCell>
                    {logEntry.isAnonymised ? (
                        <Chip label={logEntry.username} />
                    ) : (
                        logEntry.username
                    )}
                </TableCell>
                <TableCell>{logEntry.type.name}</TableCell>
            </TableRow>
        );
    }
};

export default ActivityLogRow;
