import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { MoreVert } from '@mui/icons-material';
import {
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { PageTitle, RestrictAccess, usePageTitle } from 'components';
import deepEqual from 'deep-equal';
import { DateTime } from 'luxon';
import {
    actions as collectionsActions,
    collectionStates as collectionStateIds,
    selectors as collectionsSelectors,
    states as collectionsStates,
} from 'modules/collections';
import {
    actions as specificationsActions,
    selectors as specificationsSelectors,
    states as specificationsStates,
} from 'modules/specifications';
import PropTypes from 'prop-types';
import Loading from 'src/components/Loading/Loading';
import { PATHS } from 'src/constants/constants';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { CollectionState } from './CollectionState/CollectionState';
import { Specification } from './Specification/Specification';

import styles from './collections.module.scss';

const formatDate = date => DateTime.fromISO(date).toFormat('dd/LL/yyyy');

const CollectionsTableRow = ({ reference }) => {
    const history = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const {
        id: collectionId,
        name,
        referencePeriodStart,
        referencePeriodEnd,
    } = useSelector(collectionsSelectors.getCollection(reference));

    const { id: collectionStatusId } = useSelector(
        collectionsSelectors.getCollectionState(reference),
    );

    const start = formatDate(referencePeriodStart);
    const end = formatDate(referencePeriodEnd);

    const openMenu = event => {
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    const goToReprocessing = reference => {
        const route = generatePath(PATHS.BULK_PROCESSING, {
            reference,
            collectionId,
        });
        history(route);
    };

    const goToHistoricAmendment = collectionReference => {
        const route = generatePath(PATHS.HISTORIC_AMENDMENT, {
            collectionReference,
        });
        history(route);
    };

    return (
        <TableRow hover>
            <TableCell>{reference}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>
                {start} - {end}
            </TableCell>
            <TableCell>
                <Specification reference={reference} />
            </TableCell>
            <TableCell>
                <CollectionState reference={reference} />
            </TableCell>
            {collectionId ? (
                <TableCell>
                    <RestrictAccess
                        allowPermissions={[
                            'management.reprocess-for-one-provider',
                        ]}
                    >
                        <IconButton
                            className={styles.menuIcon}
                            disableRipple={true}
                            aria-label="Approve menu"
                            aria-haspopup="true"
                            onClick={openMenu}
                            size="large"
                            data-test-id={formatTestId(
                                'more actions',
                                reference,
                            )}
                        >
                            <MoreVert />
                        </IconButton>

                        <Menu
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            aria-controls="approve-menu"
                            onClose={closeMenu}
                        >
                            <MenuItem
                                onClick={() => goToReprocessing(reference)}
                                data-test-id={formatTestId(
                                    'reprocess',
                                    reference,
                                )}
                            >
                                Re-process
                            </MenuItem>

                            {collectionStatusId ===
                                collectionStateIds.HISTORIC_AMENDMENT && (
                                <MenuItem
                                    onClick={() =>
                                        goToHistoricAmendment(reference)
                                    }
                                    data-test-id={formatTestId(
                                        'historicAmendment',
                                        reference,
                                    )}
                                >
                                    Historic Amendment Resubmission
                                </MenuItem>
                            )}
                        </Menu>
                    </RestrictAccess>
                </TableCell>
            ) : (
                ''
            )}
        </TableRow>
    );
};

CollectionsTableRow.propTypes = {
    reference: PropTypes.string.isRequired,
};

const CollectionsTable = () => {
    const references = useSelector(
        collectionsSelectors.getCollectionReferences,
        deepEqual,
    );

    return (
        <Box mb={4}>
            <TableContainer component={Paper}>
                <Table
                    size={'small'}
                    data-test-id={formatTestId('table', 'collections')}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell width="10%">Reference</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Reference Period</TableCell>
                            <TableCell>Specification</TableCell>
                            <TableCell width="25%">State</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {references.map(reference => (
                            <CollectionsTableRow
                                key={reference}
                                reference={reference}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

const states = {
    UNINITIALISED: 1,
    LOADING: 2,
    LOADED: 3,
    LOADING_FAILED: 4,
};

const determineState = (collections, specifications) => {
    if (
        collections === collectionsStates.LOADING ||
        specifications === specificationsStates.fetch.LOADING
    ) {
        return states.LOADING;
    }

    if (
        collections === collectionsStates.LOADED &&
        specifications === specificationsStates.fetch.LOADED
    ) {
        return states.LOADED;
    }

    if (
        collections === collectionsStates.LOADING_FAILED ||
        specifications === specificationsStates.fetch.LOADING_FAILED
    ) {
        return states.LOADING_FAILED;
    }

    return states.UNINITIALISED;
};

const useCollections = () => {
    const dispatch = useDispatch();

    const collectionsState = useSelector(collectionsSelectors.getState);

    const specificationsState = useSelector(
        specificationsSelectors.getFetchStatus,
    );

    const loadData = useCallback(
        ({ limit, offset }) => {
            dispatch(collectionsActions.fetchCollections({ limit, offset }));
            dispatch(
                specificationsActions.fetchSpecifications({
                    state: ['available', 'in_use'],
                }),
            );
        },
        [dispatch],
    );

    return {
        state: determineState(collectionsState, specificationsState),
        loadData,
    };
};

const ErrorMessage = ({ title, children }) => (
    <Container maxWidth={'sm'}>
        <Typography variant={'h6'} align={'left'} gutterBottom>
            {title}
        </Typography>
        {children}
    </Container>
);

ErrorMessage.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export const Collections = () => {
    usePageTitle('Collections');

    const { state, loadData } = useCollections();

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
    };

    const tableId = TABLE_PARAMS_IDS.COLLECTIONS;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset } = tableParams;

    const { setLimit, setOffset } = tableParamsMethods;

    const pagingMetadata = useSelector(
        collectionsSelectors.getCollectionPagingMetadata,
    );

    useEffect(() => {
        loadData({
            limit: limit,
            offset: offset * limit,
        });
    }, [loadData, offset, limit]);

    const handleChangePage = (_event, newPage) => {
        setOffset(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setLimit(event.target.value);

        if (event.target.value > pagingMetadata.total) {
            setOffset(0);
        }
    };

    const handleTryAgain = () =>
        loadData({
            limit: limit,
            offset: limit * offset,
        });

    const renderState = () => {
        switch (state) {
            case states.LOADING:
                return <Loading />;

            case states.LOADED:
                return (
                    <>
                        <CollectionsTable />
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={pagingMetadata.total}
                            rowsPerPage={limit}
                            page={offset}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                                'data-test-id': 'previousPage',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                                'data-test-id': 'nextPage',
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                );

            case states.LOADING_FAILED:
                return (
                    <ErrorMessage title={'Loading Error'}>
                        <Typography paragraph align={'left'}>
                            The list of collections failed to load.
                        </Typography>
                        <Button
                            variant={'outlined'}
                            onClick={handleTryAgain}
                            data-test-id={formatTestId('try again')}
                        >
                            Try again
                        </Button>
                    </ErrorMessage>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <PageTitle title="Collection configuration" />
            <Typography>
                Assign a specification version to a collection and manage the
                collection state
            </Typography>
            <Box marginTop={3}>{renderState()}</Box>
        </>
    );
};
