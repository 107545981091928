import { del, get as fetchGet, patch, post, put } from '../utils';

export const applyConfiguration = async id =>
    post(`/specifications/${id}/apply-configuration`);

export const getAll = async ({ state } = {}) =>
    state
        ? fetchGet('/specifications', { query: { state } })
        : fetchGet('/specifications');

export const get = ({ id }) => fetchGet(`/specifications/${id}`);

export const create = ({ schemaId, rulesetId, versionIncrement }) =>
    post('/specifications', {
        body: {
            schemaId,
            rulesetId,
            versionIncrement,
        },
    });

export const getValidations = ({ id, filter, offset, limit }) =>
    fetchGet(`/specifications/${id}/validations`, {
        query: { filter, offset, limit },
    });

export const getDerivedFields = ({ id, filter, offset, limit }) =>
    fetchGet(`/specifications/${id}/derived-fields`, {
        query: { filter, offset, limit },
    });

export const updateState = async ({ id, stateId }) =>
    put(`/specifications/${id}/state`, {
        body: { stateId },
    });

export const deleteRequest = id => del(`/specifications/${id}`);

export const updateValidation = ({
    specId,
    validationId,
    metadata,
    tolerances,
}) =>
    patch(`/specifications/${specId}/validations/${validationId}`, {
        body: { metadata, tolerances },
    });
