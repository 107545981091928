import { createAction } from 'redux-actions';
import { call, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { permissions as permissionsApi } from 'services/api';

export const actionTypes = {
    LOAD_PERMISSIONS: 'users/LOAD_PERMISSIONS',
    SET_PERMISSIONS: 'users/SET_PERMISSIONS',
    SET_ROLES: 'users/SET_ROLES',
    LOGOUT: 'users/LOGOUT',

    CHANGE_ORGANISATION: 'users/CHANGE_ORGANISATION',
    CHANGE_STREAM: 'users/CHANGE_STREAM',
};

export const actions = {
    loadPermissions: createAction(actionTypes.LOAD_PERMISSIONS),
    setPermissions: createAction(actionTypes.SET_PERMISSIONS),
    setRoles: createAction(actionTypes.SET_ROLES),
    logout: createAction(actionTypes.LOGOUT),

    changeOrganisation: createAction(actionTypes.CHANGE_ORGANISATION),
    changeStream: createAction(actionTypes.CHANGE_STREAM),
};

const INITIAL_STATE = {
    organisations: {},
    permissionsBySection: {},
    activeOrganisationId: null,
    activeOrganisationType: null,
    activeStreamId: null,
    initialRequest: null,
};

const keyBy = (a, key) =>
    a.reduce((output, i) => ({ ...output, [i[key]]: i }), {});

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_PERMISSIONS: {
            const { initialRequest, ...permissionsBySection } = action.payload;

            return {
                ...state,
                permissionsBySection,
                initialRequest,
            };
        }

        case actionTypes.SET_ROLES: {
            const organisations = action.payload;
            const student = organisations[0]?.streams?.find(
                s => s.id === '056',
            );

            return {
                ...state,
                organisations: keyBy(organisations, 'id'),
                activeOrganisationId: organisations[0]?.id,
                activeOrganisationType: organisations[0]?.type,
                activeStreamId: student?.id,
            };
        }

        case actionTypes.CHANGE_ORGANISATION: {
            const organisation = state.organisations[action.payload.id];
            const stream = organisation.streams.find(
                stream => stream.id === state.activeStreamId,
            );

            return {
                ...state,
                activeOrganisationId: action.payload.id,
                activeOrganisationType: action.payload.type,
                activeStreamId: stream ? stream.id : organisation.streams[0].id,
            };
        }

        case actionTypes.CHANGE_STREAM:
            return {
                ...state,
                activeStreamId: action.payload.id,
            };

        default:
            return state;
    }
};

const getUser = state => state.user;
const getOrganisations = createSelector(getUser, user =>
    Object.values(user.organisations),
);
const getActiveOrganisationId = createSelector(
    getUser,
    user => user.activeOrganisationId,
);
const getActiveOrganisationType = createSelector(
    getUser,
    user => user.activeOrganisationType,
);
const getActiveOrganisation = createSelector(
    getOrganisations,
    getActiveOrganisationId,
    getActiveOrganisationType,
    (organisations, activeOrganisationId, activeOrganisationType) => {
        return (
            organisations.find(
                organisation =>
                    organisation.id === activeOrganisationId &&
                    organisation.type === activeOrganisationType,
            ) || {}
        );
    },
);
const getStreams = createSelector(getActiveOrganisation, activeOrganisation => {
    return activeOrganisation.streams || [];
});
const getActiveStreamId = createSelector(getUser, user => user.activeStreamId);
const getActiveStream = createSelector(
    getStreams,
    getActiveStreamId,
    (streams, activeStreamId) => {
        return streams.find(stream => stream.id === activeStreamId);
    },
);
const getPermissionsBySection = createSelector(
    getUser,
    user => user.permissionsBySection,
);
const getRole = createSelector(getActiveOrganisation, activeOrganisation => {
    switch (activeOrganisation.type) {
        case 'provider':
            return '1';

        case 'hesa':
            return '2';

        case 'statutory_customer':
            return '3';

        default:
            return null;
    }
});

const getRoles = createSelector(
    getActiveOrganisation,
    getActiveStreamId,
    (activeOrganisation, activeStreamId) => {
        const { roles } = activeOrganisation?.streams?.find(
            stream => stream.id === activeStreamId,
        ) || { roles: [] };

        return roles;
    },
);

export const selectors = {
    getOrganisations,
    getActiveOrganisation,
    getActiveOrganisationId,
    getActiveOrganisationType,
    getStreams,
    getActiveStream,
    getActiveStreamId,
    getPermissionsBySection,
    getRole,
    getRoles,
};

function* watchChangeOrganisation() {
    yield takeLatest(
        actionTypes.CHANGE_ORGANISATION,
        function* onChangeOrganisation({ payload }) {
            const organisations = yield select(selectors.getOrganisations);
            const organisation = organisations.find(
                organisation =>
                    organisation.id === payload.id &&
                    organisation.type === payload.type,
            );
            const activeStreamId = yield select(selectors.getActiveStreamId);
            const stream = organisation.streams.find(
                stream => stream.id === activeStreamId,
            );
            const streamId = stream ? stream.id : organisation.streams[0].id;

            return yield put(
                actions.loadPermissions({
                    organisationId: payload.id,
                    organisationType: payload.type,
                    streamId,
                }),
            );
        },
    );
}

function* watchChangeStream() {
    yield takeLatest(
        actionTypes.CHANGE_STREAM,
        function* onChangeStream({ payload }) {
            const { id: streamId } = payload;
            const organisationId = yield select(
                selectors.getActiveOrganisationId,
            );
            const organisationType = yield select(
                selectors.getActiveOrganisationType,
            );

            return yield put(
                actions.loadPermissions({
                    organisationId,
                    organisationType,
                    streamId,
                }),
            );
        },
    );
}

function* watchLoadPermissions() {
    yield takeLatest(
        actionTypes.LOAD_PERMISSIONS,
        function* onLoadPermissions({
            payload: { organisationId, organisationType, streamId },
        }) {
            const existingPermissions = yield select(
                selectors.getPermissionsBySection,
            );
            const initialRequest = !Object.keys(existingPermissions).length;
            const permissionsBySection = yield call(permissionsApi.getKeyring, {
                organisationId,
                organisationType,
                streamId,
            });
            yield put(
                actions.setPermissions({
                    initialRequest,
                    ...permissionsBySection,
                }),
            );
        },
    );
}

export const sagas = {
    setup: function* setup() {
        yield spawn(watchChangeOrganisation);
        yield spawn(watchChangeStream);
        yield spawn(watchLoadPermissions);
    },

    listen: function* () {
        yield call(sagas.setup);
    },
};
