import { get as fetchGet, put } from '../utils';

export const getAll = ({ state } = {}) =>
    fetchGet('/specifications/schemas', {
        query: { state },
    });

export const updateState = ({ id, stateId }) =>
    put(`/specifications/schemas/${id}/state`, {
        body: { stateId: parseInt(stateId, 10) },
    });
