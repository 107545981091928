import { Navigate, Outlet } from 'react-router-dom';
import { useUserContext } from 'components';
import {
    HesaRole,
    ProviderRole,
    StatutoryCustomerRole,
    User,
    UserRole,
} from 'src/types/user';

type AuthRouteProps = {
    allowAllHesa?: boolean;
    allowAllProviders?: boolean;
    allowAllStatutoryCustomers?: boolean;
    allowPrimaryRegulatorsOnly?: boolean;

    allowProviders?: ProviderRole | ProviderRole[];
    allowHesa?: HesaRole | HesaRole[];
    allowStatutoryCustomers?: StatutoryCustomerRole | StatutoryCustomerRole[];
};

const isUserWithAllowedRoles = (
    userRoles: UserRole[],
    allowedRoles?: UserRole | UserRole[],
) => {
    if (allowedRoles === undefined) return false;
    if (Array.isArray(allowedRoles)) {
        return allowedRoles
            .flat()
            .filter(Boolean)
            .some(role => userRoles.includes(role));
    } else {
        return userRoles.includes(allowedRoles);
    }
};

const AuthRoute = ({
    allowAllHesa,
    allowAllProviders,
    allowAllStatutoryCustomers,
    allowPrimaryRegulatorsOnly,

    allowProviders,
    allowHesa,
    allowStatutoryCustomers,
}: AuthRouteProps) => {
    const {
        isHesa,
        isProvider,
        isStatutoryCustomer,
        isPrimaryRegulator,
        roles,
    } = useUserContext() as User; // TODO: Remove this type casting when useUserContext is converted to TS

    const isAuthedAsHesa =
        isHesa && (allowAllHesa || isUserWithAllowedRoles(roles, allowHesa));

    const isAuthedAsProvider =
        isProvider &&
        (allowAllProviders || isUserWithAllowedRoles(roles, allowProviders));

    const isAuthedAsPrimaryRegulator =
        isPrimaryRegulator &&
        allowPrimaryRegulatorsOnly &&
        !allowAllStatutoryCustomers &&
        isUserWithAllowedRoles(roles, allowStatutoryCustomers);

    const isAuthedAsStatutoryCustomer =
        !allowPrimaryRegulatorsOnly &&
        isStatutoryCustomer &&
        (allowAllStatutoryCustomers ||
            isUserWithAllowedRoles(roles, allowStatutoryCustomers));

    const isAuthed =
        isAuthedAsHesa ||
        isAuthedAsProvider ||
        isAuthedAsPrimaryRegulator ||
        isAuthedAsStatutoryCustomer;

    return isAuthed ? <Outlet /> : <Navigate to="/" replace />;
};

export default AuthRoute;
