import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { listen } from 'services/history';

import router from './routes/index';
import { rootReducer, rootSaga } from './modules';
import theme from './theme';

import './styles/main.scss';
import './index.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
);
sagaMiddleware.run(rootSaga);
listen(store);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <IntlProvider locale={'en'}>
                        <QueryClientProvider client={queryClient}>
                            <Provider store={store}>
                                <RouterProvider router={router} />
                            </Provider>
                        </QueryClientProvider>
                    </IntlProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </StrictMode>,
);
