import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useMatches, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from 'components';
import BannerBox from 'components/BannerBox/BannerBox';
import { actions, selectors as submissionSelectors } from 'modules/submissions';
import {
    providers as providersApi,
    submissions as submissionsApi,
} from 'services/api';
import { PageTitle, PageTitleProvider } from 'src/components/index';
import { connectWebSocket } from 'src/services/api/utils';

import Stepper from '../Submission/Steps/Stepper/Stepper';
import { inferStepLabelFromRoute } from '../Submission/utils';

const steps = ['Upload', 'Processing', 'Quality Assurance'];

export const Submission = () => {
    const { submissionId, collectionId } = useParams();
    const matches = useMatches();

    const dispatch = useDispatch();
    const socketRef = useRef();
    const { activeOrganisation } = useUserContext();

    const {
        data: submissionData,
        isLoading,
        refetch: getSubmission,
    } = useQuery({
        queryKey: ['ovt-submission-data', submissionId, collectionId],
        queryFn: () => submissionsApi.getOvtSubmission({ submissionId }),
        enabled: Boolean(submissionId),
        refetchOnWindowFocus: false,
        refetchInterval: false,
    });

    // State
    const [pageTitle, setPageTitle] = useState(
        inferStepLabelFromRoute(matches, steps),
    );
    const [latestMessage, setLatestMessage] = useState();
    const [collection, setCollection] = useState({});
    const [subId, setSubId] = useState(undefined);
    const [qualityProcessingComplete, setQualityProcessingComplete] =
        useState(false);

    // selectors
    const loadingSubmission = useSelector(
        submissionSelectors.getFetchWorkingSubStatus,
    );

    const loadingStates = useSelector(
        submissionSelectors.getFetchSubStatesStatus,
    );

    const instId = submissionData?.provider?.instId || activeOrganisation.id;

    // functions
    const loadSubmissionStates = useCallback(() => {
        dispatch(actions.fetchSubmissionStates());
    }, [dispatch]);

    const resetSubmission = useCallback(() => {
        dispatch(actions.resetWorkingSubmissionState());
    }, [dispatch]);

    // handlers
    const handleReceiveMessage = useCallback(
        msg => {
            const incomingMessage = JSON.parse(msg.data);
            if (incomingMessage.submissionId === submissionId) {
                setLatestMessage(incomingMessage);
            }
        },
        [submissionId],
    );

    useEffect(() => {
        const createConnection = () => {
            socketRef.current = connectWebSocket(activeOrganisation.id);
            socketRef.current.addEventListener('message', handleReceiveMessage);
        };

        // If we've started a new upload without leaving this component
        // the submissionId will be different. So we reset the ws connection.
        if (submissionId !== subId) {
            if (socketRef.current) {
                socketRef.current.removeEventListener(
                    'message',
                    handleReceiveMessage,
                );
                socketRef.current.close();
                socketRef.current = null;
            }
            createConnection();
            setSubId(submissionId);
        }

        return () => {
            if (socketRef.current?.readyState === 1) {
                socketRef.current?.removeEventListener(
                    'message',
                    handleReceiveMessage,
                );
                socketRef.current?.close();
                socketRef.current = null;
            }
        };
    }, [submissionId, activeOrganisation.id, handleReceiveMessage, subId]);

    useEffect(() => {
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [socketRef]);

    useEffect(() => {
        if (!instId || instId === 'hesa') {
            return;
        }

        const fetchData = async () => {
            const collections = await providersApi.getCollections({
                instId,
                state: ['open', 'closed'],
            });
            const collection = collections.find(
                c => c.id === parseInt(collectionId, 10),
            );
            if (collection) {
                setCollection(collection);
            }
        };
        fetchData();
    }, [instId, collectionId, setCollection]);

    useEffect(() => {
        return () => {
            resetSubmission();
        };
    }, [submissionData?.submissionId, resetSubmission]);

    useEffect(() => {
        if (loadingStates === 1) {
            loadSubmissionStates();
        }
        if (loadingSubmission === 1) {
            getSubmission();
        } else if (
            !submissionData?.uuid &&
            latestMessage?.status === 'UPLOADED' &&
            loadingSubmission !== 2
        ) {
            getSubmission();
        } else if (submissionData && submissionData?.status && latestMessage) {
            // Keep updating the submission while the submission is uploading.
            // Stop updating after we hit 'QUALITY_PROCESSING_COMPLETE'.
            if (
                submissionData?.status.code !== latestMessage.status &&
                !qualityProcessingComplete
            ) {
                getSubmission();

                if (latestMessage.status === 'QUALITY_PROCESSING_COMPLETE') {
                    setQualityProcessingComplete(true);
                }
            }
        }
    }, [
        loadingStates,
        loadingSubmission,
        loadSubmissionStates,
        latestMessage,
        submissionData,
        qualityProcessingComplete,
        getSubmission,
    ]);

    return (
        <>
            <BannerBox
                heading="Online validation tool"
                upperText="This is the online validation tool (OVT). This tool is designed for providers to validate their files. Once your file is ready to be analysed you will need to upload it to the relevant live collection. Please note that only the latest uploaded file will be available to view."
                lowerText={
                    <span>
                        If you need support please contact{' '}
                        <a href="mailto:liaison@hesa.ac.uk">HESA Liaison.</a>
                    </span>
                }
            />

            {isLoading ? (
                <CircularProgress aria-label="Circular loading animation" />
            ) : (
                <PageTitleProvider>
                    <PageTitle title={pageTitle} />
                    <Stepper
                        submissionData={submissionData}
                        submissionId={submissionId}
                        instId={instId}
                        collectionId={collectionId}
                        steps={steps}
                        key={`ovt-stepper-${submissionId}`}
                        ovt
                    >
                        <Outlet
                            context={{
                                collectionId,
                                submissionId,
                                data: submissionData,
                                instId,
                                latestMessage,
                                collection,
                                setPageTitle,
                                getSubmission,
                            }}
                        />
                    </Stepper>
                </PageTitleProvider>
            )}
        </>
    );
};

Submission.displayName = 'Submission';
