import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, states } from 'modules/collections';

export default function useCollection({ id, reference }) {
    const dispatch = useDispatch();
    const storeState = useSelector(selectors.getState);
    const collection = useSelector(
        id
            ? selectors.getCollectionById(id)
            : selectors.getCollection(reference),
    );

    useEffect(() => {
        const collectionsUninitialised = storeState === states.UNINITIALISED;
        if (collectionsUninitialised) {
            dispatch(actions.fetchCollections());
        }
    }, [dispatch, storeState, collection]);

    return collection;
}
