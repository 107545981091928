import { post } from 'services/api/utils';

import { get, getRaw } from '../utils';
export const getProviderStatuses = ({ collectionReference, instId } = {}) =>
    get(`/submissions/provider-statuses`, {
        query: { collectionReference, instId },
    });
export const getOvtProviderStatuses = ({ collectionReference, instId } = {}) =>
    get(`/submissions/ovt/provider-statuses`, {
        query: { collectionReference, instId },
    });
export const getSchemaChecks = ({ submissionId, instId, offset, limit }) =>
    get(`/submissions/${submissionId}/schemachecks`, {
        query: { instId, offset, limit },
    });

export const getOvtSchemaChecks = ({ submissionId, instId, offset, limit }) =>
    get(`/submissions/ovt/${submissionId}/schemachecks`, {
        query: { instId, offset, limit },
    });

export const getValidations = ({
    submissionId,
    instId,
    status,
    searchTerm,
    sortBy,
    sortOrder,
    offset,
    limit,
    csv,
}) => {
    const method = csv ? getRaw : get;

    return method(`/submissions/${submissionId}/validations`, {
        query: {
            instId,
            status,
            sortBy,
            sortOrder,
            searchTerm,
            limit,
            ...(offset && { offset: offset * limit }),
        },
        ...(csv && { headers: { Accept: 'text/csv' } }),
    });
};
export const getOvtValidations = ({
    submissionId,
    instId,
    status,
    searchTerm,
    sortBy,
    sortOrder,
    offset,
    limit,
    csv,
}) => {
    const method = csv ? getRaw : get;

    return method(`/submissions/ovt/${submissionId}/validations`, {
        query: {
            instId,
            status,
            sortBy,
            sortOrder,
            searchTerm,
            limit,
            ...(offset && { offset: offset * limit }),
        },
        ...(csv && { headers: { Accept: 'text/csv' } }),
    });
};
export const getCompareValidations = ({
    submissionId,
    previousSubmissionId,
    instId,
    status,
    searchTerm,
    sortBy,
    sortOrder,
    offset,
    limit,
    hideUnchangedValues,
}) => {
    return get(
        `/submissions/${submissionId}/validations/compare/${previousSubmissionId}`,
        {
            query: {
                instId,
                status,
                sortBy,
                sortOrder,
                searchTerm,
                limit,
                hideUnchangedValues,
                ...(offset && { offset: offset * limit }),
            },
        },
    );
};

export const getValidationsCsv = args =>
    getValidations({ ...args, csv: true }).then(res => res.text());

export const getOvtValidationsCsv = args =>
    getOvtValidations({ ...args, csv: true }).then(res => res.text());

export const getOvtValidationDetails = ({
    submissionId,
    validationId,
    instId,
    regulatorCode,
    offset,
    limit,
    contentType = 'application/json',
}) => {
    const method = contentType !== 'application/json' ? getRaw : get;
    return method(
        `/submissions/ovt/${submissionId}/validations/${validationId}`,
        {
            query: {
                instId,
                limit,
                regulator: regulatorCode,
                ...(offset && { offset: offset * limit }),
            },
            ...{ headers: { Accept: contentType } },
        },
    );
};
export const getValidationDetails = ({
    submissionId,
    validationId,
    instId,
    regulatorCode,
    offset,
    limit,
    contentType = 'application/json',
}) => {
    const method = contentType !== 'application/json' ? getRaw : get;
    return method(`/submissions/${submissionId}/validations/${validationId}`, {
        query: {
            instId,
            limit,
            regulator: regulatorCode,
            ...(offset && { offset: offset * limit }),
        },
        ...{ headers: { Accept: contentType } },
    });
};
export const getValidationDetailsCsv = args =>
    getValidationDetails({ ...args, contentType: 'text/csv' }).then(res =>
        res.text(),
    );
export const getOvtValidationDetailsCsv = args =>
    getOvtValidationDetails({ ...args, contentType: 'text/csv' }).then(res =>
        res.text(),
    );
export const getValidationDetailsUri = args =>
    getValidationDetails({ ...args, contentType: 'text/uri-list' }).then(res =>
        res.text(),
    );
export const getOvtValidationDetailsUri = args =>
    getOvtValidationDetails({ ...args, contentType: 'text/uri-list' }).then(
        res => res.text(),
    );
export const getStates = async () => get('/submissions/states');

export const getSubmissionHistory = async ({ submissionId }) =>
    get(`/submissions/${submissionId}/submission-history`);

export const getSubmission = async ({ submissionId }) => {
    return get(`/submissions/${submissionId}`);
};

export const getLatestSubmission = async ({ collectionId, instId }) => {
    return get(`/submissions/latest/${collectionId}/${instId}`);
};

export const getSubmissionCommitAllowedStatus = async ({ submissionId }) => {
    return get(`/submissions/${submissionId}/commit-allowed-status`);
};

export const getSubmissions = async ({ collectionId, instId }) => {
    return get(`/submissions`, { query: { collectionId, instId } });
};
export const createNilReturn = async ({ instId, collectionId }) =>
    post(`/submissions/create-nil-submission`, {
        body: { collectionId: String(collectionId), instId: String(instId) },
    });

export const updateSubmissionState = async ({
    action,
    statusId,
    submissionId,
}) =>
    post(`/submissions/update-state/${submissionId}`, {
        body: { action, statusId },
    });

export const updateToResubmissionRequired = async ({
    instIds,
    collectionReference,
}) =>
    post('/submissions/update-to-resubmission-required-historic-amendment', {
        body: { instIds, collectionReference },
    });

export const signoff = async ({ submissionId }) =>
    post(`/submissions/${submissionId}/signoff`, {});
export const getFailureSummaryTable = ({ submissionId, instId }) =>
    get(`/submissions/${submissionId}/failure-summary-table`, {
        query: { instId },
    });

export const getFileStatistics = ({ submissionId, instId }) =>
    get(`/submissions/${submissionId}/statistics`, {
        query: { instId },
    });

export const createIssue = async ({
    validationCode,
    regulatorCode,
    submissionUuid,
}) =>
    post(`/submissions/${submissionUuid}/issues`, {
        body: { validationCode, regulatorCode },
    });

export const getOvtSubmission = async ({ submissionId }) => {
    return get(`/submissions/ovt/${submissionId}`);
};
export const getOvtSubmissions = async ({ collectionId, instId }) => {
    return get(`/submissions/ovt`, { query: { collectionId, instId } });
};
export const getOvtFailureCounts = ({ submissionId, instId }) =>
    get(`/submissions/ovt/${submissionId}/failurecounts`, {
        query: { instId },
    });
export const requestCredibility = async ({
    submissionUuid,
    instId,
    collectionId,
    fileName,
    providerName,
}) =>
    post(`/submissions/requestCredibility`, {
        body: { submissionUuid, instId, collectionId, fileName, providerName },
    });
export const requestResubmission = async ({ uuid }) =>
    post(`/submissions/${uuid}/request-resubmission/`);
