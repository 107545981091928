import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { generatePath, useNavigate } from 'react-router-dom';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import {
    OvtSubmissionSummary,
    StepSummary,
    SubmissionSummary,
    useUserContext,
} from 'components';
import CollectionsActivityLog from 'components/ActivityLog/CollectionsActivityLog/CollectionsActivityLog';
import { DateTime } from 'luxon';
import * as collections from 'modules/collections';
import {
    HDPAccordion,
    HDPAccordionDetails,
    HDPAccordionGroup,
    HDPAccordionSummary,
} from 'src/components/HDPAccordion';
import { PageTitle } from 'src/components/PageTitle/PageTitle';
import { ACCORDION_IDS, PATHS } from 'src/constants/constants';
import { useProviderSubmissions } from 'src/pages/Monitoring/helpers/useProviderSubmissions.helper';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './collection.module.scss';

// TODO: Delete this in HDP-3724 (RM 02/07/24)
const useCollection = reference => {
    const dispatch = useDispatch();

    const status = useSelector(collections.selectors.getState);
    const collection = useSelector(
        collections.selectors.getCollection(reference),
    );

    useEffect(() => {
        if (collection) {
            return;
        }

        dispatch(collections.actions.fetchCollections());
    }, [dispatch, reference, collection]);

    return {
        status: collection ? collections.states.LOADED : status,
        collection,
    };
};

const formatDate = date => DateTime.fromISO(date).toFormat('dd/LL/yyyy');

const Loading = () => {
    return (
        <Box className={styles.box}>
            <CircularProgress />
        </Box>
    );
};

export const Collection = () => {
    const history = useNavigate();
    const { isHesa } = useUserContext();
    const { reference } = useParams();
    const { status, collection } = useCollection(reference);
    const { submissions } = useProviderSubmissions({
        collectionReference: reference,
    });

    const providers = useMemo(
        () => submissions.map(s => s.provider),
        [submissions],
    );

    const goBack = () => {
        const route = generatePath(PATHS.MONITORING);
        history(route);
    };

    const CollectionSummary = ({
        name,
        reference,
        referencePeriodStart,
        referencePeriodEnd,
        state,
    }) => {
        return (
            <Table data-test-id={formatTestId('table', 'collection config')}>
                <TableHead>
                    <TableRow className={styles.header}>
                        <TableCell className={styles.bold}>Reference</TableCell>
                        <TableCell className={styles.bold}>Name</TableCell>
                        <TableCell className={styles.bold}>
                            Reference Period
                        </TableCell>
                        <TableCell className={styles.bold}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={styles.row}>
                        <TableCell key="ref">{reference}</TableCell>
                        <TableCell key="name">{name}</TableCell>
                        <TableCell key="period">
                            {formatDate(referencePeriodStart)} –{' '}
                            {formatDate(referencePeriodEnd)}
                        </TableCell>
                        <TableCell key="state">
                            <span className={styles.chip}>{state.name}</span>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    const renderContent = () => {
        switch (status) {
            case collections.states.LOADING:
                return <Loading />;

            case collections.states.LOADED:
                return (
                    <React.Fragment>
                        <HDPAccordionGroup>
                            <HDPAccordion
                                size="xs"
                                isOpenByDefault
                                searchParamId={
                                    ACCORDION_IDS.MONITORING.COLLECTION_SUMMARY
                                }
                            >
                                <HDPAccordionSummary
                                    id="collection-summary-header"
                                    aria-controls="collection-summary-panel"
                                    expandIcon={<ExpandMore />}
                                    data-test-id={formatTestId(
                                        'open accordion',
                                        'collection summary',
                                    )}
                                    header="Collection Summary"
                                />
                                <HDPAccordionDetails>
                                    <CollectionSummary {...collection} />
                                </HDPAccordionDetails>
                            </HDPAccordion>

                            <HDPAccordion
                                size="xs"
                                isOpenByDefault
                                searchParamId={
                                    ACCORDION_IDS.MONITORING.STEP_SUMMARY
                                }
                            >
                                <HDPAccordionSummary
                                    id="step-summary-header"
                                    aria-controls="step-summary-panel"
                                    expandIcon={<ExpandMore />}
                                    header="Step Summary"
                                    data-test-id={formatTestId(
                                        'open accordion',
                                        'step summary',
                                    )}
                                />
                                <HDPAccordionDetails>
                                    <StepSummary
                                        collectionReference={reference}
                                    />
                                </HDPAccordionDetails>
                            </HDPAccordion>

                            <HDPAccordion
                                size="xs"
                                searchParamId={
                                    ACCORDION_IDS.MONITORING.SUBMISSION_SUMMARY
                                }
                            >
                                <HDPAccordionSummary
                                    id="submission-summary-header"
                                    aria-controls="submission-summary-panel"
                                    data-test-id={formatTestId(
                                        'open accordion',
                                        'submission summary',
                                    )}
                                    expandIcon={<ExpandMore />}
                                    header="Submission Summary"
                                />
                                <HDPAccordionDetails>
                                    <SubmissionSummary
                                        collectionReference={reference}
                                    />
                                </HDPAccordionDetails>
                            </HDPAccordion>
                            {isHesa ? (
                                <HDPAccordion
                                    size="xs"
                                    searchParamId={
                                        ACCORDION_IDS.MONITORING
                                            .SUBMISSION_SUMMARY_OVT
                                    }
                                >
                                    <HDPAccordionSummary
                                        id="ovt-submission-summary-header"
                                        aria-controls="ovt-submission-summary-panel"
                                        data-test-id={formatTestId(
                                            'open accordion',
                                            'submission summary ovt',
                                        )}
                                        expandIcon={<ExpandMore />}
                                        header="Submission Summary OVT"
                                    />

                                    <HDPAccordionDetails>
                                        <OvtSubmissionSummary
                                            collectionReference={reference}
                                        />
                                    </HDPAccordionDetails>
                                </HDPAccordion>
                            ) : (
                                <></>
                            )}
                            {isHesa && (
                                <HDPAccordion
                                    size="xs"
                                    searchParamId={
                                        ACCORDION_IDS.MONITORING.ACTIVITY_LOG
                                    }
                                >
                                    <HDPAccordionSummary
                                        id="activity-log-header"
                                        aria-controls="activity-log-panel"
                                        data-test-id={formatTestId(
                                            'open accordion',
                                            'activity log',
                                        )}
                                        expandIcon={<ExpandMore />}
                                        header="Activity log"
                                    />

                                    <HDPAccordionDetails>
                                        <CollectionsActivityLog
                                            providers={providers}
                                        />
                                    </HDPAccordionDetails>
                                </HDPAccordion>
                            )}
                        </HDPAccordionGroup>
                        <div className={styles.buttons}>
                            <Button
                                data-test-id="back"
                                disabled={false}
                                disableRipple={true}
                                variant="contained"
                                onClick={goBack}
                            >
                                Back
                            </Button>
                        </div>
                    </React.Fragment>
                );

            default:
                return <></>;
        }
    };

    return (
        <>
            <PageTitle
                title={collection ? `${collection.name}` : `Monitoring`}
            />
            {renderContent()}
        </>
    );
};
