import { createAction } from 'redux-actions';
import { END, eventChannel } from 'redux-saga';
import { call, delay, put, retry, take, takeLatest } from 'redux-saga/effects';
import { files as filesApi } from 'services/api';

const maxUploadRetries = 3;
const uploadRetryDelay = 5000;

const maxCancelRetries = 12;
const cancelRetryDelay = 15 * 1000;

export const actionTypes = {
    RESET_UPLOAD: 'uploadFile/RESET',
    UPLOAD_FILE: 'uploadFile/INITIATE',
    FETCH_PRESIGN: 'uploadFile/FETCH',
    FETCH_PRESIGN_SUCCESS: 'uploadFile/FETCH_SUCCESS',
    FETCH_PRESIGN_FAILED: 'uploadFile/FETCH_PRESIGN_FAILED',
    UPLOAD_SUCCESS: 'uploadFile/UPLOAD_SUCCESS',
    UPLOAD_FAILED: 'uploadFile/UPLOAD_FAILED',
    CANCEL_UPLOAD: 'uploadFile/CANCEL',
    CANCEL_SUCCESS: 'uploadFile/CANCEL_SUCCESS',
    CANCEL_FAILED: 'uploadFile/CANCEL_FAILED',
    WATCH_UPLOAD: 'uploadFile/WATCH_UPLOAD',
    UPDATE_PROGRESS: 'uploadFile/UPDATE_PROGRESS',
};

export const actions = {
    resetUpload: createAction(actionTypes.RESET_UPLOAD),
    uploadFile: createAction(actionTypes.UPLOAD_FILE),
    fetchPresign: createAction(actionTypes.FETCH_PRESIGN),
    fetchPresignSuccess: createAction(actionTypes.FETCH_PRESIGN_SUCCESS),
    fetchPresignFailed: createAction(actionTypes.FETCH_PRESIGN_FAILED),
    uploadSuccess: createAction(actionTypes.UPLOAD_SUCCESS),
    uploadFailed: createAction(actionTypes.UPLOAD_FAILED),
    cancelUpload: createAction(actionTypes.CANCEL_UPLOAD),
    cancelSuccess: createAction(actionTypes.CANCEL_SUCCESS),
    cancelFailed: createAction(actionTypes.CANCEL_FAILED),
    updateProgress: createAction(actionTypes.UPDATE_PROGRESS),
    watchUpload: createAction(actionTypes.WATCH_UPLOAD),
};

export const states = {
    uploadState: {
        UNINITIALISED: 1,
        UPLOADING: 2,
        UPLOAD_SUCCESS: 3,
        UPLOAD_FAILED: 4,
    },
    fetchPresignState: {
        UNINITIALISED: 1,
        LOADING: 2,
        LOADED: 3,
        LOADING_FAILED: 4,
    },
    cancelState: {
        UNINITIALISED: 1,
        CANCEL_PENDING: 2,
        CANCEL_SUCCESS: 3,
        CANCEL_FAILED: 4,
    },
};

const INITIAL_STATE = {
    uploadState: {
        state: states.uploadState.UNINITIALISED,
    },
    fetchPresignState: {
        state: states.fetchPresignState.UNINITIALISED,
    },
    cancelState: {
        state: states.cancelState.UNINITIALISED,
    },
    progress: 0,
};

export const selectors = {
    getStatus: state => state.files.uploadState.state,
    getCancelStatus: state => state.files.cancelState.state,
    getUploadProgress: state => state.files.progress,
};

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.RESET_UPLOAD:
            return {
                ...state,
                uploadState: {
                    state: states.uploadState.UNINITIALISED,
                },
                fetchPresignState: {
                    state: states.fetchPresignState.UNINITIALISED,
                },
                cancelState: {
                    state: states.cancelState.UNINITIALISED,
                },
                progress: 0,
            };

        case actionTypes.FETCH_PRESIGN:
            return {
                ...state,
                fetchPresignState: {
                    state: states.fetchPresignState.LOADING,
                },
            };

        case actionTypes.FETCH_PRESIGN_SUCCESS:
            return {
                ...state,
                fetchPresignState: {
                    state: states.fetchPresignState.LOADED,
                },
                uploadState: {
                    state: states.uploadState.UPLOADING,
                },
            };

        case actionTypes.FETCH_PRESIGN_FAILED:
            return {
                ...state,
                fetchPresignState: {
                    state: states.fetchPresignState.LOADING_FAILED,
                },
            };

        case actionTypes.UPLOAD_SUCCESS:
            return {
                ...state,
                fetchPresignState: {
                    state: states.fetchPresignState.UNINITIALISED,
                },
                uploadState: {
                    state: states.uploadState.UPLOAD_SUCCESS,
                },
            };

        case actionTypes.UPLOAD_FAILED:
            return {
                ...state,
                fetchPresignState: {
                    state: states.fetchPresignState.UNINITIALISED,
                },
                uploadState: {
                    state: states.uploadState.UPLOAD_FAILED,
                },
            };

        case actionTypes.CANCEL_UPLOAD:
            return {
                ...state,
                fetchPresignState: {
                    state: states.fetchPresignState.UNINITIALISED,
                },
                uploadState: {
                    state: states.uploadState.UPLOADING,
                },
                cancelState: {
                    state: states.cancelState.CANCEL_PENDING,
                },
            };

        case actionTypes.CANCEL_SUCCESS:
            return {
                ...state,
                fetchPresignState: {
                    state: states.fetchPresignState.UNINITIALISED,
                },
                uploadState: {
                    state: states.uploadState.UPLOAD_FAILED,
                },
                cancelState: {
                    state: states.cancelState.CANCEL_SUCCESS,
                },
            };

        case actionTypes.CANCEL_FAILED:
            return {
                ...state,
                fetchPresignState: {
                    state: states.fetchPresignState.UNINITIALISED,
                },
                uploadState: {
                    state: states.uploadState.UPLOADING,
                },
                cancelState: {
                    state: states.cancelState.CANCEL_FAILED,
                },
            };

        case actionTypes.UPDATE_PROGRESS:
            return {
                ...state,
                fetchPresignState: {
                    state: states.fetchPresignState.UNINITIALISED,
                },
                uploadState: {
                    state: states.uploadState.UPLOADING,
                },
                progress: action.payload.progress,
            };

        default:
            return state;
    }
};

const channels = {
    receiveProgress: xhr => {
        return eventChannel(emitter => {
            const captureProgressEvent = event => emitter(event);
            xhr.upload.addEventListener('progress', captureProgressEvent);
            return () => {
                emitter(END);
                xhr.upload.removeEventListener(
                    'progress',
                    captureProgressEvent,
                );
            };
        });
    },
};

function* checkConnectivity() {
    while (true) {
        try {
            const networkStatus = navigator.onLine;
            if (!networkStatus) {
                throw new Error('Network offline');
            } else {
                return networkStatus;
            }
        } catch (error) {
            yield delay(2000);
        }
    }
}

export const sagas = {
    onUploadFile: function* onUploadFile({ payload = {} }) {
        const { submissionId, file, collectionId, instId, isOvt } = payload;
        const fetchPresignedUrlEndpoint = isOvt
            ? filesApi.ovtUploadRequest
            : filesApi.uploadRequest;

        try {
            yield put(actions.fetchPresign());
            const response = yield retry(
                maxUploadRetries,
                uploadRetryDelay,
                fetchPresignedUrlEndpoint,
                {
                    submissionUuid: submissionId,
                    fileName: file.name,
                    collectionId,
                    instId,
                },
            );

            yield put(
                actions.fetchPresignSuccess({
                    ...payload,
                    presignedPostData: response.data,
                }),
            );
        } catch (error) {
            yield put(actions.fetchPresignFailed(payload));
        }
    },
    onFetchPresignSuccess: function* onFetchPresignSuccess({ payload = {} }) {
        const { file, xhr, presignedPostData } = payload;

        yield put(actions.watchUpload({ xhr }));

        try {
            yield call(filesApi.uploadFile, {
                presignedPostData,
                file,
                xhr,
            });
            yield put(actions.uploadSuccess());
        } catch (error) {
            yield put(actions.uploadFailed());
        }
    },
    onFetchPresignFailed: function* onFetchPresignFailed({ payload = {} }) {
        yield call(checkConnectivity);
        yield put(
            actions.cancelUpload({
                ...payload,
                submissionUuid: payload.submissionId,
                fileName: payload.file.name,
            }),
        );
    },
    onWatchUpload: function* onWatchUpload({ payload = {} }) {
        const { xhr } = payload;
        const channel = yield call(channels.receiveProgress, xhr);
        while (true) {
            const progressEvent = yield take(channel);
            if (progressEvent.lengthComputable) {
                const progress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100,
                );
                yield put(actions.updateProgress({ progress }));
            }
        }
    },
    onCancelUpload: function* onCancelUpload({ payload = {} }) {
        const { submissionUuid, collectionId, instId, fileName, isOvt } =
            payload;

        const cancelUploadEndpoint = isOvt
            ? filesApi.cancelOvtUpload
            : filesApi.cancelUpload;

        try {
            yield retry(
                maxCancelRetries,
                cancelRetryDelay,
                cancelUploadEndpoint,
                {
                    submissionUuid,
                    collectionId,
                    instId,
                    fileName,
                },
            );
            yield put(actions.cancelSuccess());
        } catch (e) {
            yield put(actions.cancelFailed());
        }
    },

    listen: function* listen() {
        yield takeLatest(actionTypes.UPLOAD_FILE, sagas.onUploadFile);
        yield takeLatest(actionTypes.WATCH_UPLOAD, sagas.onWatchUpload);
        yield takeLatest(actionTypes.CANCEL_UPLOAD, sagas.onCancelUpload);
        yield takeLatest(
            actionTypes.FETCH_PRESIGN_SUCCESS,
            sagas.onFetchPresignSuccess,
        );
        yield takeLatest(
            actionTypes.FETCH_PRESIGN_FAILED,
            sagas.onFetchPresignFailed,
        );
    },
};
