import { get as fetchGet } from '../utils';

const pipe =
    (...fns) =>
    x =>
        fns.reduce((v, f) => f(v), x);

const addStateName = name => ruleset => ({
    ...ruleset,
    state: { ...ruleset.state, name },
});

const addAuditInfo = ruleset => ({
    ...ruleset,
    createdBy: 'HESA user',
    createdUtc: '2020-08-04T12:00:00Z',
});

const stubRuleset = pipe(addStateName('Available'), addAuditInfo);

const stubRulesets = ({ rulesets }) => ({
    rulesets: rulesets.map(stubRuleset),
});

export const getAll = ({ schemaId, state } = {}) =>
    fetchGet('/specifications/rulesets', {
        query: { schemaId, state },
    }).then(stubRulesets);
