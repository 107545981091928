import React, { useState } from 'react';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { MoreVert, Remove } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import {
    Button,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuItem,
} from '@mui/material';
import { RestrictAccess } from 'components';
import { useUserContext } from 'src/components';
import {
    HDPAccordion,
    HDPAccordionDetails,
    HDPAccordionGroup,
    HDPAccordionSummary,
} from 'src/components/HDPAccordion';
import { ACCORDION_IDS, PATHS } from 'src/constants/constants';
import { FILE_STATUS, fileStatusByKey } from 'src/constants/FileStatus';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { v4 as uuid } from 'uuid';

import { useCollections, useCollectionSubmissions } from '../hooks';

import {
    collectionAllowsSubmissions,
    collectionContainsNilReturnSubmission,
    collectionContainsSubmissions,
    collectionIsLocked,
    collectionIsOpenOrHistoricAmendment,
    formatDate,
    generateUploadText,
    getSubmissionLinkForStep,
} from './utils';

import styles from './submissions.module.scss';

export const SubmissionLink = ({ submission, index, isOvt = false }) => {
    const stateHistory = submission.StateHistory.map(fileStatusByKey);
    const isHistoricResubmission = stateHistory.includes(
        FILE_STATUS.RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT,
    );
    const isNilReturn = stateHistory.includes(FILE_STATUS.NIL_RETURN);

    if (isNilReturn) {
        return (
            <p className={styles.name}>
                {isHistoricResubmission
                    ? 'Resubmission required - historic amendment (Nil return)'
                    : 'Nil return'}
            </p>
        );
    }

    const { path } = getSubmissionLinkForStep({
        submissionId: submission.Uuid,
        collectionId: submission.CollectionId,
        status: submission.Status,
        isOvt,
    });

    return (
        <Link
            className={styles.link}
            data-test-id={formatTestId('linkTo', `submission ${index + 1}`)}
            to={path}
        >
            {submission.FileName}
        </Link>
    );
};

const SubmissionsList = ({ collection, instId }) => {
    const {
        data: submissions,
        isLoading,
        isError,
    } = useCollectionSubmissions({ collectionId: collection.id, instId });

    if (isError) {
        return <p>Error getting submissions</p>;
    }

    if (isLoading) {
        return <CircularProgress aria-label="Circular loading animation" />;
    }

    if (submissions?.length === 0) {
        return <p>No Submissions found for this collection</p>;
    }

    return (
        <div>
            <div className={styles.header}>
                <span className={`${styles.heading} ${styles.submission}`}>
                    Submission
                </span>
                <span className={`${styles.heading} ${styles.uploaded}`}>
                    Uploaded
                </span>
                <span className={`${styles.heading} ${styles.status}`}>
                    Status
                </span>
            </div>
            <List className={styles.submissionsList}>
                {Array.isArray(submissions) &&
                    submissions.map((submission, index) => (
                        <ListItem
                            key={submission.Uuid}
                            className={styles.submissionItem}
                        >
                            <SubmissionLink
                                submission={submission}
                                index={index}
                            />
                            <span className={styles.uploaded}>
                                {generateUploadText(submission, submissions)}
                            </span>
                            <span
                                className={`${styles.detail} ${styles.detailStatus}`}
                                data-test-id={formatTestId(
                                    'submission status',
                                    `submission ${submission.SubmissionId}`,
                                )}
                            >
                                <span className={styles.icon}>
                                    {FILE_STATUS[submission.Status]
                                        ? FILE_STATUS[submission.Status]
                                              .statusIcon
                                        : ''}
                                </span>
                                <span>
                                    {FILE_STATUS[submission.Status]
                                        ? FILE_STATUS[submission.Status].label
                                        : ''}
                                </span>
                            </span>
                        </ListItem>
                    ))}
            </List>
        </div>
    );
};

const Icon = ({ collection }) => {
    if (FILE_STATUS[collection.collectionStatus]) {
        return FILE_STATUS[collection.collectionStatus].statusIcon;
    } else if (collectionContainsNilReturnSubmission(collection)) {
        return <span>Nil return</span>;
    } else {
        return <Remove />;
    }
};

const SubmissionAccordionHeader = ({ collection }) => {
    const from = formatDate(collection.referencePeriodStart);
    const to = formatDate(collection.referencePeriodEnd);

    return (
        <>
            <span className={styles.accordionName}>{collection.name}</span>
            <span className={styles.accordionDate}>{`${from} - ${to}`}</span>
            <span className={styles.accordionSubmissions}>
                {collection.collectionSubmissionCount}
            </span>
            <span className={styles.accordionStatus}>
                <span className={styles.icon}>
                    <Icon collection={collection} />
                </span>
                <span>
                    {FILE_STATUS[collection.collectionStatus]
                        ? FILE_STATUS[collection.collectionStatus].label
                        : ''}
                </span>
            </span>
        </>
    );
};

const SubmissionAccordionActions = ({ collection, openMenu }) => {
    const navigate = useNavigate();

    const newSubmissionRoute = generatePath(PATHS.UPLOAD, {
        collectionId: collection.id,
        submissionId: uuid().toUpperCase(),
    });

    return (
        <div className={styles.accordionSummary}>
            <div className={styles.actions}>
                <RestrictAccess
                    allowPermissions={['collections.upload-submission-file']}
                >
                    <Button
                        className={`hdp-override ${styles.uploadFile}`}
                        data-test-id={formatTestId(
                            'uploadFile',
                            collection?.reference,
                        )}
                        disabled={
                            !collectionAllowsSubmissions(collection) ||
                            collectionIsLocked(collection)
                        }
                        disableRipple={true}
                        variant="contained"
                        onClick={() => navigate(newSubmissionRoute)}
                    >
                        Upload File
                    </Button>
                </RestrictAccess>
                <RestrictAccess
                    allowPermissions={[
                        'collections.collection-activity-log-provider-view',
                    ]}
                >
                    <IconButton
                        className={styles.menuButton}
                        id={`log-menu-button-${collection.id}`}
                        disableRipple={true}
                        aria-haspopup="true"
                        aria-label="More actions"
                        data-test-id={formatTestId(
                            'moreActions',
                            collection?.reference,
                        )}
                        onClick={e => openMenu(e, collection)}
                        disabled={
                            !collectionContainsSubmissions(collection) &&
                            collectionIsLocked(collection)
                        }
                        size="large"
                    >
                        <MoreVert />
                    </IconButton>
                </RestrictAccess>
            </div>
        </div>
    );
};

export const Submissions = () => {
    const history = useNavigate();
    const [activityLogLink, setActivityLogLink] = useState('');
    const { activeOrganisation } = useUserContext();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const {
        data: collections,
        isLoading: collectionsLoading,
        isError: collectionsError,
    } = useCollections({
        instId: activeOrganisation.id,
        state: ['open', 'closed', 'historic amendment'],
    });

    const openMenu = (event, collection) => {
        event.stopPropagation();

        const activityLogLink = generatePath(PATHS.PROVIDER_ACTIVITY_LOG, {
            collectionReference: collection.reference,
        });

        setMenuAnchorEl(event.currentTarget);
        setActivityLogLink(activityLogLink);
    };

    const closeMenu = () => {
        setActivityLogLink(null);
        setMenuAnchorEl(null);
    };

    return (
        <>
            <h1 className="heading--h1 margin--bottom-1" id="heading">
                Manage Submissions
            </h1>
            <div className={styles.accordionheader}>
                <span className={styles.accordionHeaderName}>Name</span>
                <span className={styles.accordionHeaderHeading}>
                    Date range
                </span>
                <span className={styles.accordionHeaderHeading}>
                    Submissions
                </span>
                <span className={styles.accordionHeaderHeading}>&nbsp;</span>
                <span className={styles.accordionHeaderHeading}>&nbsp;</span>
            </div>
            <Menu
                id="log-menu"
                classes={{
                    paper: 'hdp-override three-dot-menu',
                }}
                anchorEl={menuAnchorEl}
                disableRipple={true}
                open={Boolean(menuAnchorEl)}
                getContentAnchorEl={null}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => history(activityLogLink)}>
                    Activity log
                </MenuItem>
            </Menu>
            {collectionsLoading && (
                <CircularProgress aria-label="Circular loading animation" />
            )}
            {collectionsError && <p>Error getting collections</p>}
            {!collectionsError && !collectionsLoading && (
                <HDPAccordionGroup>
                    {collections.map(collection => (
                        <HDPAccordion
                            disabled={
                                !collectionIsOpenOrHistoricAmendment(collection)
                            }
                            data-test-id={formatTestId(
                                'openAccordion',
                                collection?.reference,
                            )}
                            key={`submissions-${collection.reference}`}
                            searchParamId={
                                ACCORDION_IDS.MANAGE_SUBMISSIONS.COLLECTION +
                                collection.reference
                            }
                        >
                            <HDPAccordionSummary
                                expandIcon={
                                    !collectionIsOpenOrHistoricAmendment(
                                        collection,
                                    ) ? (
                                        <LockIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )
                                }
                                header={
                                    <SubmissionAccordionHeader
                                        collection={collection}
                                    />
                                }
                                size="md"
                                fontWeight="normal"
                                data-test-id={formatTestId(
                                    'open accordion',
                                    collection?.id,
                                )}
                            >
                                <SubmissionAccordionActions
                                    collection={collection}
                                    openMenu={openMenu}
                                />
                            </HDPAccordionSummary>
                            <HDPAccordionDetails>
                                <SubmissionsList
                                    collection={collection}
                                    instId={activeOrganisation.id}
                                />
                            </HDPAccordionDetails>
                        </HDPAccordion>
                    ))}
                </HDPAccordionGroup>
            )}
        </>
    );
};

Submissions.displayName = 'Submissions';
