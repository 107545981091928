import { useMemo } from 'react';
import { Box } from '@mui/material';
import { useUserContext } from 'components';
import CollectionsActivityLog from 'components/ActivityLog/CollectionsActivityLog/CollectionsActivityLog';
import Loading from 'components/Loading/Loading';
import TabPanel from 'components/Tabs/TabPanel';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';

import { uiStates } from '../queries';

import { Filters, ProviderFilter } from './Filters';
import { useProviderSubmissions } from './hooks';
import { SubmissionSummaryTable } from '.';

export const SubmissionSummary = ({ collectionReference }) => {
    const { isHesa } = useUserContext();

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
        selectedProvider: {},
        filters: {},
        sortBy: 'last-submission',
        sortOrder: 'desc',
    };

    const tableId = TABLE_PARAMS_IDS.SUBMISSIONS_SUMMARY;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset, selectedProvider, filters, sortBy, sortOrder } =
        tableParams;

    const {
        setLimit,
        setOffset,
        setSelectedProvider,
        setFilters,
        setSortBy,
        setSortOrder,
    } = tableParamsMethods;

    const { status, submissions, filteredSubmissions, submissionStates } =
        useProviderSubmissions({
            collectionReference,
            filters,
            sortBy,
            sortOrder,
        });

    const handleFiltersChange = newFilter => {
        setFilters({ ...filters, ...newFilter });
    };

    const handleSortChange = id => {
        if (sortBy === id) {
            // If already sorting by the same column, flip the order
            const flippedSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
            setSortOrder(flippedSortOrder);
        } else {
            // If sorting by a different column, set the order to the default
            setSortOrder(DEFAULT_TABLE_PARAMETERS.sortOrder);
            setSortBy(id);
        }
    };
    const handleProviderChange = provider => {
        setSelectedProvider(provider);
    };
    const providers = useMemo(
        () => submissions.map(s => s.provider),
        [submissions],
    );

    const renderContent = () => {
        switch (status) {
            case uiStates.LOADING:
                return <Loading />;

            case uiStates.LOADED:
                return (
                    <>
                        <TabPanel value={0} index={0}>
                            <Filters
                                filters={filters}
                                submissions={submissions}
                                submissionStates={submissionStates}
                                onChange={handleFiltersChange}
                            />
                            <SubmissionSummaryTable
                                submissions={filteredSubmissions}
                                order={sortOrder}
                                orderBy={sortBy}
                                onSort={handleSortChange}
                                limit={limit}
                                setLimit={setLimit}
                                offset={offset}
                                setOffset={setOffset}
                            />
                        </TabPanel>
                        {isHesa && (
                            <TabPanel value={0} index={2}>
                                <Box px={2} pb={3}>
                                    <ProviderFilter
                                        value={selectedProvider.institutionId}
                                        providers={providers}
                                        onChange={handleProviderChange}
                                    />
                                </Box>
                                <CollectionsActivityLog
                                    providers={providers}
                                    selectedProvider={selectedProvider}
                                />
                            </TabPanel>
                        )}
                    </>
                );

            default:
                return null;
        }
    };

    return <>{renderContent()}</>;
};

SubmissionSummary.displayName = 'SubmissionSummary';
