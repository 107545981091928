import { post } from '../utils';

export const getKeyring = ({ organisationId, organisationType, streamId }) =>
    post(`/permissions/getKeyring`, {
        body: {
            organisationId,
            organisationType,
            streamId,
        },
    }).then(res => res);
