import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { providers as providersApi } from 'services/api';

export const actionTypes = {
    FETCH_PROVIDERS: 'providers/FETCH',
    FETCH_PROVIDERS_SUCCESS: 'providers/FETCH_SUCCESS',
    FETCH_PROVIDERS_FAILED: 'providers/FETCH_FAILED',
    ONBOARD_PROVIDER: 'providers/ONBOARD',
    ONBOARD_PROVIDER_SUCCESS: 'providers/ONBOARD_PROVIDER_SUCCESS',
    ONBOARD_PROVIDER_FAILED: 'providers/ONBOARD_PROVIDER_FAILED',
};

export const actions = {
    fetch: createAction(actionTypes.FETCH_PROVIDERS),
    fetchSuccess: createAction(actionTypes.FETCH_PROVIDERS_SUCCESS),
    fetchFailed: createAction(actionTypes.FETCH_PROVIDERS_FAILED),
    onboard: createAction(actionTypes.ONBOARD_PROVIDER),
    onboardSuccess: createAction(actionTypes.ONBOARD_PROVIDER_SUCCESS),
    onboardFailed: createAction(actionTypes.ONBOARD_PROVIDER_FAILED),
};

export const states = {
    fetch: {
        UNINITIALISED: 1,
        LOADING: 2,
        LOADED: 3,
        LOADING_FAILED: 4,
    },
    onboard: {
        UNINITIALISED: 0,
        ONBORDING: 1,
        ONBOARDED: 2,
        ONBOARD_FAILED: 3,
    },
};

const INITIAL_STATE = {
    state: {
        fetch: states.fetch.UNINITIALISED,
    },
    providers: {},
};

const keyBy = (a, key) =>
    a.reduce((output, i) => ({ ...output, [i[key]]: i }), {});

const mapped = providers =>
    providers.map(provider => ({ ...provider, name: provider.publishedName }));

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROVIDERS:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING,
                },
            };

        case actionTypes.FETCH_PROVIDERS_SUCCESS:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADED,
                },
                providers: keyBy(
                    mapped(action.payload.providers),
                    'institutionId',
                ),
            };

        case actionTypes.FETCH_PROVIDERS_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING_FAILED,
                },
            };

        case actionTypes.ONBOARD_PROVIDER:
            return {
                ...state,
                state: {
                    ...state.state,
                    onboard: states.onboard.ONBORDING,
                },
                providers: {
                    ...state.providers,
                    [action.payload.instId]: {
                        ...state.providers[action.payload.instId],
                        onboarded: true,
                    },
                },
            };

        case actionTypes.ONBOARD_PROVIDER_SUCCESS:
            return {
                ...state,
                state: {
                    ...state.state,
                    onboard: states.onboard.ONBOARDED,
                },
            };

        case actionTypes.ONBOARD_PROVIDER_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    onboard: states.onboard.ONBOARD_FAILED,
                },
            };

        default:
            return state;
    }
};

const getProviders = state => state.providers;

const findProvider = institutionId => state => {
    return state.providers.providers[institutionId];
};

const getProviderByInst = instId => {
    return createSelector(findProvider(instId), id => id);
};

export const selectors = {
    getFetchStatus: createSelector(
        getProviders,
        providers => providers.state.fetch,
    ),
    getProviders: createSelector(getProviders, providers =>
        Object.values(providers.providers),
    ),
    getOnboardStatus: createSelector(
        getProviders,
        providers => providers.state.onboard,
    ),

    getProviderByInst,
};

export const sagas = {
    onFetchProviders: function* onFetchProviders() {
        try {
            const response = yield call(providersApi.getAll);
            yield put(actions.fetchSuccess(response));
        } catch (error) {
            yield put(actions.fetchFailed(error));
        }
    },

    onOnboardProvider: function* onOnboardProvider({ payload }) {
        const { instId } = payload || {};
        try {
            yield call(providersApi.onboard, { instId });
            yield put(actions.onboardSuccess(instId));
        } catch (error) {
            yield put(actions.onboardFailed(error));
        }
    },

    listen: function* listen() {
        yield takeLatest(actionTypes.FETCH_PROVIDERS, sagas.onFetchProviders);
        yield takeLatest(actionTypes.ONBOARD_PROVIDER, sagas.onOnboardProvider);
    },
};
