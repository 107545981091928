import { stringify } from 'query-string';
import { token } from 'services/storage';
import { API_URL, WS_URL } from 'src/constants/constants';

class UnauthorisedError extends Error {
    constructor(response) {
        super();
        this.name = 'UnauthorisedError';
        this.response = response;
    }
}

const handleResponse = res => {
    if (!res.ok) {
        if (res.status === 401) {
            throw new UnauthorisedError(res);
        }
        throw new Error(`${res.status}: ${res.statusText}`);
    }

    return res;
};

const empty = o => Object.keys(o).length === 0;
const querystring = (o = {}) =>
    empty(o) ? '' : `?${stringify(o, { skipEmptyString: true })}`;

const getToken = () => token.get();

export const getRaw = (path, { query, headers = {} } = {}) =>
    fetch(`${API_URL}${path}${querystring(query)}`, {
        headers: { ...headers, Authorization: `Bearer ${getToken()}` },
    }).then(handleResponse);

export const get = (...params) => getRaw(...params).then(res => res.json());

export const put = (path, { body } = {}) =>
    fetch(`${API_URL}${path}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleResponse)
        .then(res => res.json());

export const post = (path, { body, headers } = {}) =>
    fetch(`${API_URL}${path}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(body),
    })
        .then(handleResponse)
        .then(res => {
            return res.status === 204 || res.status === 202 ? null : res.json();
        });

export const postFile = (path, { body } = {}) =>
    fetch(path, {
        method: 'POST',
        body,
    })
        .then(handleResponse)
        .then(res => {
            return res.status === 204 ? null : res.json();
        });

export const del = async (path, { query } = {}) => {
    await fetch(`${API_URL}${path}${querystring(query)}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${getToken()}` },
    }).then(handleResponse);
};

export const delWithBody = (path, { body } = {}) => {
    fetch(`${API_URL}${path}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${getToken()}` },
        body: JSON.stringify(body),
    }).then(handleResponse);
};

export const patch = (path, { body, headers } = {}) =>
    fetch(`${API_URL}${path}`, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(body),
    })
        .then(handleResponse)
        .then(res => {
            return res.status === 204 ? null : res.json();
        });

export const getFilenameFromContentHeader = header => {
    let filename = '';
    if (header && header.indexOf('attachment') !== -1) {
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = regex.exec(header);
        if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }

    return filename;
};

export const connectWebSocket = instId => {
    // Helper function for connecting to the websocket in websockets/handlers.js
    const token = getToken();
    if (!token) {
        throw new Error('No token provided');
    }
    const ws = new WebSocket(`${WS_URL}?instId=${instId}&token=${token}`);
    ws.onopen = () => {
        console.log('WebSocket is connected');
    };
    ws.onmessage = msg => {
        console.log('Received:', msg);
    };
    ws.onclose = () => {
        console.log('WebSocket is closed');
    };
    ws.onerror = error => {
        console.error('WebSocket encountered an error:', error);
    };
    return ws;
};
