import React, { useCallback, useMemo } from 'react';
import { useUserContext } from 'components';

import {
    AwaitingActionFilter,
    ProviderFilter,
    RegulatorFilter,
    StateFilter,
} from '.';

import styles from './filters.module.scss';

export const Filters = React.memo(
    ({ filters, submissions, submissionStates, onChange, isOvt }) => {
        const { isHesa } = useUserContext();
        const handleProviderChange = useCallback(
            ({ institutionId }) => {
                onChange({ institutionId });
            },
            [onChange],
        );
        const handleRegulatorChange = useCallback(
            ({ regulatorCode }) => {
                onChange({ regulatorCode });
            },
            [onChange],
        );
        const handleStateChange = useCallback(
            ({ code: state }) => {
                onChange({ state });
            },
            [onChange],
        );

        const handleAwaitingActionChange = useCallback(
            ({ id }) => {
                onChange({ awaitingAction: id });
            },
            [onChange],
        );

        const providers = useMemo(
            () => submissions.map(s => s.provider),
            [submissions],
        );

        const actions = useMemo(
            () =>
                Object.values(
                    submissionStates.reduce((output, { awaitingParty }) => {
                        return {
                            ...output,
                            ...(awaitingParty
                                ? { [awaitingParty.id]: awaitingParty }
                                : {}),
                        };
                    }, {}),
                ),
            [submissionStates],
        );

        const availableStateIds = useMemo(
            () =>
                Array.from(
                    new Set(
                        submissions.map(
                            submission =>
                                submission?.latestSubmission?.status?.id || 0,
                        ),
                    ),
                ),
            [submissions],
        );

        return (
            <div className={styles.filters}>
                <ProviderFilter
                    value={filters.institutionId}
                    providers={providers}
                    onChange={handleProviderChange}
                />
                <StateFilter
                    value={filters.state}
                    availableStateIds={availableStateIds}
                    onChange={handleStateChange}
                />
                {isHesa && (
                    <RegulatorFilter
                        value={filters.regulatorCode}
                        onChange={handleRegulatorChange}
                    />
                )}
                {isOvt ? null : (
                    <AwaitingActionFilter
                        value={filters.awaitingAction}
                        actions={actions}
                        onChange={handleAwaitingActionChange}
                    />
                )}
            </div>
        );
    },
);
