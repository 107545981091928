import { FILE_STATUS, fileStatusByKey } from 'src/constants/FileStatus';

export const statuses = {
    Queueing: [FILE_STATUS.UPLOADING, FILE_STATUS.UPLOADED],
    Processing: [
        FILE_STATUS.PROVISIONING,
        FILE_STATUS.PROVISIONED,
        FILE_STATUS.SCHEMA_CHECK,
        FILE_STATUS.SCHEMA_CHECK_PASSED,
        FILE_STATUS.RIGHT_TO_PROCESS,
        FILE_STATUS.RIGHT_TO_PROCESS_PASSED,
        FILE_STATUS.ENRICHMENT,
        FILE_STATUS.ENRICHMENT_PASSED,
        FILE_STATUS.QUALITY_RULES,
        FILE_STATUS.QUALITY_RULES_PASSED,
        FILE_STATUS.CREDIBILITY,
        FILE_STATUS.CREDIBILITY_PASSED,
        FILE_STATUS.COLLECTION_REPORTS,
    ],
    Failed: [
        FILE_STATUS.UPLOAD_ERROR,
        FILE_STATUS.PROVISIONING_ERROR,
        FILE_STATUS.SCHEMA_CHECK_FAILED,
        FILE_STATUS.SCHEMA_CHECK_ERROR,
        FILE_STATUS.RIGHT_TO_PROCESS_FAILED,
        FILE_STATUS.RIGHT_TO_PROCESS_ERROR,
        FILE_STATUS.ENRICHMENT_ERROR,
        FILE_STATUS.SAVING_ENTITIES_ERROR,
        FILE_STATUS.QUALITY_RULES_ERROR,
        FILE_STATUS.CREDIBILITY_ERROR,
        FILE_STATUS.COLLECTION_REPORTS_ERROR,
    ],
    Reprocessed: [
        FILE_STATUS.COLLECTION_REPORTS_PASSED,
        FILE_STATUS.QUALITY_PROCESSING_COMPLETE,
        FILE_STATUS.HESA_ANALYST_APPROVAL,
        FILE_STATUS.HESA_APPROVAL,
        FILE_STATUS.SC_APPROVAL,
        FILE_STATUS.APPROVED,
        FILE_STATUS.PROVIDER_SIGNOFF,
        FILE_STATUS.SIGNED_OFF,
        FILE_STATUS.NIL_RETURN,
        FILE_STATUS.HISTORY_DATA,
        FILE_STATUS.HISTORY_DATA_ERROR,
        FILE_STATUS.HISTORY_DATA_PASSED,
        FILE_STATUS.RESUBMISSION_REQUIRED,
        FILE_STATUS.RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT,
    ],
};

export const getBurpStateByKey = key => {
    const fileStatus = fileStatusByKey(key);

    for (const burpState of Object.keys(statuses)) {
        if (statuses[burpState].includes(fileStatus)) return burpState;
    }
};
