import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { AuthCallback } from 'components';
import { actions as authActions } from 'modules/auth';

import appRoutes from './app.routes';
import deliveryRoutes from './delivery.routes';
import irisRoutes from './iris.routes';
import managementRoutes from './management.routes';
import monitoringRoutes from './monitoring.routes';
import ovtRoutes from './ovt.routes';
import redirects from './redirects';
import submissionRoutes from './submission.routes';

const routes: RouteObject[] = [
    {
        path: '/',
        async lazy() {
            const App = (await import('pages/App/App')).default;
            return { Component: App };
        },
        children: [
            ...appRoutes,
            ...monitoringRoutes,
            ...managementRoutes,
            ...submissionRoutes,
            ...deliveryRoutes,
            ...irisRoutes,
            ...ovtRoutes,
        ],
    },
    {
        path: '*',
        async lazy() {
            const { PageNotFound } = await import(
                'pages/PageNotFound/PageNotFound'
            );
            return { Component: PageNotFound };
        },
    },
    {
        path: '/callback/signin',
        element: <AuthCallback action={authActions.signInCallback} />,
    },
    {
        path: '/callback/signout',
        element: <AuthCallback action={authActions.signOutCallback} />,
    },
    ...redirects,
];

const router = createBrowserRouter(routes);

export default router;
