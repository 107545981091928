import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { usePageTitle } from 'components';
import { PageTitle } from 'components';
import deepEqual from 'deep-equal';
import { DateTime } from 'luxon';
import {
    actions as collectionsActions,
    collectionStates,
    selectors as collectionsSelectors,
    states as collectionsStates,
} from 'modules/collections';
import PropTypes from 'prop-types';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './collections.module.scss';

const Loading = () => {
    return (
        <Box className={styles.box}>
            <CircularProgress aria-label="Circular loading animation" />
        </Box>
    );
};

const formatDate = date => DateTime.fromISO(date).toFormat('dd/LL/yyyy');

const CollectionsTableRow = ({ reference }) => {
    const {
        id: collectionId,
        name,
        referencePeriodStart,
        referencePeriodEnd,
    } = useSelector(collectionsSelectors.getCollection(reference));

    const { id } = useSelector(
        collectionsSelectors.getCollectionState(reference),
    );

    const start = formatDate(referencePeriodStart);
    const end = formatDate(referencePeriodEnd);

    return (
        <TableRow hover className={styles.collectionItem}>
            <TableCell className={styles.collectionReference}>
                {reference}
            </TableCell>
            <TableCell className={styles.collectionName}>
                {collectionId ? (
                    <Link
                        className={styles.link}
                        to={{
                            pathname: `/monitoring/${reference}`,
                            state: { name, reference },
                        }}
                        data-test-id={formatTestId('link to', reference)}
                    >
                        {name}
                    </Link>
                ) : (
                    name
                )}
            </TableCell>
            <TableCell className={styles.collectionReference}>
                {start} - {end}
            </TableCell>
            <TableCell>
                <CollectionStatus id={id} />
            </TableCell>
        </TableRow>
    );
};

CollectionsTableRow.propTypes = {
    reference: PropTypes.string.isRequired,
};

const CollectionsTable = () => {
    const references = useSelector(
        collectionsSelectors.getCollectionReferences,
        deepEqual,
    );

    return (
        <Box mb={4}>
            <TableContainer component={Paper}>
                <Table data-test-id={formatTestId('table', 'collections')}>
                    <TableHead>
                        <TableRow className={styles.header}>
                            <TableCell className={styles.headerCell}>
                                Reference
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                Name
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                Reference Period
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                State
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {references.map((reference, index) => (
                            <CollectionsTableRow
                                key={reference}
                                reference={reference}
                                index={index}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

const states = {
    UNINITIALISED: 1,
    LOADING: 2,
    LOADED: 3,
    LOADING_FAILED: 4,
};

const determineState = collections => {
    if (collections === collectionsStates.LOADING) {
        return states.LOADING;
    }

    if (collections === collectionsStates.LOADED) {
        return states.LOADED;
    }

    if (collections === collectionsStates.LOADING_FAILED) {
        return states.LOADING_FAILED;
    }

    return states.UNINITIALISED;
};

const useCollections = () => {
    const dispatch = useDispatch();

    const collectionsState = useSelector(collectionsSelectors.getState);

    const loadData = useCallback(
        ({ limit, offset }) => {
            dispatch(collectionsActions.fetchCollections({ limit, offset }));
        },
        [dispatch],
    );

    return {
        state: determineState(collectionsState),
        loadData,
    };
};

const ErrorMessage = ({ title, children }) => (
    <Container maxWidth={'sm'}>
        <Typography variant={'h6'} align={'left'} gutterBottom>
            {title}
        </Typography>
        {children}
    </Container>
);

ErrorMessage.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
};

const CollectionStatus = ({ id }) => {
    switch (id) {
        case collectionStates.DRAFT:
            return <span className={styles.collectionState}>Draft</span>;

        case collectionStates.DELETED:
            return <span className={styles.collectionState}>Deleted</span>;

        case collectionStates.OPEN:
            return <span className={styles.collectionState}>Open</span>;

        case collectionStates.CLOSED:
            return <span className={styles.collectionState}>Closed</span>;

        case collectionStates.HISTORIC_AMENDMENT:
            return (
                <span className={styles.collectionState}>
                    Historic Amendment
                </span>
            );

        case collectionStates.ARCHIVED:
            return <span className={styles.collectionState}>Archived</span>;

        case collectionStates.VALIDATION:
            return <span className={styles.collectionState}>Validation</span>;

        default:
            return <span className={styles.collectionState}>Unassigned</span>;
    }
};

export const Collections = () => {
    usePageTitle('Monitoring');
    const { state, loadData } = useCollections();

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
    };

    const tableId = TABLE_PARAMS_IDS.COLLECTIONS;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { offset, limit } = tableParams;

    const { setLimit, setOffset } = tableParamsMethods;

    const pagingMetadata = useSelector(
        collectionsSelectors.getCollectionPagingMetadata,
    );

    useEffect(() => {
        loadData({
            limit: limit,
            offset: offset * limit,
        });
    }, [loadData, offset, limit]);

    const handleChangePage = (_event, newPage) => {
        setOffset(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setLimit(event.target.value);

        if (event.target.value > pagingMetadata.total) {
            setOffset(0);
        }
    };

    const handleTryAgain = () =>
        loadData({
            limit: limit,
            offset: limit * offset,
        });

    const renderState = () => {
        switch (state) {
            case states.LOADING:
                return <Loading />;

            case states.LOADED:
                return (
                    <>
                        <CollectionsTable />
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={pagingMetadata.total}
                            rowsPerPage={limit}
                            page={offset}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                                'data-test-id': 'previousPage',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                                'data-test-id': 'nextPage',
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                );

            case states.LOADING_FAILED:
                return (
                    <ErrorMessage title={'Loading Error'}>
                        <Typography paragraph align={'left'}>
                            The list of collections failed to load.
                        </Typography>
                        <Button
                            variant={'outlined'}
                            onClick={handleTryAgain}
                            data-test-id={formatTestId('try again')}
                        >
                            Try again
                        </Button>
                    </ErrorMessage>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <PageTitle title="Monitoring" />
            <Box marginTop={3}>{renderState()}</Box>
        </>
    );
};
