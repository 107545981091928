const remove = key => () => window.localStorage.removeItem(key);
const get = key => () => {
    try {
        return JSON.parse(window.localStorage.getItem(key));
    } catch (error) {
        window.localStorage.removeItem(key);
        return null;
    }
};
const set = key => value =>
    window.localStorage.setItem(key, JSON.stringify(value));

export const token = {
    set: set('hdp.token'),
    get: get('hdp.token'),
    clear: remove('hdp.token'),
};
