import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { calculatePercentage } from 'src/pages/Collections/Submission/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import InformationPopup from '../InformationPopup/InformationPopup';

import styles from './qualityRule.module.scss';

type QualityRuleProps = {
    rule: any;
    validation: any;
    results: any;
    regulator: any;
    isDownloading: boolean;
    handleClickDownloadCsv: () => void;
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void;
    csvData: any;
    csvLink: any;
    pageData: any;
    limit: number;
    offset: number;
};

/*
    Quality rules table component that shows the details of a single quality rule. 
    TODO: Reduce the amount of props passed to the component by handling the data fetching 
    and table params in this component, and only pass in the tableID and API url?
*/
const QualityRule = (props: QualityRuleProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const {
        rule,
        validation,
        results,
        regulator,
        isDownloading,
        handleClickDownloadCsv,
        handleChangePage,
        handleChangeRowsPerPage,
        csvData,
        csvLink,
        pageData,
        limit,
        offset,
    } = props;
    const history = useNavigate();

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const dialog = () => {
        return (
            <InformationPopup
                isOpen={isDialogOpen}
                onConfirm={closeDialog}
                title={rule}
                description={validation.Description}
            />
        );
    };
    return (
        <div>
            <Paper className={styles.QualityRule}>
                {isDialogOpen ? dialog() : null}
                <div className={styles.title}>
                    <h2 className="margin--bottom-0-5">
                        <a
                            href={validation.GuidanceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {rule}
                        </a>
                    </h2>
                    <Button
                        className={styles.descriptionButton}
                        onClick={openDialog}
                        aria-haspopup="dialog"
                        data-test-id="openDescription"
                    >
                        Description
                    </Button>
                </div>
                <div className={styles.ruleDataItem}>
                    <span>Description</span>
                    <span>{validation.Description}</span>
                </div>
                <div className={styles.ruleDataWrapper}>
                    {results && results?.length > 0 ? (
                        <div className={styles.ruleData}>
                            <div className={styles.ruleDataItem}>
                                <span>Regulator</span>
                                <span>
                                    {regulator?.name} ({regulator?.shortName})
                                </span>
                            </div>
                            <div className={styles.ruleDataItem}>
                                <span>Tolerance</span>
                                <span className={styles.tolerance}>
                                    {results[0]?.ConjoinedTolerances}

                                    {results[0]
                                        ?.IsProviderToleranceOverride && (
                                        <Chip
                                            size="small"
                                            label="Override"
                                            className={styles.overrideChip}
                                        />
                                    )}
                                </span>
                            </div>
                            <div className={styles.ruleDataItem}>
                                <span>Population</span>
                                <span>{results[0]?.Population}</span>
                            </div>
                            <div className={styles.ruleDataItem}>
                                <span>Failed</span>
                                <span>
                                    {results[0]?.Failures} (
                                    {calculatePercentage(
                                        results[0]?.Failures,
                                        results[0]?.Population,
                                    )}
                                    %)
                                </span>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className={styles.downloadButtons}>
                        <Button
                            className="hdp-override"
                            variant="contained"
                            size="small"
                            disabled={isDownloading}
                            data-test-id={formatTestId('download details')}
                            onClick={handleClickDownloadCsv}
                        >
                            Download Details
                        </Button>
                        {csvData && (
                            <a {...csvData} ref={csvLink}>
                                Download file
                            </a>
                        )}
                    </div>
                </div>
                <Paper className={styles.tableWrapper}>
                    <Table
                        className={styles.table}
                        data-test-id={formatTestId('table', 'quality rule')}
                    >
                        <TableHead>
                            <TableRow key={'dataColumns'}>
                                {results &&
                                    results?.length > 0 &&
                                    results[0]?.Details &&
                                    results[0]?.Details?.map(
                                        (column: {
                                            // TODO: Proper type
                                            Name: string | null | undefined;
                                        }) => (
                                            <TableCell
                                                key={`header_${column.Name}`}
                                                className={`${styles.cell} ${styles.cellHeader}`}
                                            >
                                                {column.Name}
                                            </TableCell>
                                        ),
                                    )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results &&
                                results.map(
                                    (
                                        // TODO: Proper type
                                        row: {
                                            RowIdentifier: string;
                                            Details: any[];
                                        },
                                        index: number,
                                    ) => (
                                        <TableRow
                                            className={styles.tableRow}
                                            key={`${index}-${row.RowIdentifier}`}
                                            hover
                                        >
                                            {row.Details &&
                                                row.Details.map(detail => (
                                                    <TableCell
                                                        key={`${row.RowIdentifier}_valueFor_${detail.Name}`}
                                                    >
                                                        {detail.Value}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    ),
                                )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={pageData?.totalResultsCount || 0}
                        rowsPerPage={limit}
                        page={offset}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Paper>
            <div className={styles.buttons}>
                <Button
                    className="hdp-override large hdp-override--grey"
                    data-test-id="back"
                    disabled={false}
                    color="primary"
                    variant="contained"
                    size="small"
                    disableRipple={true}
                    onClick={() => history(-1)}
                >
                    Back
                </Button>
            </div>
        </div>
    );
};

export default QualityRule;
