import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

import { useSubmissionStates } from '../../../queries';

import styles from './stateFilter.module.scss';

const useStates = availableStateIds => {
    const { status, data } = useSubmissionStates();

    if (status !== 'success') {
        return [];
    }

    return data.map(s => {
        const state =
            s.id === null ? { ...s, id: 0, code: '__NOT_STARTED' } : s;
        return {
            ...state,
            disabled: !availableStateIds.includes(state.id),
        };
    });
};

export const StateFilter = React.memo(
    ({ value, availableStateIds, onChange }) => {
        const states = useStates(availableStateIds);

        const handleChange = useCallback(
            (_e, option) => {
                onChange(option || {});
            },
            [onChange],
        );

        const selected = states.find(state => state.code === value) || null;
        const getOptionDisabled = option => option.disabled === true;

        return (
            <Autocomplete
                className={styles.stateFilter}
                options={states}
                getOptionDisabled={getOptionDisabled}
                onChange={handleChange}
                value={selected}
                getOptionLabel={option => option.name}
                renderOption={(props, option) => (
                    <span {...props}>{option.name}</span>
                )}
                renderInput={params => (
                    <TextField
                        {...params}
                        placeholder="Filter by state"
                        size={'small'}
                    />
                )}
            />
        );
    },
);
