import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, NativeSelect } from '@mui/material';
import { RestrictAccess } from 'components';
import {
    actions,
    collectionStates,
    selectors,
    states,
} from 'modules/collections';
import PropTypes from 'prop-types';
import { capitalizeString } from 'src/utils/capitalizeString/capitalizeString';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { CancelButton, EditButton, SaveButton } from '../Buttons';

const CollectionStatus = ({ id, reference }) => {
    const collectionState =
        Object.keys(collectionStates).find(
            key => collectionStates[key] === id,
        ) || 'Unassigned';

    const label = capitalizeString(collectionState.replaceAll(/_/g, ' '));

    return (
        <Chip
            variant="outlined"
            label={label}
            disabled={label === 'Unassigned'}
            data-test-id={formatTestId('collection state', reference)}
        />
    );
};

CollectionStatus.propTypes = {
    id: PropTypes.number,
    reference: PropTypes.string.isRequired,
};

const modes = {
    DISPLAY: 1,
    EDITING: 2,
    SAVING: 3,
    ERROR: 4,
};

export const CollectionState = ({ reference }) => {
    const { DISPLAY, EDITING, SAVING, ERROR } = modes;

    const dispatch = useDispatch();
    const { id, status, isEditable } = useSelector(
        selectors.getCollectionState(reference),
    );
    const [mode, setMode] = useState(DISPLAY);

    const defaultChoice = { id: '', name: 'Select state' };
    const availableStates = useSelector(
        selectors.getAvailableCollectionStates(reference),
    );
    const [value, setValue] = useState(defaultChoice.id);

    useEffect(() => {
        switch (status) {
            case states.collectionState.UPDATING:
                return setMode(SAVING);

            case states.collectionState.UPDATED:
                return setMode(DISPLAY);

            case states.collectionState.UPDATING_FAILED:
                return setMode(ERROR);

            default:
                return;
        }
    }, [DISPLAY, ERROR, SAVING, status]);

    useEffect(() => {
        if (mode === DISPLAY) {
            setValue(defaultChoice.id);
        }
    }, [DISPLAY, defaultChoice.id, mode]);

    const handleChange = event => {
        setValue(event.target.value);
    };

    const handleEdit = () => setMode(EDITING);
    const handleCancel = () => setMode(DISPLAY);
    const handleSave = useCallback(
        () =>
            dispatch(
                actions.updateState({
                    reference,
                    stateId: value,
                }),
            ),
        [dispatch, reference, value],
    );

    const renderDropdown = ({ disabled = false, error = false } = {}) => (
        <NativeSelect
            value={value}
            onChange={handleChange}
            disabled={disabled}
            error={error}
            inputProps={{
                'aria-label': 'Specification states',
                'data-test-id': formatTestId('select state', reference),
            }}
        >
            {[defaultChoice, ...availableStates].map(({ id, name }) => (
                <option key={id} value={id}>
                    {name}
                </option>
            ))}
        </NativeSelect>
    );

    const renderEditButton = () => (
        <RestrictAccess
            allowPermissions={['management.change-collection-state']}
        >
            <EditButton
                title="Change collection state"
                onClick={handleEdit}
                data-test-id={formatTestId('changeState', reference)}
            />
        </RestrictAccess>
    );

    const renderSaveButton = ({ loading = false, disabled = false } = {}) => (
        <SaveButton
            title="Save collection state"
            onClick={handleSave}
            loading={loading}
            disabled={loading || disabled}
            data-test-id={formatTestId('saveState', reference)}
        />
    );

    const renderCancelButton = ({ disabled = false } = {}) => (
        <CancelButton
            title="Cancel"
            onClick={handleCancel}
            disabled={disabled}
            data-test-id={formatTestId('cancelStateChange', reference)}
        />
    );

    const Wrapper = ({ children, justifyContent = 'space-between' }) => (
        <Box
            display={'flex'}
            justifyContent={justifyContent}
            alignItems={'center'}
        >
            {children}
        </Box>
    );

    switch (mode) {
        case EDITING:
            return (
                <Wrapper>
                    {renderDropdown()}
                    <Box>
                        {renderSaveButton({
                            disabled: value === defaultChoice.id,
                        })}
                        {renderCancelButton()}
                    </Box>
                </Wrapper>
            );

        case SAVING:
            return (
                <Wrapper>
                    {renderDropdown({ disabled: true })}
                    <Box>
                        {renderSaveButton({ loading: true })}
                        {renderCancelButton({ disabled: true })}
                    </Box>
                </Wrapper>
            );

        case ERROR:
            return (
                <Wrapper>
                    {renderDropdown({ error: true })}
                    <Box>
                        {renderSaveButton()}
                        {renderCancelButton()}
                    </Box>
                </Wrapper>
            );

        default:
            return (
                <Wrapper justifyContent={'flex-start'}>
                    <CollectionStatus id={id} reference={reference} />
                    {isEditable && renderEditButton()}
                </Wrapper>
            );
    }
};

CollectionState.propTypes = {
    reference: PropTypes.string.isRequired,
};
