import { FILE_STATUS } from 'src/constants/FileStatus';

import { StepperContext } from '../types';

import {
    currentStateMatchesFilters,
    stateHistoryIncludesFilters,
} from './utils';

const ACTIVE_STATES = [
    FILE_STATUS.HESA_ANALYST_APPROVAL,
    FILE_STATUS.HESA_APPROVAL,
    FILE_STATUS.SC_APPROVAL,
];
const COMPLETE_STATES = [FILE_STATUS.APPROVED];

const ERROR_STATES = [
    FILE_STATUS.RESUBMISSION_REQUIRED,
    FILE_STATUS.RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT,
];

const checkIfIsActive = (stepperContext: StepperContext) => {
    const { currentState } = stepperContext;

    return currentStateMatchesFilters(ACTIVE_STATES, currentState);
};

const checkIfIsError = (stepperContext: StepperContext) => {
    const { currentState } = stepperContext;

    return currentStateMatchesFilters(ERROR_STATES, currentState);
};

const checkIfIsComplete = (stepperContext: StepperContext) => {
    const { currentState, stateHistory } = stepperContext;

    const isResubmissionRequired = currentStateMatchesFilters(
        ERROR_STATES,
        currentState,
    );
    const isApproved = stateHistoryIncludesFilters(
        COMPLETE_STATES,
        stateHistory,
    );

    return !isResubmissionRequired && isApproved;
};

export default {
    checkIfIsActive,
    checkIfIsError,
    checkIfIsComplete,
};
