import { get, put } from '../utils';

export const getActivityLog = async ({
    collectionReference,
    limit,
    offset,
    instId,
}) => {
    let url = `/collections/activity-log?collectionReference=${collectionReference}&limit=${limit}&offset=${offset}`;
    if (instId) {
        url += `&instId=${instId}`;
    }
    return get(url);
};
export const getActivityLogEntries = async ({
    submissionUuid,
    activityLogTypeId,
}) =>
    get('/collections/activity-log-entries', {
        query: { submissionUuid, activityLogTypeId },
    });

export const getAll = ({ offset, limit } = { offset: 0, limit: 1000 }) =>
    get('/collections', { query: { offset, limit } });

export const updateSpecification = async ({ reference, specificationId }) =>
    put(`/collections/${reference}`, {
        body: { specificationId },
    });

export const updateState = async ({ reference, stateId }) =>
    put(`/collections/${reference}/state`, {
        body: { stateId },
    });
