import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { schemas as schemasApi } from 'services/api';
import { history } from 'services/history';

export const actionTypes = {
    FETCH_SCHEMAS: 'schemas/FETCH',
    FETCH_SCHEMAS_SUCCESS: 'schemas/FETCH_SUCCESS',
    FETCH_SCHEMAS_FAILED: 'schemas/FETCH_FAILED',

    FETCH_SCHEMA: 'schemas/FETCH_SCHEMA',
    FETCH_SCHEMA_SUCCESS: 'schemas/FETCH_SCHEMA_SUCCESS',
    FETCH_SCHEMA_FAILED: 'schemas/FETCH_SCHEMA_FAILED',

    UPDATE_STATE: 'schemas/UPDATE_STATE',
    UPDATE_STATE_SUCCESS: 'schemas/UPDATE_STATE_SUCCESS',
    UPDATE_STATE_FAILED: 'schemas/UPDATE_STATE_FAILED',

    CANCEL: 'schemas/CANCEL',
};

export const actions = {
    fetch: createAction(actionTypes.FETCH_SCHEMAS),
    fetchSuccess: createAction(actionTypes.FETCH_SCHEMAS_SUCCESS),
    fetchFailed: createAction(actionTypes.FETCH_SCHEMAS_FAILED),

    fetchSchema: createAction(actionTypes.FETCH_SCHEMA),
    fetchSchemaSuccess: createAction(actionTypes.FETCH_SCHEMA_SUCCESS),
    fetchSchemaFailed: createAction(actionTypes.FETCH_SCHEMA_FAILED),

    updateState: createAction(actionTypes.UPDATE_STATE),
    updateStateSuccess: createAction(actionTypes.UPDATE_STATE_SUCCESS),
    updateStateFailed: createAction(actionTypes.UPDATE_STATE_FAILED),

    cancel: createAction(actionTypes.CANCEL),
};

export const states = {
    fetch: {
        UNINITIALISED: 1,
        LOADING: 2,
        LOADED: 3,
        LOADING_FAILED: 4,
    },
    update: {
        UPDATING: 1,
        UPDATED: 2,
        UPDATE_FAILED: 3,
    },
};

const INITIAL_STATE = {
    state: {
        fetch: states.fetch.UNINITIALISED,
    },
    schemas: {},
    updating: {},
};

const keyBy = (a, key) =>
    a.reduce((output, i) => ({ ...output, [i[key]]: i }), {});

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SCHEMAS:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch:
                        state.state.fetch === states.fetch.UNINITIALISED
                            ? states.fetch.LOADING
                            : states.fetch.LOADED,
                },
            };

        case actionTypes.FETCH_SCHEMAS_SUCCESS:
            return {
                ...state,
                schemas: keyBy(
                    action.payload.schemas.map((schema, i) => ({
                        ...schema,
                        order: i,
                    })),
                    'id',
                ),
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADED,
                },
            };

        case actionTypes.FETCH_SCHEMAS_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING_FAILED,
                },
            };

        case actionTypes.FETCH_SCHEMA:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING,
                },
            };

        case actionTypes.FETCH_SCHEMA_SUCCESS:
            return {
                ...state,
                schemas: {
                    ...state.schemas,
                    [action.payload.id]: {
                        ...state.schemas[action.payload.id],
                        ...action.payload,
                    },
                },
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADED,
                },
            };

        case actionTypes.FETCH_SCHEMA_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING_FAILED,
                },
            };

        case actionTypes.UPDATE_STATE:
            return {
                ...state,
                updating: {
                    ...state.updating,
                    [action.payload.id]: states.update.UPDATING,
                },
            };

        case actionTypes.UPDATE_STATE_SUCCESS:
            return {
                ...state,
                schemas: {
                    ...state.schemas,
                    [action.payload.id]: {
                        ...state.schemas[action.payload.id],
                        ...action.payload,
                    },
                },
                updating: {
                    ...state.updating,
                    [action.payload.id]: states.update.UPDATED,
                },
            };

        case actionTypes.UPDATE_STATE_FAILED:
            return {
                ...state,
                updating: {
                    ...state.updating,
                    [action.payload.id]: states.update.UPDATE_FAILED,
                },
            };

        default:
            return state;
    }
};

const byOrderAsc = (a, b) => a.order - b.order;

const getFetchStatus = state => state.schemas.state.fetch;

const schemas = state => Object.values(state.schemas.schemas);

const getSchemas = createSelector(schemas, schemas => schemas.sort(byOrderAsc));
const getSchema = id => state => state.schemas.schemas[id];

const getSchemaState = id => state => {
    const { state: s, availableStates } = selectors.getSchema(id)(state);
    const status = state.schemas.updating[id];
    const isEditable = availableStates.length > 0;

    return {
        state: s,
        availableStates,
        status,
        isEditable,
    };
};

export const selectors = {
    getFetchStatus,
    getSchemas,
    getSchema,
    getSchemaState,
};

export const sagas = {
    onFetchSchemas: function* onFetchSchemas({ payload }) {
        try {
            const { state } = payload || {};
            const response = yield call(schemasApi.getAll, { state });
            yield put(actions.fetchSuccess(response));
        } catch (error) {
            yield put(actions.fetchFailed(error));
        }
    },

    onFetchSchema: function* onFetchSchema({ payload }) {
        try {
            const response = yield call(schemasApi.get, payload);
            yield put(actions.fetchSchemaSuccess(response));
        } catch (error) {
            yield put(actions.fetchSchemaFailed(error));
        }
    },

    onUpdateState: function* onUpdateState({ payload }) {
        const { id, stateId } = payload;

        try {
            const response = yield call(schemasApi.updateState, {
                id,
                stateId,
            });
            yield put(actions.updateStateSuccess(response));
        } catch (error) {
            yield put(actions.updateStateFailed({ id, error }));
        }
    },

    onCancel: function* onCancel() {
        yield call(history.replace, '/management/specifications/schemas');
    },

    listen: function* listen() {
        yield takeLatest(actionTypes.FETCH_SCHEMAS, sagas.onFetchSchemas);
        yield takeLatest(actionTypes.FETCH_SCHEMA, sagas.onFetchSchema);
        yield takeLatest(actionTypes.UPDATE_STATE, sagas.onUpdateState);
        yield takeLatest(actionTypes.CANCEL, sagas.onCancel);
    },
};
