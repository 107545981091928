import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { getAll } from 'services/api/regulators';

import styles from './regulatorFilter.module.scss';

export const RegulatorFilter = ({ value, onChange }) => {
    const [regulators, setRegulators] = useState([]);
    const getPrimaryRegulators = async () => {
        const { regulators } = await getAll();
        setRegulators(
            regulators.filter(
                regulator => regulator.regulatorTypeCode === '01',
            ),
        );
    };
    const sortAlphabetically = regulators =>
        regulators.slice().sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        });

    const handleChange = useCallback(
        (_e, option) => {
            onChange(option || {});
        },
        [onChange],
    );

    const selected =
        regulators.find(regulator => {
            return regulator.regulatorCode === value;
        }) || null;
    useEffect(() => {
        getPrimaryRegulators();
    }, []);
    return (
        <Autocomplete
            className={styles.regulatorFilter}
            options={sortAlphabetically(regulators)}
            onChange={handleChange}
            value={selected}
            getOptionLabel={option => option.name}
            renderOption={(props, option) => (
                <span {...props}>
                    <span className="RegulatorFilter__name">
                        {option?.name}
                    </span>
                </span>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder="Filter by regulator"
                    size={'small'}
                />
            )}
        />
    );
};

RegulatorFilter.displayName = 'RegulatorFilter';
