import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as authService from 'services/auth';
import { history } from 'services/history';

export const actionTypes = {
    LOAD_USER: 'auth/LOAD_USER',
    LOAD_USER_FAILED: 'auth/LOAD_USER_FAILED',

    USER_FOUND: 'auth/USER_FOUND',
    USER_NOT_FOUND: 'auth/USER_NOT_FOUND',
    USER_EXPIRED: 'auth/USER_EXPIRED',

    SIGN_IN: 'auth/SIGN_IN',
    SIGN_IN_CALLBACK: 'auth/SIGN_IN_CALLBACK',
    SIGN_IN_CALLBACK_SUCCESS: 'auth/SIGN_IN_CALLBACK_SUCCESS',
    SIGN_IN_CALLBACK_FAILED: 'auth/SIGN_IN_CALLBACK_FAILED',

    SIGN_IN_SILENT: 'auth/SIGN_IN_SILENT',
    SIGN_IN_SILENT_SUCCESS: 'auth/SIGN_IN_SILENT_SUCCESS',
    SIGN_IN_SILENT_FAILED: 'auth/SIGN_IN_SILENT_FAILED',

    SIGN_OUT: 'auth/SIGN_OUT',
    SIGN_OUT_CALLBACK: 'auth/SIGN_OUT_CALLBACK',
    SIGN_OUT_CALLBACK_SUCCESS: 'auth/SIGN_OUT_CALLBACK_SUCCESS',
    SIGN_OUT_CALLBACK_FAILED: 'auth/SIGN_OUT_CALLBACK_FAILED',
};

export const actions = {
    loadUser: createAction(actionTypes.LOAD_USER),
    loadUserFailed: createAction(actionTypes.LOAD_USER_FAILED),

    userFound: createAction(actionTypes.USER_FOUND),
    userNotFound: createAction(actionTypes.USER_NOT_FOUND),
    userExpired: createAction(actionTypes.USER_EXPIRED),

    signIn: createAction(actionTypes.SIGN_IN),
    signInCallback: createAction(actionTypes.SIGN_IN_CALLBACK),
    signInCallbackSuccess: createAction(actionTypes.SIGN_IN_CALLBACK_SUCCESS),
    signInCallbackFailed: createAction(actionTypes.SIGN_IN_CALLBACK_FAILED),

    signInSilent: createAction(actionTypes.SIGN_IN_SILENT),
    signInSilentSuccess: createAction(actionTypes.SIGN_IN_SILENT_SUCCESS),
    signInSilentFailed: createAction(actionTypes.SIGN_IN_SILENT_FAILED),

    signOut: createAction(actionTypes.SIGN_OUT),
    signOutCallback: createAction(actionTypes.SIGN_OUT_CALLBACK),
    signOutCallbackSuccess: createAction(actionTypes.SIGN_OUT_CALLBACK_SUCCESS),
    signOutCallbackFailed: createAction(actionTypes.SIGN_OUT_CALLBACK_FAILED),
};

export const sagas = {
    onLoadUser: function* onLoadUser() {
        try {
            const user = yield call(authService.getUser);

            if (!user) {
                return yield put(actions.userNotFound());
            }

            if (user && user.expired) {
                return yield put(actions.userExpired(user));
            }

            return yield put(actions.userFound(user));
        } catch (error) {
            return yield put(actions.loadUserFailed(error));
        }
    },

    onUserNotFound: function* onUserNotFound() {
        yield put(actions.signIn());
    },

    onUserExpired: function* onUserExpired() {
        yield put(actions.signIn());
    },

    onSignIn: function* signIn() {
        yield call(authService.signIn, { location: history.location });
    },

    onSignInCallback: function* onSignInCallback({ payload }) {
        const { navigate } = payload;

        try {
            const { state } = yield call(authService.signinCallback);
            yield put(
                actions.signInCallbackSuccess({
                    location: state.location,
                    navigate,
                }),
            );
        } catch (error) {
            yield put(actions.signInCallbackFailed({ error, navigate }));
        }
    },

    onSignInCallbackSuccess: function* onSignInCallbackSuccess({ payload }) {
        const { navigate, location } = payload;
        yield call(navigate, location.pathname);
    },

    onSignInCallbackFailed: function* onSignInCallbackFailed({ payload }) {
        const { navigate } = payload;
        yield call(navigate, '/');
    },

    onSignOut: function* onSignOut() {
        yield call(authService.signOut);
    },

    onSignOutCallback: function* onSignOutCallback({ payload }) {
        const { navigate } = payload;
        try {
            yield call(authService.signoutCallback);
            yield put(
                actions.signOutCallbackSuccess({
                    navigate,
                }),
            );
        } catch (error) {
            yield put(actions.signOutCallbackFailed({ error, navigate }));
        }
    },

    onSignOutCallbackSuccess: function* onSignOutCallbackSuccess({ payload }) {
        const { navigate } = payload;
        yield call(navigate, '/');
    },

    onSignOutCallbackFailed: function* onSignOutCallbackFailed({ payload }) {
        const { navigate } = payload;
        yield call(navigate, '/');
    },

    onSignInSilent: function* onSignInSilent() {
        try {
            yield call(authService.signInSilent);
            yield put(actions.signInSilentSuccess());
        } catch (err) {
            yield put(actions.signInSilentFailed());
        }
    },

    onSignInSilentFailed: function* onSignInSilentFailed() {
        yield call(authService.signOut);
    },

    listen: function* listen() {
        yield takeLatest(actionTypes.LOAD_USER, sagas.onLoadUser);
        yield takeLatest(actionTypes.USER_NOT_FOUND, sagas.onUserNotFound);
        yield takeLatest(actionTypes.USER_EXPIRED, sagas.onUserExpired);

        yield takeLatest(actionTypes.SIGN_IN, sagas.onSignIn);
        yield takeLatest(actionTypes.SIGN_IN_CALLBACK, sagas.onSignInCallback);
        yield takeLatest(
            actionTypes.SIGN_IN_CALLBACK_SUCCESS,
            sagas.onSignInCallbackSuccess,
        );
        yield takeLatest(
            actionTypes.SIGN_IN_CALLBACK_FAILED,
            sagas.onSignInCallbackFailed,
        );

        yield takeLatest(actionTypes.SIGN_OUT, sagas.onSignOut);
        yield takeLatest(
            actionTypes.SIGN_OUT_CALLBACK,
            sagas.onSignOutCallback,
        );
        yield takeLatest(
            actionTypes.SIGN_OUT_CALLBACK_SUCCESS,
            sagas.onSignOutCallbackSuccess,
        );
        yield takeLatest(
            actionTypes.SIGN_OUT_CALLBACK_FAILED,
            sagas.onSignOutCallbackFailed,
        );
        yield takeLatest(actionTypes.SIGN_IN_SILENT, sagas.onSignInSilent);
        yield takeLatest(
            actionTypes.SIGN_IN_SILENT_FAILED,
            sagas.onSignInSilentFailed,
        );
    },
};
