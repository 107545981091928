import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const AuthCallback = ({ action }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(action({ navigate }));
    }, [dispatch, action, navigate]);

    return null;
};
