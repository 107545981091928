import React, { useCallback, useEffect, useState } from 'react';
import { List, ListItem } from '@mui/material';
import { getOvtSchemaChecks, getSchemaChecks } from 'services/api/submissions';
import { formatSchemaErrorIds } from 'src/utils/formatSchemaErrorIds/formatSchemaErrorIds';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './schemaFailuresReport.module.scss';

const SchemaFailuresReport = props => {
    const { instId, submissionId, isOvt } = props;
    const [errors, setErrors] = useState([]);
    const getSchemaFailures = useCallback(
        async (submissionId, instId, isOvt) => {
            let schemaFailures;
            if (isOvt) {
                schemaFailures = await getOvtSchemaChecks({
                    submissionId,
                    instId,
                    offset: 0,
                    limit: 101,
                });
            } else {
                schemaFailures = await getSchemaChecks({
                    submissionId,
                    instId,
                    offset: 0,
                    limit: 101,
                });
            }
            setErrors(schemaFailures);
        },
        [],
    );

    useEffect(() => {
        const fetchData = async () => {
            await getSchemaFailures(submissionId, instId, isOvt);
        };

        fetchData();
    }, [getSchemaFailures, submissionId, instId]);

    const schemaErrorSubsetMessage =
        errors.length === 101
            ? 'This report is limited to 100 rows. To see all errors, please download the full report using the download icon next to Schema check failed'
            : '';

    return (
        <div className={styles.schemaFailuresReport}>
            <span className={styles.heading}>
                Schema validations errors
                <br />
                {schemaErrorSubsetMessage}
            </span>

            <List>
                {errors.slice(0, 100).map((error, index) => {
                    return (
                        <ListItem
                            key={index}
                            data-test-id={formatTestId(
                                'schema validation error',
                                index,
                            )}
                            className={styles.listItem}
                        >
                            Line:{error.LineNumber}
                            <br />
                            Error:{error.Error}
                            <br />
                            Details:{formatSchemaErrorIds(error.Details)}
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};

export default SchemaFailuresReport;
