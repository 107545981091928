import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useUserContext } from 'src/components';
import { FILE_STATUS } from 'src/constants/FileStatus';
import { getApprovalStates } from 'src/pages/Collections/Submission/utils';

import {
    getUiState,
    uiStates,
    useProviderStatuses,
    useSubmissionStates,
} from '../queries';

const OFS_CODE = '5016';

const transformToSteps = states => {
    const steps = states.reduce((acc, { step }) => {
        return { ...acc, [step.number]: { ...step, count: 0 } };
    }, {});

    return Object.values(steps);
};

const addTotalSteps = steps => providerStatus => {
    return {
        ...providerStatus,
        totalSteps: steps.length,
    };
};

const transformToAwaitingParties = states => {
    return states.reduce((output, state) => {
        return {
            ...output,
            [state.id]: state.awaitingParty,
        };
    }, {});
};

const addAwaitingParty = awaitingParties => providerStatus => {
    return {
        ...providerStatus,
        awaitingParty: providerStatus.latestSubmission
            ? awaitingParties[providerStatus.latestSubmission?.status.id]
            : { id: 1, name: 'Provider' },
    };
};

const applyFilters = (filters, isHesaAndProviderSignOff) => providerStatus => {
    return Object.entries(filters).reduce((acc, [name, value]) => {
        if (acc === false) {
            return acc;
        }

        if (value === undefined) {
            return true;
        }

        switch (name) {
            case 'institutionId':
                return providerStatus.provider.institutionId === value;

            case 'state':
                return (
                    (value === '__NOT_STARTED' &&
                        providerStatus.latestSubmission === null) ||
                    providerStatus.latestSubmission?.status.code === value
                );

            case 'awaitingAction':
                if (
                    isHesaAndProviderSignOff(providerStatus.latestSubmission) &&
                    value === 1
                ) {
                    return false;
                } else if (
                    isHesaAndProviderSignOff(providerStatus.latestSubmission) &&
                    value === 2
                ) {
                    return true;
                }
                return providerStatus.awaitingParty?.id === value;

            case 'regulatorCode':
                return providerStatus.provider.regulatorCode === value;

            default:
                return true;
        }
    }, true);
};

const sortByLastSubmission = (a, b) => {
    const aDate = DateTime.fromISO(a.latestSubmission.uploaded);
    const bDate = DateTime.fromISO(b.latestSubmission.uploaded);

    if (aDate < bDate) return 1;
    else if (aDate === bDate) return 0;
    else return -1;
};

const sortByStep = prop => order => (a, b) => {
    if (a[prop] && b[prop]) {
        if (a[prop].status.step.number < b[prop].status.step.number)
            return order === 'desc' ? 1 : -1;

        if (a[prop].status.step.number > b[prop].status.step.number)
            return order === 'desc' ? -1 : 1;
    }

    if (b[prop] && !a[prop]) return order === 'desc' ? 1 : -1;
    if (!b[prop] && a[prop]) return order === 'desc' ? -1 : 1;

    if (a.provider.name > b.provider.name) return 1;
    if (a.provider.name < b.provider.name) return -1;

    return 0;
};

const sortByCurrentStep = sortByStep('latestSubmission');
const sortByFurthestStep = sortByStep('furthestSubmission');

const sortResults = (array, sortOrder, sortBy) => {
    let sortedArray;

    switch (sortBy) {
        case 'last-submission':
            sortedArray = array
                .filter(sub => !!sub?.latestSubmission?.uploaded)
                .sort(sortByLastSubmission);
            if (sortOrder !== 'desc') sortedArray.reverse();

            return sortedArray.concat(
                array.filter(sub => !sub?.latestSubmission?.uploaded),
            );

        case 'current-step':
            return array.toSorted(sortByCurrentStep(sortOrder));

        case 'furthest-step':
            return array.toSorted(sortByFurthestStep(sortOrder));

        default:
            return array;
    }
};

const getNextApprovalState = (approvalStateIndex, submissionApprovalStates) => {
    if (approvalStateIndex === submissionApprovalStates.length - 1) {
        return FILE_STATUS.APPROVED;
    } else if (approvalStateIndex !== -1) {
        return submissionApprovalStates.at(approvalStateIndex + 1);
    }
    return null;
};

const addNextApprovalState = providerStatus => {
    if (!providerStatus?.latestSubmission) return providerStatus;

    const submissionApprovalStates = getApprovalStates(
        providerStatus.latestSubmission?.riskCode,
        providerStatus.provider?.countryCode,
    );

    const approvalStateIndex = submissionApprovalStates.findIndex(
        state => state.id === providerStatus?.latestSubmission?.status?.id,
    );

    const nextApprovalState = getNextApprovalState(
        approvalStateIndex,
        submissionApprovalStates,
    );

    return {
        ...providerStatus,
        latestSubmission: {
            ...providerStatus?.latestSubmission,
            nextApprovalState,
        },
    };
};

const addActionStates =
    (isHesa, statutoryCustomer, isStatutoryCustomer) => providerStatus => {
        const { latestSubmission, provider } = providerStatus;
        const { status } = latestSubmission ?? {};

        const readyForHesaApproval = Boolean(
            isHesa &&
                [
                    FILE_STATUS.HESA_APPROVAL.id,
                    FILE_STATUS.HESA_ANALYST_APPROVAL.id,
                ].includes(status?.id),
        );

        const readyForScApproval = Boolean(
            isStatutoryCustomer &&
                status?.id === FILE_STATUS.SC_APPROVAL.id &&
                (statutoryCustomer?.code === provider?.regulatorCode ||
                    statutoryCustomer?.code === OFS_CODE),
        );

        const canRequestResubmission = Boolean(
            isHesa &&
                [
                    FILE_STATUS.APPROVED.id,
                    FILE_STATUS.PROVIDER_SIGNOFF.id,
                    FILE_STATUS.SIGNED_OFF.id,
                ].includes(status?.id),
        );

        const readyForSignOff = Boolean(
            isHesa && FILE_STATUS.PROVIDER_SIGNOFF.id === status?.id,
        );

        const canCreateNilReturn = Boolean(
            isHesa && !providerStatus?.latestSubmission,
        );

        return {
            ...providerStatus,
            actionStates: {
                readyForHesaApproval,
                readyForScApproval,
                canRequestResubmission,
                readyForSignOff,
                canCreateNilReturn,
            },
        };
    };

const pipe =
    (...fns) =>
    x =>
        fns.reduce((v, f) => f(v), x);

export const useProviderSubmissions = ({
    collectionReference,
    filters,
    sortBy,
    sortOrder,
}) => {
    const providerStatuses = useProviderStatuses(collectionReference);
    const { isHesa, statutoryCustomer, isStatutoryCustomer } = useUserContext();
    const submissionStates = useSubmissionStates();

    const isHesaAndProviderSignOff = useCallback(
        latestSubmission => {
            return (
                isHesa &&
                latestSubmission?.status?.id === FILE_STATUS.PROVIDER_SIGNOFF.id
            );
        },
        [isHesa],
    );

    const status = useMemo(
        () => getUiState([providerStatuses, submissionStates]),
        [providerStatuses, submissionStates],
    );

    const submissions = useMemo(() => {
        return status === uiStates.LOADED
            ? providerStatuses.data.map(
                  pipe(
                      addTotalSteps(transformToSteps(submissionStates.data)),
                      addAwaitingParty(
                          transformToAwaitingParties(submissionStates.data),
                      ),
                      addNextApprovalState,
                      addActionStates(
                          isHesa,
                          statutoryCustomer,
                          isStatutoryCustomer,
                      ),
                  ),
              )
            : [];
    }, [
        status,
        providerStatuses.data,
        submissionStates.data,
        isHesa,
        statutoryCustomer,
        isStatutoryCustomer,
    ]);

    const filteredSubmissions = useMemo(() => {
        const filtered = submissions.filter(
            applyFilters(filters, isHesaAndProviderSignOff),
        );

        const sorted = sortResults(filtered, sortOrder, sortBy);

        return sorted;
    }, [filters, isHesaAndProviderSignOff, sortOrder, sortBy, submissions]);

    const states = useMemo(() => {
        return status === uiStates.LOADED ? submissionStates.data : [];
    }, [submissionStates, status]);

    return {
        status,
        submissions,
        filteredSubmissions,
        submissionStates: states,
    };
};
