import { createElement } from 'react';

import { useUserContext } from '../useUserContext';

export const RestrictAccess = ({
    allowPermissions,
    component,
    render,
    fallBackComponent,
    children,
}) => {
    const { permissionsBySection } = useUserContext();

    if (
        permissionsBySection &&
        allowPermissions &&
        allowPermissions.some(allowPermission => {
            const [sectionName, permissionName] = allowPermission.split('.');
            if ((permissionsBySection[sectionName] || {})[permissionName])
                return true;
        })
    ) {
        return children;
    }

    if (component) {
        return createElement(component);
    }

    if (render) {
        return render();
    }

    if (fallBackComponent) {
        return fallBackComponent;
    }

    return null;
};
