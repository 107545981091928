import { createAction } from 'redux-actions';
import { call, put, retry, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { collections as collectionsApi } from 'services/api';

export const actionTypes = {
    FETCH_COLLECTIONS: 'collections/FETCH',
    FETCH_COLLECTIONS_SUCCESS: 'collections/FETCH_SUCCESS',
    FETCH_COLLECTIONS_FAILED: 'collections/FETCH_FAILED',

    UPDATE_SPECIFICATION: 'collections/UPDATE_SPECIFICATION',
    UPDATE_SPECIFICATION_SUCCESS: 'collections/UPDATE_SPECIFICATION_SUCCESS',
    UPDATE_SPECIFICATION_FAILED: 'collections/UPDATE_SPECIFICATION_FAILED',

    UPDATE_STATE: 'collections/UPDATE_STATE',
    UPDATE_STATE_SUCCESS: 'collections/UPDATE_STATE_SUCCESS',
    UPDATE_STATE_FAILED: 'collections/UPDATE_STATE_FAILED',
};

export const actions = {
    fetchCollections: createAction(actionTypes.FETCH_COLLECTIONS),
    fetchCollectionsSuccess: createAction(
        actionTypes.FETCH_COLLECTIONS_SUCCESS,
    ),
    fetchCollectionsFailed: createAction(actionTypes.FETCH_COLLECTIONS_FAILED),

    updateSpecification: createAction(actionTypes.UPDATE_SPECIFICATION),
    updateSpecificationSuccess: createAction(
        actionTypes.UPDATE_SPECIFICATION_SUCCESS,
    ),
    updateSpecificationFailed: createAction(
        actionTypes.UPDATE_SPECIFICATION_FAILED,
    ),

    updateState: createAction(actionTypes.UPDATE_STATE),
    updateStateSuccess: createAction(actionTypes.UPDATE_STATE_SUCCESS),
    updateStateFailed: createAction(actionTypes.UPDATE_STATE_FAILED),
};

export const states = {
    UNINITIALISED: 1,
    LOADING: 2,
    LOADED: 3,
    LOADING_FAILED: 4,

    specification: {
        UPDATING: 2,
        UPDATED: 3,
        UPDATING_FAILED: 4,
    },

    collectionState: {
        UPDATING: 2,
        UPDATED: 3,
        UPDATING_FAILED: 4,
    },
};

const INITIAL_STATE = {
    state: states.UNINITIALISED,
    collections: {},
    specification: {},
    collectionState: {},
};

const keyBy = (a, key) =>
    a.reduce((output, i) => ({ ...output, [i[key]]: i }), {});

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_COLLECTIONS:
            return {
                ...state,
                state: states.LOADING,
            };

        case actionTypes.FETCH_COLLECTIONS_SUCCESS:
            return {
                ...state,
                state: states.LOADED,
                pagingMetadata: action.payload.pagingMetadata,
                collections: keyBy(action.payload.collections, 'reference'),
                specification: {},
                collectionState: {},
            };

        case actionTypes.FETCH_COLLECTIONS_FAILED:
            return {
                ...state,
                state: states.LOADING_FAILED,
            };

        case actionTypes.UPDATE_SPECIFICATION:
            return {
                ...state,
                specification: {
                    ...state.specification,
                    [action.payload.reference]: states.specification.UPDATING,
                },
            };

        case actionTypes.UPDATE_SPECIFICATION_SUCCESS: {
            return {
                ...state,
                collections: {
                    ...state.collections,
                    [action.payload.reference]: {
                        ...state.collections[action.payload.reference],
                        ...action.payload.collection,
                    },
                },
                specification: {
                    ...state.specification,
                    [action.payload.reference]: states.specification.UPDATED,
                },
            };
        }

        case actionTypes.UPDATE_SPECIFICATION_FAILED: {
            return {
                ...state,
                specification: {
                    ...state.specification,
                    [action.payload.reference]:
                        states.specification.UPDATING_FAILED,
                },
            };
        }

        case actionTypes.UPDATE_STATE:
            return {
                ...state,
                collectionState: {
                    ...state.collectionState,
                    [action.payload.reference]: states.collectionState.UPDATING,
                },
            };

        case actionTypes.UPDATE_STATE_SUCCESS: {
            return {
                ...state,
                collections: {
                    ...state.collections,
                    [action.payload.reference]: {
                        ...state.collections[action.payload.reference],
                        ...action.payload.collection,
                    },
                },
                collectionState: {
                    ...state.collectionState,
                    [action.payload.reference]: states.collectionState.UPDATED,
                },
            };
        }

        case actionTypes.UPDATE_STATE_FAILED:
            return {
                ...state,
                collectionState: {
                    ...state.collectionState,
                    [action.payload.reference]:
                        states.collectionState.UPDATING_FAILED,
                },
            };

        default:
            return state;
    }
};

const byReferencePeriodStartDesc = (a, b) => {
    if (b.referencePeriodStart > a.referencePeriodStart) return 1;
    if (b.referencePeriodStart < a.referencePeriodStart) return -1;
    return 0;
};

export const collectionStates = {
    DRAFT: 1,
    DELETED: 2,
    OPEN: 3,
    CLOSED: 4,
    HISTORIC_AMENDMENT: 5,
    ARCHIVED: 6,
    VALIDATION: 7,
};

const getState = state => state.collections.state;
const getCollections = state => state.collections.collections;

const getCollectionStates = reference => state =>
    state.collections.collectionState[reference];

const getCollectionPagingMetadata = state => state.collections.pagingMetadata;

const getSpecificationStatus = reference => state =>
    state.collections.specification[reference];

const getCollection = reference =>
    createSelector(getCollections, collections => collections[reference]);

const getCollectionById = id =>
    createSelector(
        getCollections,
        collections =>
            Object.values(collections).filter(
                collection => collection.id === parseInt(id, 10),
            )[0] ?? null,
    );

export const selectors = {
    getState,

    getCollectionPagingMetadata,

    getCollectionReferences: createSelector(getCollections, collections =>
        Object.values(collections)
            .sort(byReferencePeriodStartDesc)
            .map(c => c.reference),
    ),

    getCollection,
    getCollectionById,

    getCollectionState: reference =>
        createSelector(
            getCollection(reference),
            getCollectionStates(reference),
            ({ state }, status) => {
                return {
                    ...state,
                    status,
                    isEditable: state.id !== null,
                };
            },
        ),

    isSpecificationEditable: reference =>
        createSelector(getCollection(reference), ({ state }) => {
            return [
                collectionStates.DRAFT,
                collectionStates.VALIDATION,
                collectionStates.OPEN,
            ].includes(state.id);
        }),

    getSpecification: reference =>
        createSelector(
            getCollection(reference),
            getSpecificationStatus(reference),
            ({ specification }, status) => ({
                ...specification,
                status,
            }),
        ),

    getAvailableCollectionStates: reference =>
        createSelector(
            getCollections,
            collections => collections[reference].availableStates,
        ),

    getCollectionId: reference =>
        createSelector(
            getCollections,
            collections => collections[reference].id,
        ),
};

export const sagas = {
    onFetchCollections: function* onFetchCollections({ payload }) {
        const { offset, limit } = payload || {};

        try {
            const collections = yield retry(3, 1000, collectionsApi.getAll, {
                offset,
                limit,
            });
            yield put(actions.fetchCollectionsSuccess(collections));
        } catch (error) {
            yield put(actions.fetchCollectionsFailed(error));
        }
    },

    onUpdateSpecification: function* onUpdateSpecification({ payload }) {
        const { reference, specificationId } = payload;
        try {
            const collection = yield call(collectionsApi.updateSpecification, {
                reference,
                specificationId,
            });
            yield put(
                actions.updateSpecificationSuccess({
                    reference,
                    collection,
                }),
            );
        } catch (error) {
            yield put(actions.updateSpecificationFailed({ reference, error }));
        }
    },

    onUpdateState: function* onUpdateState({ payload }) {
        const { reference, stateId } = payload;
        try {
            const collection = yield call(collectionsApi.updateState, {
                reference,
                stateId,
            });
            yield put(actions.updateStateSuccess({ reference, collection }));
        } catch (error) {
            yield put(actions.updateStateFailed({ reference, error }));
        }
    },

    listen: function* listen() {
        yield takeLatest(
            actionTypes.FETCH_COLLECTIONS,
            sagas.onFetchCollections,
        );

        yield takeEvery(
            actionTypes.UPDATE_SPECIFICATION,
            sagas.onUpdateSpecification,
        );

        yield takeEvery(actionTypes.UPDATE_STATE, sagas.onUpdateState);
    },
};
