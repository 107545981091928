import { createAction } from 'redux-actions';

export const actionTypes = {
    SET_NEW_MESSAGE: 'messageAnnouncer/SET_NEW_MESSAGE',
    CLEAR_CURRENT_MESSAGE: 'messageAnnouncer/CLEAR_CURRENT_MESSAGE',
};

export const actions = {
    setNewMessage: createAction(actionTypes.SET_NEW_MESSAGE),
    clearCurrentMessage: createAction(actionTypes.CLEAR_CURRENT_MESSAGE),
};

export const InitialState = {
    message: '',
    messageState: undefined,
};

export const reducer = (state = InitialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NEW_MESSAGE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case actionTypes.CLEAR_CURRENT_MESSAGE: {
            return {
                ...state,
                message: '',
                messageState: undefined,
            };
        }

        default:
            return state;
    }
};

export const selectors = {
    getMessage: state => state.messageAnnouncer,
};
