import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Link, Typography } from '@mui/material';
import { errors, selectors as appSelectors } from 'modules/app';
import { actions as userActions } from 'modules/user';

const Error = ({ title, children }) => (
    <Grid container>
        <Grid item xs={12} sm={6} md={4}>
            <Box bgcolor={'grey.100'} p={3}>
                <Typography paragraph>
                    <strong>{title}</strong>
                </Typography>
                {children}
            </Box>
        </Grid>
    </Grid>
);

const AccessDeniedError = ({ onLogin }) => {
    return (
        <Error title={'Access Denied'}>
            <Typography paragraph>
                You do not have a valid IDS role and/or organisation to access
                HDP.
            </Typography>
            <Typography paragraph variant={'body2'}>
                You may be trying to log in as a provider that is not one of the
                8 default providers currently supported by HDP.
            </Typography>
            <Link component={'button'} onClick={onLogin} variant={'body1'}>
                Return to IDS login
            </Link>
        </Error>
    );
};

const NoMatchingProvidersError = ({ onLogin }) => {
    return (
        <Error title={'No matching providers'}>
            <Typography paragraph>
                None of the provider organisations your user has access to exist
                in the current environment.
            </Typography>
            <Link component={'button'} onClick={onLogin} variant={'body1'}>
                Return to IDS login
            </Link>
        </Error>
    );
};

const NoDataError = () => {
    return (
        <Error title={'Failed to retrieve application data'}>
            <Typography paragraph>
                The application failed to load because it was not able to
                retrieve all the data it needs in order to run.
            </Typography>
            <Typography paragraph>Try refreshing your browser</Typography>
        </Error>
    );
};

const UnknownError = ({ onLogin }) => {
    return (
        <Error title={'Unknown Error'}>
            <Typography paragraph>
                An unknown error has prevented the HDP application from loading.
            </Typography>
            <Typography paragraph variant={'body2'}>
                Try using the link below to return to IDS and log in again.
            </Typography>
            <Link component={'button'} onClick={onLogin} variant={'body1'}>
                Return to IDS login
            </Link>
        </Error>
    );
};

export const AppError = () => {
    const dispatch = useDispatch();
    const error = useSelector(appSelectors.getError);

    const handleLogin = useCallback(
        () => dispatch(userActions.logout()),
        [dispatch],
    );

    switch (error) {
        case errors.NO_ORGANISATIONS:
            return <AccessDeniedError onLogin={handleLogin} />;

        case errors.NO_PROVIDERS:
            return <NoMatchingProvidersError onLogin={handleLogin} />;

        case errors.NO_DATA:
            return <NoDataError />;

        default:
            return <UnknownError onLogin={handleLogin} />;
    }
};

AppError.displayName = 'AppError';
