import { useUserContext } from 'components';

import { Selector } from '../Selector';

const toOption = ({ id, type, organisation }) => ({
    value: toValue({ id, type }),
    label: organisation.name,
    type,
});

const toValue = ({ id, type }) => `${type}:${id}`;

const groupLabels = {
    provider: 'Providers',
    hesa: 'HESA',
    statutory_customer: 'Statutory Customers',
};

const groupLabelOrder = ['provider', 'hesa', 'statutory_customer'];

const byLabel = (a, b) => {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
};

const byGroupLabel = (a, b) => {
    return groupLabelOrder.indexOf(a.type) - groupLabelOrder.indexOf(b.type);
};

const groupOrganisationsByRole = organisations => {
    const grouped = organisations
        .map(toOption)
        .reduce((output, organisation) => {
            return {
                ...output,
                [organisation.type]: [
                    ...(output[organisation.type]
                        ? output[organisation.type]
                        : []),
                    organisation,
                ],
            };
        }, {});

    return Object.entries(grouped)
        .map(([type, options]) => {
            return {
                type,
                groupLabel: groupLabels[type],
                options: options.sort(byLabel),
            };
        })
        .sort(byGroupLabel);
};

export const OrganisationSelector = () => {
    const { activeOrganisation, organisations, changeOrganisation } =
        useUserContext();

    const changeOrganisationSelector = optionValue => {
        const [type, id] = optionValue.split(':');
        changeOrganisation({ type, id });
    };

    if (!organisations || !activeOrganisation) {
        return null;
    }

    const types = new Set(organisations.map(organisation => organisation.type));
    const grouped = types.size > 1;

    const options = grouped
        ? groupOrganisationsByRole(organisations)
        : organisations.map(toOption).sort(byLabel);

    return (
        <Selector
            heading={'Organisation:'}
            options={options}
            onChange={changeOrganisationSelector}
            grouped={grouped}
            value={toValue({
                type: activeOrganisation.type,
                id: activeOrganisation.id,
            })}
        />
    );
};

OrganisationSelector.displayName = 'OrganisationSelector';
