import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

import styles from './awaitingActionFilter.module.scss';

export const AwaitingActionFilter = ({ actions, value, onChange }) => {
    const handleChange = useCallback(
        (_e, option) => {
            onChange(option || {});
        },
        [onChange],
    );

    const selected = actions.find(action => action.id === value) ?? null;

    return (
        <Autocomplete
            className={styles.awaitingActionFilter}
            options={actions}
            onChange={handleChange}
            value={selected}
            getOptionLabel={option => option.name}
            renderOption={(props, option) => (
                <span {...props}>{option.name}</span>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder="Awaiting action"
                    variant={'outlined'}
                    size={'small'}
                />
            )}
        />
    );
};
