import { delWithBody, get, post, postFile } from '../utils';

let req;

// Generic
const getFileFromPresignedUrl = ({ url }) => {
    const a = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

// Submission
export const uploadRequest = ({
    submissionUuid,
    fileName,
    collectionId,
    instId,
}) =>
    post('/files/uploadRequest', {
        body: {
            submissionUuid,
            fileName,
            collectionId,
            instId,
        },
    });
export const ovtUploadRequest = ({
    submissionUuid,
    fileName,
    collectionId,
    instId,
}) =>
    post('/files/ovt/uploadRequest', {
        body: {
            submissionUuid,
            fileName,
            collectionId,
            instId,
        },
    });

export const uploadFile = ({
    file,
    presignedPostData,
    xhr = new XMLHttpRequest(),
}) => {
    req = xhr;
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
    });
    formData.append('file', file);

    return new Promise((resolve, reject) => {
        req.open('POST', presignedPostData.url);

        req.addEventListener('load', event => {
            if (event.status >= 200 && event.status <= 300) {
                resolve(req.response);
            } else {
                reject({ status: event.status, statusText: req.statusText });
            }
        });

        req.addEventListener('error', event => {
            reject({
                status: event.status,
                statusText: req.statusText,
            });
        });

        req.send(formData);
    });
};

export const upload = async ({ presignedPostData, file }) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
    });
    formData.append('file', file);
    postFile(presignedPostData.url, { body: formData });
};

export const cancelUpload = async ({
    submissionUuid,
    collectionId,
    instId,
    fileName,
}) => {
    req?.abort();
    await post('/files/cancel-upload', {
        body: {
            submissionUuid,
            instId,
            collectionId,
            fileName,
        },
    });
};

export const cancelOvtUpload = async ({
    submissionUuid,
    collectionId,
    instId,
    fileName,
}) => {
    req?.abort();
    await post('/files/ovt/cancel-upload', {
        body: {
            submissionUuid,
            collectionId,
            instId,
            fileName,
        },
    });
};

export const downloadRequest = path =>
    post(`/files/downloadRequest`, { body: { key: path } });

export const ovtDownloadRequest = path =>
    post(`/files/ovt/downloadRequest`, { body: { key: path } });

// Quality Rules
export const downloadAllQualityRuleFailures = submissionUuid =>
    post(`/files/download-all-quality-rule-failures`, {
        body: { submissionUuid },
    });

export const ovtDownloadAllQualityRuleFailures = submissionUuid =>
    post(`/files/ovt/download-all-quality-rule-failures`, {
        body: { submissionUuid },
    });

export const getAllQualityRuleFailuresExists = submissionUuid =>
    get(`/files/all-quality-rule-failures-exists`, {
        query: { submissionUuid },
    });

export const ovtGetAllQualityRuleFailuresExists = submissionUuid =>
    get(`/files/ovt/all-quality-rule-failures-exists`, {
        query: { submissionUuid },
    });

// Delivery
export const downloadDelivery = (deliveryId, path) =>
    post(`/files/downloadDelivery`, { body: { deliveryId, key: path } });

export const downloadSubmissionDelivery = (uuid, key) =>
    post(`/files/downloadSubmissionDelivery`, { body: { uuid, key } });

export const deleteDelivery = (deliveryId, path) =>
    delWithBody(`/files/deleteDelivery`, { body: { deliveryId, key: path } });

export const downloadIrisOutput = ({ key, uuid }) =>
    post(`/files/downloadIrisOutput`, { body: { key, uuid } });

// Sign-off
export const signOffFormDownloadRequest = hash =>
    post(`/files/signOffFormDownloadRequest/${hash}`, {});

// Additional Collection Reports
export const getAcrCsvFile = async ({
    submissionUuid,
    reportCode,
    fileCode,
}) => {
    const res = await post(`/files/acr/url/${reportCode.toLowerCase()}/csv`, {
        body: { submissionUuid, reportCode, fileCode },
    });
    getFileFromPresignedUrl(res);
};
