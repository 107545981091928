import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './selector.module.scss';

export const Selector = ({
    heading,
    options,
    value,
    onChange,
    grouped = false,
}) => {
    const id = `selector-${heading}`;
    const handleChange = e => {
        e.preventDefault();
        onChange(e.target.value);
    };

    const renderOption = option => (
        <option
            key={option.value}
            value={option.value}
            data-test-id={formatTestId('option', option?.label)}
            disabled={option.disabled}
        >
            {option.label}
        </option>
    );

    const renderOptions = () => {
        if (grouped) {
            return (
                <>
                    {options.map(({ groupLabel, options }) => (
                        <optgroup key={groupLabel} label={groupLabel}>
                            {options.map(renderOption)}
                        </optgroup>
                    ))}
                </>
            );
        }

        return options.map(renderOption);
    };

    return (
        <div className={styles.selector}>
            <label htmlFor={id} className={styles.label}>
                {heading}
            </label>
            <select
                id={id}
                value={value}
                onChange={handleChange}
                className={styles.dropdown}
                data-test-id={formatTestId('open menu', heading)}
            >
                {renderOptions()}
            </select>
        </div>
    );
};

Selector.displayName = 'Selector';
