import { FILE_STATUS, fileStatusById } from 'src/constants/FileStatus';

import { StepperContext } from '../types';

const ACTIVE_STATE = FILE_STATUS.QUALITY_PROCESSING_COMPLETE;
const COMPLETE_STATE = FILE_STATUS.QUALITY_PROCESSING_COMPLETE;

const checkIfIsActive = (stepperContext: StepperContext) => {
    const { currentState } = stepperContext;
    return currentState?.id === ACTIVE_STATE.id;
};

const checkIfIsComplete = (stepperContext: StepperContext) => {
    const { currentState } = stepperContext;
    if (!currentState) return false;

    const isFurtherThanQualityAssurance =
        fileStatusById(currentState.id).order > COMPLETE_STATE.order;

    return isFurtherThanQualityAssurance;
};

const checkIfIsError = () => {
    return false;
};

export default {
    checkIfIsActive,
    checkIfIsComplete,
    checkIfIsError,
};
