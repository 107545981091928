import { createBrowserHistory } from 'history';
import { createAction } from 'redux-actions';

export const history = createBrowserHistory();

export const LOCATION_CHANGE = 'LOCATION_CHANGE';
export const locationChange = createAction(LOCATION_CHANGE);

export const listen = store =>
    history.listen((location, action) =>
        store.dispatch(locationChange({ location, action })),
    );
