import * as regulatorModule from 'modules/regulators';
import { createAction } from 'redux-actions';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { specifications as specificationsApi } from 'services/api';
import { history, LOCATION_CHANGE } from 'services/history';
import { defaultValidationGuidanceUrl } from 'src/constants/constants';

export const actionTypes = {
    FETCH_SPECIFICATIONS: 'specifications/FETCH',
    FETCH_SPECIFICATIONS_SUCCESS: 'specifications/FETCH_SUCCESS',
    FETCH_SPECIFICATIONS_FAILED: 'specifications/FETCH_FAILED',

    FETCH_SPECIFICATION: 'specifications/FETCH_SPEC',
    FETCH_SPECIFICATION_SUCCESS: 'specifications/FETCH_SPEC_SUCCESS',
    FETCH_SPECIFICATION_FAILED: 'specifications/FETCH_SPEC_FAILED',

    CREATE: 'specifications/CREATE',
    CREATE_SUCCESS: 'specifications/CREATE_SUCCESS',
    CREATE_FAILED: 'specifications/CREATE_FAILED',
    CANCEL_CREATE: 'specifications/CANCEL_CREATE',

    UPDATE_STATE: 'specifications/UPDATE_STATE',
    UPDATE_STATE_SUCCESS: 'specifications/UPDATE_STATE_SUCCESS',
    UPDATE_STATE_FAILED: 'specifications/UPDATE_STATE_FAILED',

    DELETE_SPECIFICATION: 'specifications/DELETE',
    DELETE_SPECIFICATION_SUCCESS: 'specifications/DELETE_SUCCESS',
    DELETE_SPECIFICATION_FAILED: 'specifications/DELETE_FAILED',

    SET_SPECIFICATION: 'specifications/SET_SPECIFICATION',

    FETCH_VALIDATIONS: 'specifications/FETCH_VALIDATIONS',
    FETCH_VALIDATIONS_SUCCESS: 'specifications/FETCH_VALIDATIONS_SUCCESS',
    FETCH_VALIDATIONS_FAILED: 'specifications/FETCH_VALIDATIONS_FAILED',
    VALIDATION_UPDATED: 'specifications/VALIDATION_UPDATED',

    FETCH_DERIVED_FIELDS: 'specifications/FETCH_DERIVED_FIELDS',
    FETCH_DERIVED_FIELDS_SUCCESS: 'specifications/FETCH_DERIVED_FIELDS_SUCCESS',
    FETCH_DERIVED_FIELDS_FAILED: 'specifications/FETCH_DERIVED_FIELDS_FAILED',

    APPLY_CONFIGURATION: 'specifications/APPLY_CONFIGURATION',
    APPLY_CONFIGURATION_SUCCESS: 'specifications/APPLY_CONFIGURATION_SUCCESS',
    APPLY_CONFIGURATION_FAILED: 'specifications/APPLY_CONFIGURATION_FAILED',
};

export const actions = {
    fetchSpecifications: createAction(actionTypes.FETCH_SPECIFICATIONS),
    fetchSpecificationsSuccess: createAction(
        actionTypes.FETCH_SPECIFICATIONS_SUCCESS,
    ),
    fetchSpecificationsFailed: createAction(
        actionTypes.FETCH_SPECIFICATIONS_FAILED,
    ),

    fetchSpecification: createAction(actionTypes.FETCH_SPECIFICATION),
    fetchSpecificationSuccess: createAction(
        actionTypes.FETCH_SPECIFICATION_SUCCESS,
    ),
    fetchSpecificationFailed: createAction(
        actionTypes.FETCH_SPECIFICATION_FAILED,
    ),

    create: createAction(actionTypes.CREATE),
    createSuccess: createAction(actionTypes.CREATE_SUCCESS),
    createFailed: createAction(actionTypes.CREATE_FAILED),
    cancelCreate: createAction(actionTypes.CANCEL_CREATE),

    updateState: createAction(actionTypes.UPDATE_STATE),
    updateStateSuccess: createAction(actionTypes.UPDATE_STATE_SUCCESS),
    updateStateFailed: createAction(actionTypes.UPDATE_STATE_FAILED),

    deleteSpecification: createAction(actionTypes.DELETE_SPECIFICATION),
    deleteSuccess: createAction(actionTypes.DELETE_SPECIFICATION_SUCCESS),
    deleteFailed: createAction(actionTypes.DELETE_SPECIFICATION_FAILED),

    setSpecification: createAction(actionTypes.SET_SPECIFICATION),

    fetchValidations: createAction(actionTypes.FETCH_VALIDATIONS),
    fetchValidationsSuccess: createAction(
        actionTypes.FETCH_VALIDATIONS_SUCCESS,
    ),
    fetchValidationsFailed: createAction(actionTypes.FETCH_VALIDATIONS_FAILED),
    validationUpdated: createAction(actionTypes.VALIDATION_UPDATED),

    fetchDerivedFields: createAction(actionTypes.FETCH_DERIVED_FIELDS),
    fetchDerivedFieldsSuccess: createAction(
        actionTypes.FETCH_DERIVED_FIELDS_SUCCESS,
    ),
    fetchDerivedFieldsFailed: createAction(
        actionTypes.FETCH_DERIVED_FIELDS_FAILED,
    ),

    applyConfiguration: createAction(actionTypes.APPLY_CONFIGURATION),
    applyConfigurationSuccess: createAction(
        actionTypes.APPLY_CONFIGURATION_SUCCESS,
    ),
    applyConfigurationFailed: createAction(
        actionTypes.APPLY_CONFIGURATION_FAILED,
    ),
};

export const states = {
    fetch: {
        UNINITIALISED: 1,
        LOADING: 2,
        LOADED: 3,
        LOADING_FAILED: 4,
    },

    create: {
        UNINITIALISED: 1,
        CREATING: 2,
        CREATED: 3,
        CREATE_FAILED: 4,
    },

    update: {
        UPDATING: 2,
        UPDATED: 3,
        UPDATING_FAILED: 4,
    },

    delete: {
        DELETING: 1,
        DELETED: 2,
        DELETE_FAILED: 3,
    },

    validations: {
        fetch: {
            UNINITIALISED: 1,
            LOADING: 2,
            LOADED: 3,
            LOADING_FAILED: 4,
        },
    },

    derivedFields: {
        fetch: {
            UNINITIALISED: 1,
            LOADING: 2,
            LOADED: 3,
            LOADING_FAILED: 4,
        },
    },

    applyConfiguration: {
        APPLYING: 1,
        APPLIED: 2,
        APPLY_FAILED: 3,
    },
};

const INITIAL_STATE = {
    state: {
        fetch: states.fetch.UNINITIALISED,
        create: states.create.UNINITIALISED,
        update: {},
        delete: {},
        applyConfiguration: {},

        validations: {
            fetch: states.validations.fetch.UNINITIALISED,
        },
        derivedFields: {
            fetch: states.derivedFields.fetch.UNINITIALISED,
        },
    },

    specifications: {},
    validations: [],
    derivedFields: [],

    pagination: {
        validations: {},
        derivedFields: {},
    },
};

const keyBy = (a, key) =>
    a.reduce((output, i) => ({ ...output, [i[key]]: i }), {});

const addIndex = (item, index) => ({ ...item, index });

const omit = (key, { [key]: _, ...o }) => o;

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SPECIFICATIONS:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING,
                },
            };

        case actionTypes.FETCH_SPECIFICATIONS_SUCCESS:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADED,
                },
                specifications: keyBy(
                    action.payload.specifications.map(addIndex),
                    'id',
                ),
            };

        case actionTypes.FETCH_SPECIFICATIONS_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING_FAILED,
                },
            };

        case actionTypes.FETCH_SPECIFICATION:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING,
                },
            };

        case actionTypes.FETCH_SPECIFICATION_SUCCESS:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADED,
                },
                specifications: {
                    ...state.specifications,
                    [action.payload.id]: action.payload,
                },
            };

        case actionTypes.FETCH_SPECIFICATION_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING_FAILED,
                },
            };

        case actionTypes.CREATE:
            return {
                ...state,
                state: {
                    ...state.state,
                    create: states.create.CREATING,
                },
            };

        case actionTypes.CREATE_SUCCESS:
            return {
                ...state,
                state: {
                    ...state.state,
                    create: states.create.CREATED,
                },
                specifications: {
                    ...state.specifications,
                    [action.payload.id]: {
                        ...state.specifications[action.payload.id],
                        ...action.payload,
                    },
                },
            };

        case actionTypes.CREATE_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    create: states.create.CREATE_FAILED,
                },
            };

        case actionTypes.UPDATE_STATE:
            return {
                ...state,
                state: {
                    ...state.state,
                    update: {
                        ...state.state.update,
                        [action.payload.id]: states.update.UPDATING,
                    },
                },
            };

        case actionTypes.UPDATE_STATE_SUCCESS:
            return {
                ...state,
                specifications: {
                    ...state.specifications,
                    [action.payload.id]: {
                        ...state.specifications[action.payload.id],
                        ...action.payload.specification,
                    },
                },
                state: {
                    ...state.state,
                    update: {
                        ...state.state.update,
                        [action.payload.id]: states.update.UPDATED,
                    },
                },
            };

        case actionTypes.UPDATE_STATE_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    update: {
                        ...state.state.update,
                        [action.payload.id]: states.update.UPDATING_FAILED,
                    },
                },
            };

        case actionTypes.SET_SPECIFICATION:
            return {
                ...state,
                specifications: {
                    ...state.specifications,
                    [action.payload.id]: action.payload,
                },
            };

        case actionTypes.DELETE_SPECIFICATION: {
            const { id } = action.payload;

            return {
                ...state,
                specifications: omit(id, state.specifications),
                state: {
                    ...state.state,
                    delete: {
                        ...state.state.delete,
                        [action.payload.id]: states.delete.DELETING,
                    },
                },
            };
        }

        case actionTypes.DELETE_SPECIFICATION_SUCCESS: {
            const { id } = action.payload;

            return {
                ...state,
                specifications: omit(id, state.specifications),
                state: {
                    ...state.state,
                    delete: {
                        ...state.state.delete,
                        [id]: states.delete.DELETED,
                    },
                },
            };
        }

        case actionTypes.DELETE_SPECIFICATION_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    delete: {
                        ...state.state.delete,
                        [action.payload.id]: states.delete.DELETE_FAILED,
                    },
                },
            };

        case LOCATION_CHANGE:
            return {
                ...state,
                state: {
                    ...state.state,
                    create: states.create.UNINITIALISED,
                },
            };

        case actionTypes.FETCH_VALIDATIONS:
            return {
                ...state,
                state: {
                    ...state.state,
                    validations: {
                        ...state.state.validations,
                        fetch: states.validations.fetch.LOADING,
                    },
                },
            };

        case actionTypes.FETCH_VALIDATIONS_SUCCESS:
            return {
                ...state,
                validations: keyBy(action.payload.validations, 'id'),
                pagination: {
                    ...state.pagination,
                    validations: action.payload.pagingMetadata,
                },
                state: {
                    ...state.state,
                    validations: {
                        ...state.state.validations,
                        fetch: states.validations.fetch.LOADED,
                    },
                },
            };

        case actionTypes.FETCH_VALIDATIONS_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    validations: {
                        ...state.state.validations,
                        fetch: states.validations.fetch.LOADING_FAILED,
                    },
                },
            };

        case actionTypes.VALIDATION_UPDATED:
            return {
                ...state,
                validations: {
                    ...state.validations,
                    [action.payload.validation.id]: action.payload.validation,
                },
            };

        case actionTypes.FETCH_DERIVED_FIELDS:
            return {
                ...state,
                state: {
                    ...state.state,
                    derivedFields: {
                        ...state.state.derivedFields,
                        fetch: states.derivedFields.fetch.LOADING,
                    },
                },
            };

        case actionTypes.FETCH_DERIVED_FIELDS_SUCCESS:
            return {
                ...state,
                derivedFields: action.payload.derivedFields,
                pagination: {
                    ...state.pagination,
                    derivedFields: action.payload.pagingMetadata,
                },
                state: {
                    ...state.state,
                    derivedFields: {
                        ...state.state.derivedFields,
                        fetch: states.derivedFields.fetch.LOADED,
                    },
                },
            };

        case actionTypes.FETCH_DERIVED_FIELDS_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    derivedFields: {
                        ...state.state.derivedFields,
                        fetch: states.derivedFields.fetch.LOADING_FAILED,
                    },
                },
            };

        case actionTypes.APPLY_CONFIGURATION:
            return {
                ...state,
                state: {
                    ...state.state,
                    applyConfiguration: {
                        ...state.state.applyConfiguration,
                        [action.payload.id]: states.applyConfiguration.APPLYING,
                    },
                },
            };

        case actionTypes.APPLY_CONFIGURATION_SUCCESS:
            return {
                ...state,
                state: {
                    ...state.state,
                    applyConfiguration: {
                        ...state.state.applyConfiguration,
                        [action.payload.id]: states.applyConfiguration.APPLIED,
                    },
                },
            };

        case actionTypes.APPLY_CONFIGURATION_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    applyConfiguration: {
                        ...state.state.applyConfiguration,
                        [action.payload.id]:
                            states.applyConfiguration.APPLY_FAILED,
                    },
                },
            };

        default:
            return state;
    }
};

export const specStates = {
    CREATED: 1,
    DRAFT: 2,
    AVAILABLE: 3,
    IN_USE: 4,
};

const getStatus = state => state.specifications.state;
const getFetchStatus = createSelector(getStatus, state => state.fetch);
const getCreateStatus = createSelector(getStatus, state => state.create);

const byIndexDesc = (a, b) => a.index - b.index;

const getSpecs = state => Object.values(state.specifications.specifications);

const getSpecifications = createSelector(getSpecs, specifications =>
    specifications.sort(byIndexDesc),
);

const getSpecification = id => state =>
    state.specifications.specifications[id] ?? null;

const getVersions = createSelector(getSpecifications, specifications =>
    specifications.map(({ id, name }) => ({ id, name })),
);

const getValidationsFetchStatus = createSelector(
    getStatus,
    state => state.validations.fetch,
);
const getValidations = state => Object.values(state.specifications.validations);

const getValidation = validationId =>
    createSelector(getValidations, validations => {
        return validations.find(v => v.id === validationId);
    });

const getValidationPagination = state =>
    state.specifications.pagination.validations;

const getDerivedFieldsFetchStatus = createSelector(
    getStatus,
    state => state.derivedFields.fetch,
);
const getDerivedFields = state => state.specifications.derivedFields;

const getDerivedFieldPagination = state =>
    state.specifications.pagination.derivedFields;

const getToleranceText = tolerance => {
    const { toleranceTypeId, toleranceFrom, toleranceTo } = tolerance;

    const toleranceTypeIds = {
        count: 1,
        percentage: 2,
        range: 3,
    };

    switch (toleranceTypeId) {
        case toleranceTypeIds.count:
            return `${toleranceFrom}`;

        case toleranceTypeIds.percentage:
            return `${toleranceFrom.toFixed(2)}%`;

        case toleranceTypeIds.range:
            return `${toleranceFrom} to ${toleranceTo}`;

        default:
            return 'Invalid tolerance type';
    }
};

const getRegulators = ({ validationId }) =>
    createSelector(getValidation(validationId), validation => {
        const { regulators } = validation || { regulators: [] };

        const byToleranceTypeId = (a, b) => {
            return a.toleranceTypeId - b.toleranceTypeId;
        };

        const sortTolerances = regulator => ({
            ...regulator,
            tolerances: regulator.tolerances.sort(byToleranceTypeId),
        });

        const addIsPrimary = regulator => {
            const isPrimaryRegulator =
                regulatorModule.helpers.isPrimaryRegulator(
                    regulator.regulatorTypeCode,
                );

            return { ...regulator, isPrimaryRegulator };
        };

        const byPrimaryThenName = (a, b) => {
            if (a.isPrimaryRegulator === b.isPrimaryRegulator) {
                return a.name.localeCompare(b.name);
            }

            return b.isPrimaryRegulator ? 1 : -1;
        };

        const addTolerance = regulator => {
            const toleranceTexts = regulator.tolerances.map(getToleranceText);

            return {
                ...regulator,
                tolerance: toleranceTexts.join(
                    getConjunction(regulator.conjunction),
                ),
            };
        };

        const transform = regulator => ({
            code: regulator.regulatorCode,
            name: regulator.name,
            shortName: regulator.shortName,
            tolerance: regulator.tolerance,
        });

        const getConjunction = letter => {
            switch (letter) {
                case 'A':
                    return ' AND ';
                case 'O':
                    return ' OR ';
                default:
                    return 'NOT FOUND';
            }
        };

        const groupByRegulatorAndCategory = specificationTolerances => {
            const tolerancesByRegulatorAndCategory =
                specificationTolerances.reduce(
                    (output, specificationTolerance) => {
                        const keyValue =
                            specificationTolerance.regulatorCategoryCode
                                ? `${specificationTolerance.regulatorCode}-${specificationTolerance.regulatorCategoryCode}`
                                : specificationTolerance.regulatorCode;

                        const conjoinedTolerance = output[keyValue] || {
                            regulatorCode: specificationTolerance.regulatorCode,
                            name: specificationTolerance.name,
                            shortName: specificationTolerance.shortName,
                            conjunction: specificationTolerance.conjunction,
                            regulatorTypeLabel:
                                specificationTolerance.regulatorTypeLabel,
                            regulatorTypeCode:
                                specificationTolerance.regulatorTypeCode,
                            tolerances: [],
                        };

                        conjoinedTolerance.tolerances.push({
                            toleranceTypeId:
                                specificationTolerance.toleranceTypeId,
                            toleranceFrom: specificationTolerance.toleranceFrom,
                            toleranceTo: specificationTolerance.toleranceTo,
                        });

                        return { ...output, [keyValue]: conjoinedTolerance };
                    },
                    {},
                ) || {};

            return Object.values(tolerancesByRegulatorAndCategory) || [];
        };

        const tolerancesByRegulator = groupByRegulatorAndCategory(regulators);

        return tolerancesByRegulator
            .map(sortTolerances)
            .map(addIsPrimary)
            .sort(byPrimaryThenName)
            .map(addTolerance)
            .map(transform);
    });

const toleranceTypes = {
    COUNT: 1,
    PERCENTAGE: 2,
    RANGE: 3,
};

const getRegulatorsForEdit = ({ validationId }) =>
    createSelector(getValidation(validationId), validation => {
        const { regulators } = validation || {};

        if (!regulators) {
            return null;
        }

        const getTolerance = regulator => {
            switch (regulator.toleranceTypeId) {
                case toleranceTypes.RANGE:
                    return {
                        id: regulator.toleranceId,
                        type: regulator.toleranceTypeId,
                        from: regulator.toleranceFrom,
                        to: regulator.toleranceTo,
                    };

                default:
                    return {
                        id: regulator.toleranceId,
                        type: regulator.toleranceTypeId,
                        value: regulator.toleranceFrom,
                    };
            }
        };

        return Object.values(
            regulators.reduce(
                (output, regulator) => ({
                    ...output,
                    [regulator.name]: {
                        name: regulator.name,
                        conjunction: regulator.conjunction,
                        tolerances: [
                            ...(output[regulator.name]?.tolerances ?? []),
                            getTolerance(regulator),
                        ].sort((a, b) => a.type - b.type),
                    },
                }),
                {},
            ),
        );
    });

const getRuleMetadata = ({ validationId }) =>
    createSelector(getValidation(validationId), validation => {
        if (!validation) {
            return {};
        }

        return {
            description: validation.description,
            guidanceUrl: validation.guidanceUrl || defaultValidationGuidanceUrl,
        };
    });

const getSpecStatus = id => state => state.specifications.state.update[id];

const getSpecState = id =>
    createSelector(
        getSpecification(id),
        getSpecStatus(id),
        (specification, status) => {
            const { state, availableStates } = specification || {};
            return {
                state,
                status,
                isEditable: availableStates?.length > 0,
                availableStates,
            };
        },
    );

const getDeleteStatus = id => state => {
    const { isDeleteable } = selectors.getSpecification(id)(state) || {};

    return {
        status: state.specifications.state.delete[id],
        isDeleteable,
    };
};

const getApplyConfigurationStatus = id =>
    createSelector(getStatus, state => state.applyConfiguration[id]);

export const selectors = {
    getFetchStatus,
    getCreateStatus,
    getSpecifications,
    getSpecification,
    getSpecState,
    getVersions,

    getValidationsFetchStatus,
    getValidations,
    getValidation,
    getValidationPagination,
    getDerivedFieldsFetchStatus,
    getDerivedFields,
    getDerivedFieldPagination,
    getRegulators,
    getRegulatorsForEdit,
    getRuleMetadata,
    getDeleteStatus,
    getApplyConfigurationStatus,
};

export const sagas = {
    onFetchSpecifications: function* onFetchSpecifications({ payload }) {
        try {
            const { state } = payload || {};
            const specifications = yield call(specificationsApi.getAll, {
                state,
            });
            yield put(actions.fetchSpecificationsSuccess(specifications));
        } catch (error) {
            yield put(actions.fetchSpecificationsFailed(error));
        }
    },

    onFetchSpecification: function* onFetchSpecification({ payload }) {
        try {
            const { id } = payload;
            const specification = yield call(specificationsApi.get, { id });
            yield put(actions.fetchSpecificationSuccess(specification));
        } catch (error) {
            yield put(actions.fetchSpecificationFailed(error));
        }
    },

    onCreate: function* onCreate({ payload }) {
        const { schemaId, rulesetId, versionIncrement } = payload;

        try {
            const response = yield call(specificationsApi.create, {
                schemaId,
                rulesetId,
                versionIncrement,
            });
            yield put(actions.createSuccess(response));
        } catch (error) {
            yield put(actions.createFailed(error));
        }
    },

    onCreateSuccess: function* onCreateSuccess() {
        yield call(history.push, '/management/specifications');
    },

    onCancelCreate: function* onCancelCreate() {
        yield call(history.replace, '/management/specifications');
    },

    onFetchValidations: function* onFetchValidations({ payload }) {
        const { id, filter, offset, limit } = payload;

        try {
            const response = yield call(specificationsApi.getValidations, {
                id,
                filter,
                offset,
                limit,
            });
            yield put(actions.fetchValidationsSuccess(response));
        } catch (error) {
            yield put(actions.fetchValidationsFailed(error));
        }
    },

    onFetchDerivedFields: function* onFetchDerivedFields({ payload }) {
        const { id, offset, filter, limit } = payload;

        try {
            const response = yield call(specificationsApi.getDerivedFields, {
                id,
                filter,
                offset,
                limit,
            });
            yield put(actions.fetchDerivedFieldsSuccess(response));
        } catch (error) {
            yield put(actions.fetchDerivedFieldsFailed(error));
        }
    },

    onUpdateState: function* onUpdateState({ payload }) {
        const { id, stateId } = payload;
        try {
            const specification = yield call(specificationsApi.updateState, {
                id,
                stateId,
            });
            yield put(actions.updateStateSuccess({ id, specification }));
        } catch (error) {
            yield put(actions.updateStateFailed({ id, error }));
        }
    },

    onDeleteSpecification: function* onDeleteSpecification({ payload }) {
        const { id } = payload;

        try {
            yield call(specificationsApi.deleteRequest, id);
            yield put(actions.deleteSuccess({ id }));
        } catch (error) {
            yield put(actions.deleteFailed({ id }));
        }
    },

    onDeleteSpecificationSuccess: function* onDeleteSpecificationSuccess() {
        yield call(history.push, '/management/specifications');
    },

    onApplyConfiguration: function* onApplyConfiguration({ payload }) {
        const { id } = payload;

        try {
            yield call(specificationsApi.applyConfiguration, id);
            yield put(actions.applyConfigurationSuccess({ id }));
        } catch (error) {
            yield put(actions.applyConfigurationFailed({ id }));
        }
    },

    onApplyConfigurationSuccess: function* onApplyConfigurationSuccess() {
        yield call(history.push, '/management/specifications');
    },

    listen: function* listen() {
        yield takeLatest(
            actionTypes.FETCH_SPECIFICATIONS,
            sagas.onFetchSpecifications,
        );

        yield takeLatest(
            actionTypes.FETCH_SPECIFICATION,
            sagas.onFetchSpecification,
        );

        yield takeLatest(actionTypes.CREATE, sagas.onCreate);
        yield takeLatest(actionTypes.CREATE_SUCCESS, sagas.onCreateSuccess);
        yield takeLatest(actionTypes.CANCEL_CREATE, sagas.onCancelCreate);

        yield takeLatest(
            actionTypes.FETCH_VALIDATIONS,
            sagas.onFetchValidations,
        );

        yield takeLatest(
            actionTypes.FETCH_DERIVED_FIELDS,
            sagas.onFetchDerivedFields,
        );

        yield takeEvery(actionTypes.UPDATE_STATE, sagas.onUpdateState);

        yield takeLatest(
            actionTypes.DELETE_SPECIFICATION,
            sagas.onDeleteSpecification,
        );
        yield takeLatest(
            actionTypes.DELETE_SPECIFICATION_SUCCESS,
            sagas.onDeleteSpecificationSuccess,
        );

        yield takeLatest(
            actionTypes.APPLY_CONFIGURATION,
            sagas.onApplyConfiguration,
        );
        yield takeLatest(
            actionTypes.APPLY_CONFIGURATION_SUCCESS,
            sagas.onApplyConfigurationSuccess,
        );
    },
};
