import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { selectors as regulatorSelectors } from 'modules/regulators';
import {
    getValidationDetails,
    getValidationDetailsCsv,
    getValidationDetailsUri,
} from 'services/api/submissions';
import QualityRule from 'src/components/QualityRule/QualityRule';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';

import { requestQualityRuleFailuresDownload } from '../../../utils';

const QualityRuleError = ({ rule, refetch, isFetching }) => {
    // TODO: This is present for submission quality rule, but not for the other quality rules?
    const navigate = useNavigate();

    const goBack = useCallback(() => {
        navigate('../', { relative: true });
    }, [navigate]);

    return (
        <Paper className="QualityRule__root">
            <div className="QualityRule__title-wrapper">
                <h2 className="QualityRule__id-link margin--bottom-0-5">
                    {rule}
                </h2>
            </div>

            <Box
                display={'flex'}
                flexDirection={'column'}
                p={5}
                alignItems={'center'}
            >
                {isFetching ? (
                    <CircularProgress aria-label="Circular loading animation" />
                ) : (
                    <>
                        <Typography variant={'body'} paragraph align={'center'}>
                            No data available for this submission
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={2}
                            justifyContent="center"
                        >
                            <Button
                                variant={'outlined'}
                                size={'small'}
                                onClick={refetch}
                            >
                                Try again
                            </Button>
                            <Button
                                variant={'outlined'}
                                size={'small'}
                                onClick={goBack}
                            >
                                Back
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Paper>
    );
};

const SubmissionQualityRule = () => {
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isDownloading, setIsDownloading] = React.useState(false);
    const { rule, submissionId, regulatorCode } = useParams();
    const regulators = useSelector(regulatorSelectors.getRegulatorsByCode);
    const [csvData, setCsvData] = useState();
    const csvLink = useRef(null);
    const props = useOutletContext();

    const DEFAULT_TABLE_PARAMETERS = {
        limit: 10,
        offset: 0,
    };

    const tableId = TABLE_PARAMS_IDS.REPORTS_QUALITY_RULES;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset } = tableParams;

    const { setLimit, setOffset } = tableParamsMethods;

    const instId = props.data?.provider.instId;

    const { data, isFetching, refetch, isError } = useQuery({
        queryKey: ['validations', submissionId, instId, rule, offset, limit],
        queryFn: () =>
            getValidationDetails({
                submissionId,
                validationId: rule,
                instId,
                regulatorCode,
                offset,
                limit,
            }),
        keepPreviousData: true,
    });

    function handleChangeRowsPerPage(event) {
        setLimit(+event.target.value);
        setOffset(0);
    }

    function handleChangePage(event, newPage) {
        setOffset(newPage);
    }

    useEffect(() => {
        if (location.pathname.includes('admin') && !isAdmin) {
            setIsAdmin(true); // wtf
        }
    }, [isAdmin, location.pathname]);

    const regulator = useMemo(
        () => regulators[data?.records[0]?.RegularCode] || {},
        [data, regulators],
    );

    const validation = data?.validation;
    const pageData = data?.pagingMetadata;
    const results = data?.records;

    const handleClickDownloadCsv = async () => {
        if (submissionId) {
            setIsDownloading(true);
            if (data?.pagingMetadata.totalResultsCount > 1000) {
                const uri = await getValidationDetailsUri({
                    submissionId,
                    validationId: rule,
                    instId,
                    regulatorCode,
                });

                await requestQualityRuleFailuresDownload(uri);
            } else {
                const csv = await getValidationDetailsCsv({
                    submissionId,
                    validationId: rule,
                    instId,
                    regulatorCode,
                });

                setCsvData({
                    href: `data:text/csv;charset=utf-8,${encodeURIComponent(
                        csv,
                    )}`,
                    download: `QualityRuleReport.${submissionId.toLowerCase()}.${rule}.${new Date().toISOString()}.csv`,
                });
            }
            setIsDownloading(false);
        }
    };

    useEffect(() => {
        if (csvLink && csvData) {
            csvLink.current.click();
            setCsvData();
        }
    }, [csvLink, csvData]);

    if (
        isError ||
        !data?.records?.length ||
        !data?.pagingMetadata ||
        data?.records?.validation
    ) {
        return (
            <QualityRuleError
                rule={rule}
                refetch={refetch}
                isFetching={isFetching}
            />
        );
    }

    return (
        <QualityRule
            isDownloading={isDownloading}
            rule={rule}
            regulator={regulator}
            results={results}
            validation={validation}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClickDownloadCsv={handleClickDownloadCsv}
            pageData={pageData}
            offset={offset}
            limit={limit}
            csvData={csvData}
            csvLink={csvLink}
        />
    );
};

export default SubmissionQualityRule;
