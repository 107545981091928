import { DateTime } from 'luxon';
import { createAction } from 'redux-actions';
import { eventChannel } from 'redux-saga';
import { call, delay, put, take, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { bulkReprocessing as bulkReprocessingApi } from 'services/api';
import { connectWebSocket } from 'src/services/api/utils';

import { getBurpStateByKey } from './statuses';

export const actionTypes = {
    FETCH_PROVIDERS: 'burp/FETCH',
    FETCH_PROVIDERS_SUCCESS: 'burp/FETCH_SUCCESS',
    FETCH_PROVIDERS_FAILED: 'burp/FETCH_FAILED',
    REPROCESS_PROVIDERS: 'burp/REPROCESS',
    REPROCESS_PROVIDERS_SUCCESS: 'burp/REPROCESS_SUCCESS',
    REPROCESS_PROVIDERS_FAILED: 'burp/REPROCESS_FAILED',
    WATCH_FOR_STATE_CHANGES: 'burp/WATCHING',
    INCOMING_STATE_CHANGE: 'burp/INCOMING_STATE_CHANGE',
};

export const actions = {
    fetch: createAction(actionTypes.FETCH_PROVIDERS),
    fetchSuccess: createAction(actionTypes.FETCH_PROVIDERS_SUCCESS),
    fetchFailed: createAction(actionTypes.FETCH_PROVIDERS_FAILED),
    reprocess: createAction(actionTypes.REPROCESS_PROVIDERS),
    reprocessSuccess: createAction(actionTypes.REPROCESS_PROVIDERS_SUCCESS),
    reprocessFailed: createAction(actionTypes.REPROCESS_PROVIDERS_FAILED),
    watchForStateChanges: createAction(actionTypes.WATCH_FOR_STATE_CHANGES),
    incomingStateChange: createAction(actionTypes.INCOMING_STATE_CHANGE),
};

export const states = {
    fetch: {
        UNINITIALISED: 1,
        LOADING: 2,
        LOADED: 3,
        LOADING_FAILED: 4,
    },
    reprocess: {
        UNINITIALISED: 0,
        REPROCESSING: 1,
        REPROCESSED: 2,
        REPROCESS_FAILED: 3,
    },
};

const INITIAL_STATE = {
    state: {
        fetch: states.fetch.UNINITIALISED,
        reprocess: states.reprocess.UNINITIALISED,
    },
    providers: [],
};

const formatDate = date => DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm');

export const formatStatus = submission => {
    const { burpState, uploaded, copiedFrom } = submission;
    if (burpState === 'Reprocessed')
        return `${burpState} - ${formatDate(uploaded)}`;
    if (!burpState || !copiedFrom) return 'Not Reprocessed';
    return burpState;
};

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROVIDERS:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch:
                        state.state.fetch !== states.fetch.LOADED
                            ? states.fetch.LOADING
                            : states.fetch.LOADED,
                },
            };

        case actionTypes.FETCH_PROVIDERS_SUCCESS:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADED,
                },
                providers: action.payload.map(provider => ({
                    ...provider,
                    submission: {
                        ...provider.submission,
                        burpState: formatStatus(provider.submission),
                    },
                })),
            };

        case actionTypes.FETCH_PROVIDERS_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    fetch: states.fetch.LOADING_FAILED,
                },
            };

        case actionTypes.REPROCESS_PROVIDERS:
            return {
                ...state,
                state: {
                    ...state.state,
                    reprocess: states.reprocess.REPROCESS_PROVIDERS,
                },
            };

        case actionTypes.REPROCESS_PROVIDERS_SUCCESS:
            return {
                ...state,
                state: {
                    ...state.state,
                    reprocess: states.reprocess.REPROCESSED,
                },
                providers: state.providers.map(provider => {
                    const tempProvider = provider;
                    if (action.payload.instIds.includes(provider.instId)) {
                        tempProvider.submission.burpState = 'Queueing';
                        tempProvider.submission.uploaded =
                            new Date().toISOString();
                    }
                    return tempProvider;
                }),
            };

        case actionTypes.REPROCESS_PROVIDERS_FAILED:
            return {
                ...state,
                state: {
                    ...state.state,
                    reprocess: states.reprocess.REPROCESS_PROVIDERS_FAILED,
                },
            };

        case actionTypes.INCOMING_STATE_CHANGE:
            return {
                ...state,
                state: {
                    ...state.state,
                },
                providers: state.providers.map(provider => {
                    const { incoming } = action.payload;
                    if (incoming.copiedFrom === provider.submission.id) {
                        provider.submission.id = incoming.id;
                        provider.submission.copiedFrom = incoming.copiedFrom;
                    }

                    if (incoming.id === provider.submission.id) {
                        const burpState = getBurpStateByKey(incoming.status);
                        provider.submission.burpState = formatStatus({
                            ...provider.submission,
                            burpState,
                        });
                    }
                    return provider;
                }),
            };

        default:
            return state;
    }
};

const getBurpState = state => state.bulkReprocessing;

export const selectors = {
    getFetchStatus: createSelector(
        getBurpState,
        burpState => burpState.state.fetch,
    ),
    getProviders: createSelector(getBurpState, state => state.providers),
    getReprocessingStatus: createSelector(
        getBurpState,
        burpState => burpState.state.reprocess,
    ),
};

const createWebSocketConnection = instId => connectWebSocket(instId);

const createSocketChannel = socket => {
    return eventChannel(emit => {
        const messageHandler = msg => {
            const incomingMessage = JSON.parse(msg.data);
            if (incomingMessage.action === 'file-event') {
                emit(incomingMessage);
            }
        };

        socket.addEventListener('message', messageHandler);

        const unsubscribe = () => {
            socket.removeEventListener('message', messageHandler);
        };

        return unsubscribe;
    });
};

export const sagas = {
    onFetchProviders: function* onFetchProviders({ payload = {} }) {
        const { collectionId } = payload;

        try {
            const response = yield call(bulkReprocessingApi.getProviders, {
                collectionId,
                terminal: false,
            });
            yield put(actions.fetchSuccess(response));
            yield put(actions.watchForStateChanges(payload));
        } catch (error) {
            yield put(actions.fetchFailed(error));
        }
    },

    onBulkReprocess: function* onBulkReprocess({ payload }) {
        const { instIds, collectionId, extendedOptions } = payload || {};
        try {
            yield call(bulkReprocessingApi.sendProvidersForProcessing, {
                collectionId,
                payload: {
                    instIds,
                    extendedOptions,
                },
            });
            yield put(actions.reprocessSuccess({ ...payload }));
        } catch (error) {
            yield put(actions.reprocessFailed());
        }
    },

    onWatchForStateChanges: function* onWatchForStateChanges({ payload = {} }) {
        const { user } = payload;

        const socket = yield call(createWebSocketConnection, user);
        const socketChannel = yield call(createSocketChannel, socket);

        while (true) {
            const incoming = yield take(socketChannel);
            yield put(actions.incomingStateChange({ ...payload, incoming }));
        }
    },

    onIncomingStateChange: function* onIncomingStateChange({ payload }) {
        const { incoming, collectionId, user } = payload;

        const incomingBurpState = getBurpStateByKey(incoming.status);

        if (incomingBurpState === 'Reprocessed') {
            yield delay(2500);
            yield put(actions.fetch({ collectionId, user }));
        }
    },

    listen: function* listen() {
        yield takeLatest(actionTypes.FETCH_PROVIDERS, sagas.onFetchProviders);
        yield takeLatest(
            actionTypes.REPROCESS_PROVIDERS,
            sagas.onBulkReprocess,
        );
        yield takeLatest(
            actionTypes.WATCH_FOR_STATE_CHANGES,
            sagas.onWatchForStateChanges,
        );
        yield takeLatest(
            actionTypes.INCOMING_STATE_CHANGE,
            sagas.onIncomingStateChange,
        );
    },
};
