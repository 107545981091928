import decode from 'jwt-decode';
import { actionTypes as authActions } from 'modules/auth';
import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { token as tokenApi } from 'services/api';
import { token as tokenStorage } from 'services/storage';

export const actionTypes = {
    HESA_TOKEN_RECEIVED: 'tokens/HESA_TOKEN_RECEIVED',

    EXCHANGE: 'tokens/EXCHANGE',
    EXCHANGE_SUCCESS: 'tokens/EXCHANGE_SUCCESS',
    EXCHANGE_FAILED: 'tokens/EXCHANGE_FAILED',

    VALID_TOKEN: 'tokens/VALID',
    INVALID_TOKEN: 'tokens/INVALID',
    CLEAR_TOKEN: 'tokens/CLEAR',
};

export const actions = {
    hesaTokenReceived: createAction(actionTypes.HESA_TOKEN_RECEIVED),

    exchange: createAction(actionTypes.EXCHANGE),
    exchangeSuccess: createAction(actionTypes.EXCHANGE_SUCCESS),
    exchangeFailed: createAction(actionTypes.EXCHANGE_FAILED),

    validToken: createAction(actionTypes.VALID_TOKEN),
    invalidToken: createAction(actionTypes.INVALID_TOKEN),
    clearToken: createAction(actionTypes.CLEAR_TOKEN),
};

export const states = {
    UNINITIALISED: 1,
    EXCHANGING: 2,
    EXCHANGED: 3,
    EXCHANGE_FAILED: 4,
};

const INITIAL_STATE = {
    state: states.UNINITIALISED,
    token: null,
    error: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.EXCHANGE:
            return { ...state, state: states.EXCHANGING, error: null };

        case actionTypes.EXCHANGE_SUCCESS:
            return { ...state, state: states.EXCHANGED };

        case actionTypes.EXCHANGE_FAILED:
            return { ...state, state: states.EXCHANGE_FAILED };

        case actionTypes.VALID_TOKEN:
            return { ...state, token: action.payload, error: null };

        case actionTypes.INVALID_TOKEN:
            return { ...state, token: null, error: action.payload };

        case actionTypes.CLEAR_TOKEN:
            return { ...state, token: null, error: null };

        default:
            return state;
    }
};

export const selectors = {
    getToken: state => state.tokens.token,
    getStatus: state => state.tokens.state,
    getError: state => state.tokens.error,
};

const hasTokenExpired = token => {
    const { exp } = decode(token) || { exp: 0 };
    return Date.now() > exp * 1000;
};

const hasInvalidClaims = token => {
    const { organisations } = decode(token);
    return organisations === undefined;
};

const hasEmptyOrganisations = token => {
    const { organisations } = decode(token);
    return organisations.length === 0;
};

export const tokenErrors = {
    NO_TOKEN: 'NO_TOKEN',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    INVALID_CLAIMS: 'INVALID_CLAIMS',
    NO_ORGANISATIONS: 'NO_ORGANISATIONS',
};

export const sagas = {
    checkToken: function* (token) {
        if (!token) {
            return yield put(actions.invalidToken(tokenErrors.NO_TOKEN));
        }

        if (hasTokenExpired(token)) {
            return yield put(actions.invalidToken(tokenErrors.TOKEN_EXPIRED));
        }

        if (hasInvalidClaims(token)) {
            return yield put(actions.invalidToken(tokenErrors.INVALID_CLAIMS));
        }

        if (hasEmptyOrganisations(token)) {
            return yield put(
                actions.invalidToken(tokenErrors.NO_ORGANISATIONS),
            );
        }

        yield put(actions.validToken(token));
    },

    onExchange: function* ({ payload }) {
        try {
            const { token } = yield call(tokenApi.exchangeToken, {
                token: payload.token,
            });
            yield put(actions.exchangeSuccess({ token }));
        } catch (error) {
            yield put(actions.exchangeFailed(error));
        }
    },

    onExchangeSuccess: function* ({ payload }) {
        yield call(sagas.checkToken, payload.token);
    },

    onHesaTokenReceived: function* ({ payload }) {
        yield put(actions.exchange({ token: payload }));
    },

    onValidToken: function* ({ payload }) {
        yield call(tokenStorage.set, payload);
    },

    onInvalidToken: function* () {
        yield call(tokenStorage.clear);
    },

    onClearToken: function* () {
        yield call(tokenStorage.clear);
    },

    listen: function* () {
        yield takeLatest(
            actionTypes.HESA_TOKEN_RECEIVED,
            sagas.onHesaTokenReceived,
        );
        yield takeLatest(authActions.SIGN_OUT, sagas.onClearToken);

        yield takeLatest(actionTypes.VALID_TOKEN, sagas.onValidToken);
        yield takeLatest(actionTypes.INVALID_TOKEN, sagas.onInvalidToken);
        yield takeLatest(actionTypes.CLEAR_TOKEN, sagas.onClearToken);

        yield takeLatest(actionTypes.EXCHANGE, sagas.onExchange);
        yield takeLatest(actionTypes.EXCHANGE_SUCCESS, sagas.onExchangeSuccess);
    },
};
