import { stringify } from 'query-string';

import { get, post } from '../utils';

export const getActivityLog = ({
    instId,
    collectionReference,
    offset,
    limit,
}) =>
    get(
        `/providers/${instId}/activity-log?collectionReference=${collectionReference}&offset=${offset}&limit=${limit}`,
    ).then(res => res);

export const getAll = () =>
    get(`/providers`).then(providers => ({ providers }));

export const getCollections = ({ instId, state = [] }) =>
    get(`/providers/${instId}/collections?${stringify({ state })}`).then(
        res => res.collections,
    );

export const onboard = ({ instId }) =>
    post('/providers', {
        body: { instId },
    });
