import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { regulators as regulatorsApi } from 'services/api';

import * as helpers from './helpers';
export { helpers };

export const actionTypes = {
    FETCH_REGULATORS: 'regulators/FETCH',
    FETCH_REGULATORS_SUCCESS: 'regulators/FETCH_SUCCESS',
    FETCH_REGULATORS_FAILED: 'regulators/FETCH_FAILED',
};

export const actions = {
    fetch: createAction(actionTypes.FETCH_REGULATORS),
    fetchSuccess: createAction(actionTypes.FETCH_REGULATORS_SUCCESS),
    fetchFailed: createAction(actionTypes.FETCH_REGULATORS_FAILED),
};

export const states = {
    fetch: {
        UNINITIALISED: 1,
        LOADING: 2,
        LOADED: 3,
        LOADING_FAILED: 4,
    },
};

const INITIAL_STATE = {
    state: {
        fetch: states.fetch.UNINITIALISED,
    },
    regulators: {},
};

const keyBy = (a, key) =>
    a.reduce((output, i) => ({ ...output, [i[key]]: i }), {});

const transform = regulators =>
    regulators.map(regulator => {
        const { regulatorCode, ...rest } = regulator;
        return {
            ...rest,
            code: regulatorCode,
            isPrimaryRegulator: helpers.isPrimaryRegulator(
                regulator.regulatorTypeCode,
            ),
        };
    });

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_REGULATORS:
            return {
                ...state,
                state: { ...state.state, fetch: states.fetch.LOADING },
            };

        case actionTypes.FETCH_REGULATORS_SUCCESS:
            return {
                ...state,
                state: { ...state.state, fetch: states.fetch.LOADED },
                regulators: keyBy(transform(action.payload.regulators), 'code'),
            };

        case actionTypes.FETCH_REGULATORS_FAILED:
            return {
                ...state,
                state: { ...state.state, fetch: states.fetch.LOADING_FAILED },
            };

        default:
            return state;
    }
};

const getFetchStatus = createSelector(
    state => state.regulators,
    regulators => regulators.state.fetch,
);

const getRegulator = code =>
    createSelector(
        state => state.regulators,
        regulators => regulators.regulators[code] || {},
    );

const getRegulators = createSelector(
    state => state.regulators,
    regulators => Object.values(regulators.regulators),
);

const getRegulatorsByCode = createSelector(
    state => state.regulators,
    regulators => regulators.regulators,
);

export const selectors = {
    getFetchStatus,
    getRegulator,
    getRegulators,
    getRegulatorsByCode,
};

export const sagas = {
    onFetchRegulators: function* onFetchRegulators() {
        try {
            const response = yield call(regulatorsApi.getAll);
            yield put(actions.fetchSuccess(response));
        } catch (error) {
            yield put(actions.fetchFailed(error));
        }
    },

    listen: function* listen() {
        yield takeLatest(actionTypes.FETCH_REGULATORS, sagas.onFetchRegulators);
    },
};
